import { format, parseISO } from 'date-fns';
import { SiAdblock } from 'react-icons/si';
import React from 'react';
import { es } from 'date-fns/locale';
import Progress from './progress';

const ReservationCard = ({ reservation, onPenalize }) => {
   return (
      <div
         key={reservation._id}
         className="grid grid-cols-1 bg-white px-2 py-2 rounded-lg shadow-lg h-[190px]">
         <div className="grid grid-cols-2">
            <div>
               <div className="flex flex-col">
                  <span className="text-[12px]">Torre</span>
                  <strong className="text-[14px]">
                     {reservation?.tower?.name}
                  </strong>
               </div>
               <div className="flex flex-col">
                  <span className="text-[12px]">Piso</span>
                  <strong className="text-[14px]">
                     {reservation?.floor?.name}
                  </strong>
               </div>
               <div className="flex flex-col">
                  <span className="text-[12px]">Amenidad</span>
                  <strong className="text-[14px]">
                     {reservation?.amenity?.name}
                  </strong>
               </div>
            </div>

            <div>
               <div className="flex flex-col">
                  <span className="text-[12px]">Usuario</span>
                  <strong className="text-[14px]">
                     {reservation?.user?.fullName}
                  </strong>
               </div>
               <div className="flex flex-col">
                  <span className="text-[12px]">Clave</span>
                  <strong className="text-[14px]">{reservation?.code}</strong>
               </div>
               <div className="flex flex-col">
                  <span className="text-[12px]">Fecha</span>
                  <strong className="text-[11px]">
                     {format(
                        parseISO(reservation.schedule.start),
                        'EEEE dd LLL - HH:mm',
                        { locale: es },
                     )}{' '}
                     {' - '}
                     {format(parseISO(reservation.schedule.end), 'HH:mm')}
                  </strong>
               </div>
               <div className="flex flex-col">
                  <span className="text-[12px]">
                     Penalizado: {reservation.penalty}
                  </span>
               </div>
            </div>
         </div>
         <div className="grid grid-flow-col gap-2 pt-[6px]">
            <div className="col-span-6 flex items-center">
               <Progress
                  width="w-[50%]"
                  value={Math.min(reservation.metric.progress, 100)}
                  color={
                     reservation.metric.progress > 90
                        ? reservation.metric.progress >= 100
                           ? 'red'
                           : 'yellow'
                        : 'green'
                  }
               />
            </div>
            <div className="cols-span-1">
               <button
                  className="linear rounded-[20px] bg-brand-900 px-3 py-1 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 !border-none"
                  onClick={() =>
                     onPenalize(reservation._id, reservation.user._id)
                  }>
                  <SiAdblock />
               </button>
            </div>
         </div>
      </div>
   );
};

export default ReservationCard;
