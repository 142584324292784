import { ADD_LIST_FOOD, ADD_FOOD, UPDATE_FOOD } from '../types/food.type';

export function addListFood(data) {
   return {
      type: ADD_LIST_FOOD,
      payload: data,
   };
}

export function addtFood(data) {
   return {
      type: ADD_FOOD,
      payload: data,
   };
}

export function updateFood(data) {
   return {
      type: UPDATE_FOOD,
      payload: data,
   };
}
