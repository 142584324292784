import { DateTimePicker } from '@material-ui/pickers';
import {
   compareDesc,
   getHours,
   isAfter,
   isBefore,
   isEqual,
   isSameDay,
} from 'date-fns';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../../../components/AgGrid/AgGrid';
import { http } from '../../../../utils/http';
import { columnsReportUser } from './columnsReportUser';
import UserAutocomplete from '../../../../components/autocomplete/autocomplete';

import {
   addListUser as addListUserAction,
   addListUserChild as addListUserChildAction,
} from '../../../../redux/actions/user.action';
import { addListTower as addListTowerAction } from '../../../../redux/actions/tower.action';
import DateInputFilter from '../../../../components/DateInputFilter/DateInputFilter';
import {
   Button,
   FormControl,
   InputLabel,
   MenuItem,
   Select,
} from '@material-ui/core';
import { isSameMonth } from 'date-fns/esm';

const initialState = {
   clients: [],
   filterClients: [],
   // towers: [],
   companies: [],
   userFilter: null,
   monthDateFilter: null,
   weekDateFilter: null,
   dayDateFilter: null,
   towerFilter: null,
   hourDateFilter: null,
   companyFilter: null,
};
export class ReportCreditsPage extends Component {
   state = initialState;

   async componentDidMount() {
      await Promise.all([this.getCredits(), this._getTowers()]);
   }

   async _getUser() {
      await http('/user', 'GET', {})
         .then((u) => {
            this.props.addListUser(u.data.data);
         })
         .catch((err) => {
            console.error(err);
         });
   }

   async _getUserChildren() {
      await http('/user/child', 'GET', {})
         .then((u) => {
            this.props.addListUserChild(u.data.data);
         })
         .catch((err) => {
            console.error(err);
         });
   }

   async _getTowers() {
      await http('/tower', 'GET', {})
         .then((res) => {
            console.log(res);

            this.props.addListTower(res.data.data);
         })
         .catch((err) => console.log(err));
   }

   async getCredits() {
      await this._getUser();
      await this._getUserChildren();
      await this._getTowers();
      this._getCompanies();

      await http('/credit', 'GET', {})
         .then((res) => {
            let data = [];
            const users = [...this.props.users, ...this.props.usersChildren];
            res.data.data.forEach((credit) => {
               data = [...data, ...credit.history];
            });

            data = data.sort((a, b) =>
               compareDesc(new Date(a.createdAt), new Date(b.createdAt)),
            );

            data = data.map((history) => {
               return {
                  ...history,
                  user: users.find((u) => u._id === history.user),
                  tower:
                     history.tower !== null
                        ? this.props.towers.find((t) => t._id === history.tower)
                        : null,
               };
            });
            // console.log(data);
            this.setState({
               clients: data,
               filterClients: data,
            });
         })
         .catch((err) => {
            console.error(err);
         });
   }

   _getCompanies() {
      const companies = this.props.users.map((user) => user.nameCompany);
      this.setState({
         companies,
      });
      // console.log(companies);
   }

   onFilterUser(user) {
      this.setState(
         {
            userFilter: user === null ? null : user._id,
         },
         () => {
            this._onFilter();
         },
      );
   }

   onFilterTower(evt) {
      const value = evt.target.value;
      this.setState(
         {
            towerFilter: value === null ? null : value,
         },
         () => {
            this._onFilter();
         },
      );
   }

   _onFilterWeek(evt) {
      // console.log(evt);
      this.setState(
         {
            weekDateFilter: evt,
            dayDateFilter: null,
            monthDateFilter: null,
         },
         () => {
            this._onFilter();
         },
      );
   }
   _onFilterCompany(evt) {
      const value = evt.target.value;
      this.setState(
         {
            companyFilter: value === null ? null : value,
         },
         () => {
            this._onFilter();
         },
      );
   }
   _onFilterMonth(evt) {
      // console.log(evt);
      this.setState(
         {
            monthDateFilter: evt.selectedDate,
            weekDateFilter: null,
            dayDateFilter: null,
         },
         () => {
            this._onFilter();
         },
      );
   }
   _onFilterDay(evt) {
      // console.log(evt);
      this.setState(
         {
            dayDateFilter: evt.selectedDate,
            weekDateFilter: null,
            monthDateFilter: null,
         },
         () => {
            this._onFilter();
         },
      );
   }
   _onFilterHour(evt) {
      // console.log(evt);
      this.setState(
         {
            hourDateFilter: evt.selectedDate,
         },
         () => {
            this._onFilter();
         },
      );
   }

   _onResetFilter() {
      this.setState(
         {
            userFilter: null,
            towerFilter: null,
            weekDateFilter: null,
            dayDateFilter: null,
            hourDateFilter: null,
            companyFilter: null,
            monthDateFilter: null,
         },
         () => {
            this._onFilter();
         },
      );
   }

   _onFilter() {
      const {
         weekDateFilter,
         dayDateFilter,
         hourDateFilter,
         userFilter,
         towerFilter,
         companyFilter,
         monthDateFilter,
      } = this.state;
      let filterClients = this.state.clients;
      if (userFilter !== null) {
         
         filterClients = filterClients.filter((req) => req.user).filter(
            (req) => req.user._id === userFilter,
         );
         
      }

      if (towerFilter !== null) {
         filterClients = filterClients.filter((req) => {
            if (req.tower === null) return false;
            return req.tower._id === towerFilter;
         });
      }

      if (companyFilter !== null) {
         filterClients = filterClients.filter(
            (req) =>
               req.user?.nameCompany === companyFilter ||
               req.user?.user?.nameCompany === companyFilter,
         );
      }

      if (monthDateFilter) {
         filterClients = filterClients.filter((req) =>
            isSameMonth(new Date(req.createdAt), monthDateFilter),
         );
      } else if (weekDateFilter) {
         filterClients = filterClients.filter(
            (req) =>
               isAfter(new Date(req.createdAt), weekDateFilter.startWeek) &&
               isBefore(new Date(req.createdAt), weekDateFilter.endWeek),
         );
      } else if (dayDateFilter) {
         filterClients = filterClients.filter((req) =>
            isSameDay(new Date(req.createdAt), dayDateFilter),
         );
      }

      if (hourDateFilter) {
         filterClients = filterClients.filter((req) => {
            return (
               getHours(new Date(req.createdAt)) === getHours(hourDateFilter)
            );
         });
      }

      // console.log(filterClients);
      this.setState({ filterClients });
   }
   render() {
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Reporte de Créditos</h1>
               </div>
            </div>
            <div className="Page-content">
               <div className="ReportContent">
                  <div className="FilterContainer">
                     <UserAutocomplete
                        onChange={this.onFilterUser.bind(this)}
                     />
                     <DateInputFilter
                        showMonthPicker={true}
                        valueWeekPicker={
                           this.state.weekDateFilter !== null
                              ? this.state.weekDateFilter.startWeek
                              : null
                        }
                        valueMonthPicker={this.state.monthDateFilter}
                        valueDayPicker={this.state.dayDateFilter}
                        valueHourPicker={this.state.hourDateFilter}
                        onChangeMonth={this._onFilterMonth.bind(this)}
                        onChangeWeek={this._onFilterWeek.bind(this)}
                        onChangeDay={this._onFilterDay.bind(this)}
                        onChangeHour={this._onFilterHour.bind(this)}
                     />
                     <FormControl
                        variant="outlined"
                        className="inputSelectFilter">
                        <InputLabel>Club</InputLabel>
                        <Select
                           name="type"
                           label="Club"
                           value={this.state.towerFilter}
                           onChange={this.onFilterTower.bind(this)}>
                           <MenuItem value={null}>Selecione un Club</MenuItem>
                           {this.props.towers.map((tower) => (
                              <MenuItem key={tower._id} value={tower._id}>
                                 {tower.name}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     <FormControl
                        variant="outlined"
                        className="inputSelectFilter">
                        <InputLabel>Empresa</InputLabel>
                        <Select
                           name="type"
                           label="Empresa"
                           value={this.state.companyFilter}
                           onChange={this._onFilterCompany.bind(this)}>
                           <MenuItem value={null}>
                              Selecione una empresa
                           </MenuItem>
                           {this.state.companies.map((company) => (
                              <MenuItem key={company} value={company}>
                                 {company}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     <Button onClick={this._onResetFilter.bind(this)}>
                        Reset
                     </Button>
                  </div>
                  <AgGrid
                     rowData={this.state.filterClients}
                     columnDefs={columnsReportUser}
                  />
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   users: state.userReducer.users,
   usersChildren: state.userReducer.usersChildren,
   towers: state.towerReducer.towers,
});

const mapDispatchToProps = (dispatch) => ({
   addListUserChild: (children) => dispatch(addListUserChildAction(children)),
   addListUser: (children) => dispatch(addListUserAction(children)),
   addListTower: (towers) => dispatch(addListTowerAction(towers)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportCreditsPage);
