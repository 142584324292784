import { IconButton, Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { http } from '../../utils/http';
import './ClubPage.scss';
import EditModalClub from './components/EditModalClub/EditModalClub';

class CreditPrice extends Component {
   state = {
      towerSelected: null,
      showModal: false,
      showModalFloors: false,
      creditPrice: {},
      typeEdit: 'PRICE',
   };

   async componentDidMount() {
      await this._getPrices();
   }

   async _getPrices() {
      http('/price/', 'GET', {})
         .then((res) => {
            this.setState({ creditPrice: res.data.data });
         })
         .catch((err) => console.error(err));
   }

   _showModal() {
      this.setState({ showModal: true });
   }

   _hideModal() {
      this.setState({ showModal: false, towerSelected: null });
      this._getPrices();
   }

   _handleRowClicked(value) {
      this.setState({ showModal: true, towerSelected: value });
   }

   _renderDate(value) {
      if (!value) return '';
      return value.toString().split('T')[0];
   }
   render() {
      const { creditPrice } = this.state;
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Administrar precio de creditos</h1>
               </div>
            </div>
            <div className="Page-content flex flex-col gap-y-4 py-10">
               <div className="flex flex-col py-10 px-10 items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                  <h2>
                     Precio actual{' '}
                     <span className="text-600 text-[20px]">
                        ${(creditPrice?.price || 0).toFixed(2)}
                     </span>
                  </h2>

                  <Tooltip title="Crear" aria-label="Crear">
                     <IconButton
                        onClick={() => {
                           this.setState(
                              {
                                 typeEdit: 'PRICE',
                                 showModal: true

                              },
                              () => {
                                 this._showModal.bind(this);
                              },
                           );
                        }}>
                        <i className="material-icons">edit</i>
                     </IconButton>
                  </Tooltip>
               </div>

               <div className="flex flex-col py-10 px-10 items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                  <h2>
                     Promociòn de <br />
                     {this._renderDate(creditPrice?.startPrice)} al{' '}
                     {this._renderDate(creditPrice?.endPrice)} el precio es $
                     <span className="text-600 text-[20px]">
                        {(creditPrice?.newPrice || 0).toFixed(2)}
                     </span>
                  </h2>
                  <Tooltip title="Crear" aria-label="Crear">
                     <IconButton
                        onClick={() => {
                           this.setState(
                              {
                                 typeEdit: 'DATES',
                                 showModal: true
                              },
                              () => {
                                 this._showModal.bind(this);
                              },
                           );
                        }}>
                        <i className="material-icons">edit</i>
                     </IconButton>
                  </Tooltip>
               </div>
            </div>
            <EditModalClub
               isOpen={this.state.showModal}
               onClose={this._hideModal.bind(this)}
               data={this.state.creditPrice}
               typeEdit={this.state.typeEdit}
            />
         </div>
      );
   }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CreditPrice);
