import {
   ADD_LIST_USER,
   ADD_LIST_USER_CHILD,
   ADD_USER,
   ADD_USER_CHILD,
   UPDATE_USER,
   UPDATE_USER_CHILD,
} from '../types/user.type';

const initialState = {
   users: [],
   usersChildren: [],
};

const userReducer = (state = initialState, action) => {
   switch (action.type) {
      case ADD_LIST_USER:
         return {
            ...state,
            users: [...action.payload],
         };

      case ADD_USER:
         return {
            ...state,
            users: [...state.users, action.payload],
         };

      case UPDATE_USER:
         return {
            ...state,
            users: state.users.map((user, index) => {
               if (user._id !== action.payload._id) {
                  // This isn't the item we care about - keep it as-is
                  return user;
               }

               // Otherwise, this is the one we want - return an updated value
               return {
                  ...user,
                  ...action.payload,
               };
            }),
         };

      case ADD_LIST_USER_CHILD:
         let obj = {};
         if (state.usersChildren.length > 0) {
            for (var i = 0, len = state.usersChildren.length; i < len; i++)
               obj[state.usersChildren[i]['_id']] = state.usersChildren[i];

            for (const user of action.payload) {
               obj[user._id] = user;
            }

            return {
               ...state,
               usersChildren: Object.values(obj),
            };
         } else {
            return {
               ...state,
               usersChildren: [...action.payload],
            };
         }

      case ADD_USER_CHILD:
         return {
            ...state,
            usersChildren: [...state.usersChildren, action.payload],
         };

      case UPDATE_USER_CHILD:
         return {
            ...state,
            usersChildren: state.usersChildren.map((user, index) => {
               if (user._id !== action.payload._id) {
                  // This isn't the item we care about - keep it as-is
                  return user;
               }

               // Otherwise, this is the one we want - return an updated value
               return {
                  ...user,
                  ...action.payload,
               };
            }),
         };
      default:
         return state;
   }
};

export { userReducer };
