import { updateMenu } from '../../redux/actions/menu.action';

export const columns = [
   { headerName: 'Nombre', field: 'name', filter: true, sortable: true },
   {
      headerName: 'Creación',
      field: 'createdAt',
      filter: true,
      sortable: true,
      cellRenderer: 'agDateCellRenderer',
   },
   {
      headerName: 'Actualización',
      field: 'updatedAt',
      filter: true,
      sortable: true,
      cellRenderer: 'agDateCellRenderer',
   },
   {
      headerName: 'Club',
      field: 'towers.0.name',
      filter: true,
      sortable: true,
   },
   {
      headerName: 'Estado',
      resizable: true,
      valueGetter: ({ data }) => {
         return data.active ? 'ACTIVO' : 'INACTIVO';
      },
   },

   {
      headerName: 'Acciones',
      cellRenderer: 'agActionButtonCellRenderer',
      width: 110,
      cellRendererParams: {
         active: {
            url: '/menu',
            action: updateMenu,
         },
      },
   },
];
