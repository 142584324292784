import {
   ADD_LIST_TOWER,
   ADD_TOWER,
   DELETE_TOWER,
   UPDATE_TOWER,
   ADD_LIST_AMENITY,
} from '../types/tower.type';
const initialState = {
   towers: [],
   amenitys: [],
};

const towerReducer = (state = initialState, action) => {
   switch (action.type) {
      case ADD_LIST_TOWER:
         return {
            ...state,
            towers: [...action.payload],
         };
      case ADD_LIST_AMENITY:
         return {
            ...state,
            amenitys: [...action.payload],
         };

      case ADD_TOWER:
         return {
            ...state,
            towers: [...state.towers, action.payload],
         };

      case UPDATE_TOWER:
         return {
            ...state,
            towers: state.towers.map((tower, index) => {
               if (tower._id !== action.payload._id) {
                  // This isn't the item we care about - keep it as-is
                  return tower;
               }

               // Otherwise, this is the one we want - return an updated value
               return {
                  ...tower,
                  ...action.payload,
               };
            }),
         };

      case DELETE_TOWER:
         return {
            ...state,
            towers: state.towers.filter(
               (tower, index) => tower._id !== action.payload._id,
            ),
         };
      default:
         return state;
   }
};

export { towerReducer };
