import {
   FormControl,
   IconButton,
   InputLabel,
   MenuItem,
   Select,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './ListItemMenu.scss';
// import PropTypes from 'prop-types'

function ListItemMenu({ section, foods, onChange, isUpdate }) {
   const [items, setItems] = useState([]);
   const [foodsFilter, setFoodFilter] = useState([]);

   useEffect(() => {
      setItems(section.items);
      _getFoodsSelect(section.items);
      onChange(section.items);
   }, []);

   const _handleChangeSelectFood = (evt) => {
      const value = evt.target.value;

      if (value === '') return;
      const itemsUpdate = [
         ...items,
         { _id: Date.now(), food: value, stock: 1 },
      ];
      setItems(itemsUpdate);
      onChange(itemsUpdate);
      _getFoodsSelect(itemsUpdate);
   };

   const _handleChangeStockFood = (evt, id) => {
      const value = evt.target.value;
      const itemsUpdate = items.map((item) => {
         if (item._id === id) {
            return {
               ...item,
               resetStock: value,
               stock: value,
            };
         }

         return item;
      });
      setItems(itemsUpdate);
      onChange(itemsUpdate);

      //   _getFoodsSelect(itemsUpdate);
   };

   const _deleteItem = (id) => {
      const itemsUpdate = items.filter((item) => item._id !== id);
      setItems(itemsUpdate);
      _getFoodsSelect(itemsUpdate);
      onChange(itemsUpdate);
   };

   const _getFoodsSelect = (items) => {
      const foodsUpdate = foods.filter((food) => {
         for (const item of items) {
            if (item.food._id === food._id) {
               return false;
            }
            return true;
         }
         return true;
      });

      setFoodFilter(foodsUpdate);
   };

   const _getItem = (item) => {
      const stock = isUpdate ? item.resetStock : item.stock;
      return (
         <li key={item._id} className="ListItemMenu-item">
            <div className="ListItemMenu-itemContainer">
               <div className="ListItemMenu-item_left">
                  <div className="ListItemMenu-item_image">
                     <img
                        src={item.food.image ? item.food.image : ''}
                        alt={item.food.name}
                     />
                  </div>
                  <div className="ListItemMenu-item_info">
                     <span className="itemTitle">{item.food.name}</span>
                     <span className="itemDescription">
                        {item.food.descriptionShort}
                     </span>
                  </div>
               </div>
               <div className="ListItemMenu-item_right">
                  <IconButton
                     edge="end"
                     aria-label="delete"
                     onClick={() => _deleteItem(item._id)}>
                     <i className="material-icons">delete</i>
                  </IconButton>
               </div>
            </div>
         </li>
      );
   };

   return (
      <div className="ListItemMenu">
         <ul className="ListItemMenu-list">
            {items.map((item) => _getItem(item))}
         </ul>
         <FormControl variant="outlined" className="ListItemMenu-select">
            <InputLabel>Comida</InputLabel>
            <Select
               name="food"
               label="Food"
               //   value={formModalUser.tower}
               onChange={_handleChangeSelectFood}>
               <MenuItem value={''}>Selecione una comida</MenuItem>
               {foodsFilter.map((food) => (
                  <MenuItem key={food._id} value={food}>
                     {food.name}
                  </MenuItem>
               ))}
            </Select>
         </FormControl>
      </div>
   );
}

ListItemMenu.propTypes = {};
const mapStateToProps = (state) => ({
   foods: state.foodReducer.foods,
});

export default connect(mapStateToProps)(ListItemMenu);
