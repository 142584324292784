import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../../../components/AgGrid/AgGrid';
import { http } from '../../../../utils/http';
import { columns } from './columns';
import { Pie } from 'react-chartjs-2';
import {
   Card,
   CardContent,
   CardHeader,
   Drawer,
   Grid,
   IconButton,
   Tooltip,
} from '@material-ui/core';
class ReportCreditsExtraPage extends Component {
   state = {
      creditsExtra: [],
      isOpenDrawer: false,
   };

   constructor(props) {
      super(props);
      this._getDataForChart.bind(this);
   }

   componentDidMount() {
      this._getCredits();
   }
   _getCredits() {
      http('/credit', 'GET', {})
         .then((res) => {
            console.log(res);

            const creditsExtra = res.data.data.map((credit) => {
               const creditsByRequest = credit.requestMoreCredits
                  .map((request) => request.amount)
                  .reduce((a, b) => a + b, 0);
               return {
                  companyName: credit.userRef.nameCompany,
                  creditsExtra: creditsByRequest,
               };
            });

            this.setState({
               creditsExtra: creditsExtra.sort(
                  (a, b) => a.creditsExtra - b.creditsExtra,
               ),
            });
         })
         .catch((err) => console.log(err));
   }

   _getDataForChart(isReverse = false) {
      let labels = [];
      let values = [];

      let credits = this.state.creditsExtra;

      if (isReverse) {
         credits = credits.reverse();
      }

      for (let i = 0; i < credits.length && i < 10; i++) {
         const data = credits[i];
         labels.push(i + 1 + '. ' + data.companyName);
         values.push(data.creditsExtra);
      }

      return {
         labels: labels,
         datasets: [
            {
               data: values,
               backgroundColor: [
                  '#FF6384',
                  '#36A2EB',
                  '#FFCE56',
                  '#F8f',
                  '#C42',
                  '#f1e5c3',
                  '#c8FF68',
                  '#cbcbcb',
                  '#a2e5f6',
                  '#a5FF',
               ],
               hoverBackgroundColor: [
                  '#FF6384',
                  '#36A2EB',
                  '#FFCE56',
                  '#F8f',
                  '#C42',
                  '#f1e5c3',
                  '#c8FF68',
                  '#cbcbcb',
                  '#a2e5f6',
                  '#a5FF',
               ],
            },
         ],
      };
   }
   _toggleDrawer() {
      this.setState({
         isOpenDrawer: !this.state.isOpenDrawer,
      });
   }

   render() {
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Reporte de Créditos Extras</h1>
               </div>
               <div className="header-actions">
                  {/* <Tooltip title="Gráficas" aria-label="Gráficas">
                     <IconButton onClick={this._toggleDrawer.bind(this)}>
                        <i className="material-icons">analytics</i>
                     </IconButton>
                  </Tooltip> */}
               </div>
            </div>
            <div className="Page-content">
               <div className="FilterContainer"></div>
               <div className="ReportContent">
                  <Grid container spacing={3}>
                     <Grid item xs={12}>
                        <Card className="card-chart">
                           <CardContent
                              style={{
                                 height: 'calc(100% - 72px)',
                                 overflow: 'auto',
                              }}>
                              <AgGrid
                                 rowData={this.state.creditsExtra}
                                 columnDefs={columns}
                              />
                           </CardContent>
                        </Card>
                     </Grid>
                     <Grid item xs={12} md={6} sm={12}>
                        <Card className="card-chart">
                           <CardHeader title="Empresas Top 10 que más han solicitado créditos" />
                           <CardContent
                              style={{
                                 height: 'calc(100% - 100px);',
                                 display: 'flex',
                                 width: '100%',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                                 // overflow: 'auto',
                              }}>
                              <div
                                 style={{
                                    width: '80%',
                                    height: '100%',
                                 }}>
                                 <Pie data={this._getDataForChart()} />
                              </div>
                           </CardContent>
                        </Card>
                     </Grid>
                     <Grid item xs={12} md={6} sm={12}>
                        <Card className="card-chart">
                           <CardHeader title="Empresas Top 10 que menos han solicitado créditos" />

                           <CardContent
                              style={{
                                 height: 'calc(100% - 100px);',
                                 display: 'flex',
                                 width: '100%',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                                 // overflow: 'auto',
                              }}>
                              <div
                                 style={{
                                    width: '80%',
                                    height: '100%',
                                 }}>
                                 <Pie data={this._getDataForChart(true)} />
                              </div>
                           </CardContent>
                        </Card>
                     </Grid>
                  </Grid>

                  {/* <Drawer
                     classes={{ paper: 'expand-drawer' }}
                     anchor={'right'}
                     open={this.state.isOpenDrawer}
                     onClose={this._toggleDrawer.bind(this)}>
                     <div className="content-chart-drawer">
                        <span className="title-chart-drawer">
                           Empresas con mayor solicitud de créditos
                        </span>
                     </div>
                     <div className="content-chart-drawer">
                        <span className="title-chart-drawer">
                           Empresas con menor solicitud de créditos
                        </span>
                     </div>
                  </Drawer> */}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
   mapStateToProps,
   mapDispatchToProps,
)(ReportCreditsExtraPage);
