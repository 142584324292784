import React from 'react';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './redux/store';
import './styles/index.scss';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/es-mx';
moment.locale('es-mx');
ReactDOM.render(
   <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <Provider store={store}>
         <App />
      </Provider>
   </MuiPickersUtilsProvider>,
   document.getElementById('root'),
);
