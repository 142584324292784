// import { deleteValetParking as deleteValetParkingAction } from "../../redux/actions/valetParking.action";

import { deleteNews } from '../../redux/actions/news.action';

export const columns = [
   {
      headerName: 'Titulo',
      field: 'title',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Contenido',
      field: 'body',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Link',
      field: 'link',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Fecha',
      field: 'createdAt',
      cellRenderer: 'agFullDateCellRenderer',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Acciones',
      cellRenderer: 'agActionButtonCellRenderer',
      width: 110,
      cellRendererParams: {
         del: {
            url: '/news',
            action: deleteNews,
         },
      },
   },
];
