import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

const AuthRouter = ({ component: Component, isLogged, ...rest }) => {
   return (
      <Route
         {...rest}
         render={(props) =>
            isLogged ? <Redirect to="/" /> : <Component {...props} />
         }
      />
   );
};
const mapStateToProps = (state) => ({
   isLogged: state.authReducer.isLogged,
});

export default connect(mapStateToProps)(AuthRouter);
