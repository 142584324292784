import { ADD_LIST_MENU, ADD_MENU, UPDATE_MENU } from '../types/menu.type';

export function addListMenu(data) {
   return {
      type: ADD_LIST_MENU,
      payload: data,
   };
}

export function addMenu(data) {
   return {
      type: ADD_MENU,
      payload: data,
   };
}

export function updateMenu(data) {
   return {
      type: UPDATE_MENU,
      payload: data,
   };
}
