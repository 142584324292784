import { IS_LOGGED, SIGN_IN, SIGN_OUT } from '../types/auth.type';

export function signInAction(user) {
   return {
      type: SIGN_IN,
      payload: user,
   };
}

export function signOutAction() {
   return {
      type: SIGN_OUT,
   };
}

export function setLoggedAction(token) {
   return {
      type: IS_LOGGED,
      payload: token,
   };
}
