import { TextField } from '@material-ui/core';
import React, { useRef } from 'react';
import './ChangeImage.scss';
function ChangeImage({ previewUrl, onChangeImage }) {
   const refImage = useRef(null);
   const _onChangeImage = (evt) => {
      const file = evt.target.files[0];
      const fileSize = file.size;

      if (fileSize > 100000000) {
         alert('El tamaño de la imagen no puede superar 1Mb');
         return;
      }

      console.log(file);
      let reader = new FileReader();
      reader.onload = () => {
         refImage.current.src = reader.result;
      };

      reader.readAsDataURL(file);

      onChangeImage(file);
   };

   return (
      <div className="ChangeImage">
         <div>
            <label htmlFor="file">
               Cargar imagen <span className="detail-upload-image">(El tamaño máximo de la imagen es de un 1Mb)</span>
            </label>
         </div>
         <div className="ChangeImage-container">
            <div className="ChangeImage-preview">
               {/* {previewUrl !== '' ? ( */}
               <img ref={refImage} src={previewUrl} alt="No Images" />
            </div>
            <div className="ChangeImage-input">
               <div className="wrapper">
                  <div className="file-upload">
                     <input
                        type="file"
                        name="file"
                        size
                        accept="image/gif, image/jpeg, image/png"
                        onChange={_onChangeImage}
                     />
                     <i className="material-icons">cloud_upload</i>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default ChangeImage;
