import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRouter = ({ component: Component, isLogged, ...rest }) => {
   return (
      <Route
         {...rest}
         render={(props) =>
            isLogged ? <Component {...props} /> : <Redirect to="/login" />
         }
      />
   );
};
const mapStateToProps = (state) => ({
   isLogged: state.authReducer.isLogged,
});

export default connect(mapStateToProps)(PrivateRouter);
