import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import Button from '../../../../components/Buttons/Button';
import Modal from '../../../../components/Modal/Modal';
import { http } from '../../../../utils/http';
import { connect } from 'react-redux';
import {
   addtUser as addUserAction,
   updateUser as updateUserAction,
} from '../../../../redux/actions/user.action';
import { convertArrayToObjectForError } from '../../../../utils/convertArrayToObject';

const initialState = {
   email: '',
   name: '',
   lastName: '',

   nameCompany: '',
   credits: '',
};

const initialStateError = {
   email: false,
   name: false,
   lastName: false,

   nameCompany: false,
   credits: false,
};

const EditModalUser = ({
   isOpen,
   onClose,
   user,
   addUser,
   updateUser,
   refresUsers,
}) => {
   const [formModalUser, setFormModalUser] = useState(initialState);
   const [errors, setErrors] = useState(initialStateError);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      if (user) {
         setFormModalUser({
            email: user.email,
            name: user.name,
            lastName: user.lastName,

            nameCompany: user.nameCompany,
            credits: user.credits.recharge,
         });
      }

      return () => {
         setFormModalUser(initialState);
         setErrors(initialStateError);
      };
   }, [user, isOpen]);

   const _onCreateUser = () => {
      // evt.preventDefault();
      setLoading(true);

      http('/user', 'POST', {
         ...formModalUser,
         credits: +formModalUser.credits,
      })
         .then((res) => {
            refresUsers && refresUsers();
            onClose();
         })
         .catch((err) => {
            console.error(err);
            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const _onEditUser = () => {
      // evt.preventDefault();
      setLoading(true);

      http(`/user/${user._id}`, 'PUT', {
         ...formModalUser,
         balanceReset: +formModalUser.credits,
      })
         .then((res) => {
            refresUsers && refresUsers();
            onClose();
         })
         .catch((err) => {
            console.error(err);
            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const _isDisableButtonModal = () => {
      const { email, name, lastName, nameCompany, credits } = formModalUser;

      return (
         email === '' ||
         name === '' ||
         lastName === '' ||
         nameCompany === '' ||
         credits === ''
      );
   };

   const _changeHandleText = (evt, key) => {
      setFormModalUser({
         ...formModalUser,
         [key]: evt.target.value,
      });
   };

   const _onlyNumber = (value) => {
      //   this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
   };

   return (
      <Modal
         actions={
            <div className="createUser-actions-modal">
               <Button className="actions-item" onClick={onClose}>
                  Cancelar
               </Button>
               <Button
                  loading={loading}
                  className="actions-item"
                  onClick={user ? _onEditUser : _onCreateUser}
                  disabled={_isDisableButtonModal()}>
                  Aceptar
               </Button>
            </div>
         }
         isOpen={isOpen}
         title={user !== null ? 'Editar Usuario' : 'Agregar Usuario'}
         description={
            user !== null
               ? `Actualice la información necesaria para editar un usuario`
               : 'Agrege la información necesaria para crear un nuevo usuario'
         }
         onClose={onClose}>
         <div className="createUser-content-modal">
            <TextField
               name="name"
               className="createUser-inputs"
               label="Nombre"
               variant="outlined"
               value={formModalUser.name}
               onChange={(evt) => _changeHandleText(evt, 'name')}
               error={errors.name !== false}
               helperText={errors.name}
            />
            <TextField
               name="lastName"
               className="createUser-inputs"
               label="Apellido"
               variant="outlined"
               value={formModalUser.lastName}
               onChange={(evt) => _changeHandleText(evt, 'lastName')}
               error={errors.lastName !== false}
               helperText={errors.lastName}
            />
            <TextField
               name="email"
               className="createUser-inputs"
               label="Correo"
               variant="outlined"
               disabled={user !== null}
               autoComplete="email"
               value={formModalUser.email}
               onChange={(evt) => _changeHandleText(evt, 'email')}
               error={errors.email !== false}
               helperText={errors.email}
            />
            <TextField
               name="nameCompany"
               className="createUser-inputs"
               label="Nombre Empresa"
               variant="outlined"
               value={formModalUser.nameCompany}
               onChange={(evt) => _changeHandleText(evt, 'nameCompany')}
               error={errors.nameCompany !== false}
               helperText={errors.nameCompany}
            />

            <TextField
               name="credits"
               className="createUser-inputs"
               label={
                  user !== null
                     ? 'Créditos de recarga mensual'
                     : 'Créditos Iniciales'
               }
               variant="outlined"
               onInput={_onlyNumber}
               value={formModalUser.credits}
               onChange={(evt) => _changeHandleText(evt, 'credits')}
               error={errors.credits !== false}
               helperText={errors.credits}
            />
         </div>
      </Modal>
   );
};

EditModalUser.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
   addUser: (user) => dispatch(addUserAction(user)),
   updateUser: (user) => dispatch(updateUserAction(user)),
});

export default connect(null, mapDispatchToProps)(EditModalUser);
