export function convertArrayToObjectForError(array, key) {
   const initialValue = {};
   return array.reduce((obj, item) => {
      return {
         ...obj,
         [item[key]]: Object.values(item['errors'])[0],
      };
   }, initialValue);
}
export function convertArrayToObject(array, key) {
   const initialValue = {};
   return array.reduce((obj, item) => {
      return {
         ...obj,
         [item[key]]: item,
      };
   }, initialValue);
}
