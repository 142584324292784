import { ADD_LIST_FOOD, ADD_FOOD, UPDATE_FOOD } from '../types/food.type';

const initialState = {
   foods: [],
};

const foodReducer = (state = initialState, action) => {
   switch (action.type) {
      case ADD_LIST_FOOD:
         return {
            ...state,
            foods: [...action.payload],
         };

      case ADD_FOOD:
         return {
            ...state,
            foods: [...state.foods, action.payload],
         };

      case UPDATE_FOOD:
         return {
            ...state,
            foods: state.foods.map((food, index) => {
               if (food._id !== action.payload._id) {
                  // This isn't the item we care about - keep it as-is
                  return food;
               }

               // Otherwise, this is the one we want - return an updated value
               return {
                  ...food,
                  ...action.payload,
               };
            }),
         };
      default:
         return state;
   }
};

export { foodReducer };
