import React, { Component } from 'react';
import { connect } from 'react-redux';
import './LoginPage.scss';
import LogoYama from '../../assets/images/yama-logo.png';
import { TextField } from '@material-ui/core';
import { http } from '../../utils/http';
import { signInAction } from '../../redux/actions/auth.action';
import { setStorage } from '../../utils/storage';
import Button from '../../components/Buttons/Button';

const initialStateError = {
   email: false,
   password: false,
};

class LoginPage extends Component {
   state = {
      email: '',
      password: '',
      loading: false,
      initialStateError,
   };

   onSubmitLogin(evt) {
      evt.preventDefault();
      const { history, signIn } = this.props;
      const email = evt.target.email.value;
      const password = evt.target.password.value;
      this.setState({ loading: true });
      http('/auth/admin/sign-in', 'POST', {
         email,
         password,
      })
         .then((res) => {
            signIn(res.data.data);
            setStorage('token', res.data.data.token);
            setStorage('user', res.data.data);
            history.push('/');
         })
         .catch((err) => {
            console.log(err);
            if (err?.error?.fields) {
               // const errors = Object.values(err?.error?.fields);
               // const value = convertArrayToObjectForError(errors, 'property');
               // this.setState({
               //    initialStateError: { ...initialStateError, ...value },
               // });
               // console.log(value);
            }
         })
         .finally(() => {
            this.setState({ loading: false });
         });
   }

   _handleChangeText(evt, key) {
      this.setState({ [key]: evt.target.value });
   }

   render() {
      return (
         <div className="LoginPage">
            <div className="LoginPage-container">
               <div className="LoginPage-content">
                  <img src={LogoYama} alt="Yama" className="logoHeader" />
                  <span className="titleHeader">Iniciar Sesión</span>

                  <form
                     name="formLogin"
                     className="formLogin"
                     onSubmit={this.onSubmitLogin.bind(this)}>
                     <TextField
                        id="outlined-basic"
                        label="Correo"
                        name="email"
                        variant="outlined"
                        onChange={(evt) => this._handleChangeText(evt, 'email')}
                        value={this.state.email}
                     />
                     <TextField
                        style={{ marginTop: 20 }}
                        id="outlined-basic"
                        name="password"
                        label="Contraseña"
                        onChange={(evt) =>
                           this._handleChangeText(evt, 'password')
                        }
                        variant="outlined"
                        value={this.state.password}
                     />

                     <Button
                        type="submit"
                        disabled={
                           this.state.email === '' || this.state.password === ''
                        }
                        loading={this.state.loading}
                        className="btn-form-login">
                        Iniciar Sesión
                     </Button>
                  </form>
               </div>
            </div>
         </div>
      );
   }
}

const mapDispatchToProps = (dispatch) => ({
   signIn: (user) => dispatch(signInAction(user)),
});

export default connect(null, mapDispatchToProps)(LoginPage);
