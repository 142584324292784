import {
   Button,
   FormControl,
   IconButton,
   InputLabel,
   MenuItem,
   Select,
   Tooltip,
} from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../components/AgGrid/AgGrid';
import { http } from '../../utils/http';
import { columns } from './columns';
import './FoodPage.scss';
import { addListFood as addListFoodAction } from '../../redux/actions/food.action';
import { addListTower as addListTowerAction } from '../../redux/actions/tower.action';
import EditModalFood from './components/EditModalFood/EditModalFood';

class FoodPage extends Component {
   constructor(props) {
      super();
      this._getFoods = this._getFoods.bind(this);
      this.state = {
         menus: [],
         foods: [],
         filterFoods: [],
         foodSelected: null,
         showModal: false,
         towerFilter: null,
      };
   }

   async componentDidMount() {
      await this._getTowers();
      await this._getFoods();
   }

   async _getTowers() {
      await http('/tower', 'GET', {})
         .then((res) => {
            this.props.addListTower(res.data.data);
         })
         .catch((err) => console.log(err));
   }

   async _getFoods() {
      // const menus = await this._getMenus();
      await http('/food', 'GET', {})
         .then((res) => {
            const foods = res.data.data;

            const foodReformated = foods.map((food) => {
               let newFood = {
                  ...food,
                  stock: 0,
                  resetStock: 0,
                  withTower: {},
               };

               // menus.forEach((menu) => {
               //    let stockByTower = 0;
               //    let resetStockByTower = 0;
               //    menu.sections.forEach((section) => {
               //       section.items.forEach((item) => {
               //          if (item.food._id === food?._id) {
               //             // console.log(food, item);
               //             stockByTower += item.stock;
               //             resetStockByTower += item.resetStock;
               //          }
               //       });
               //    });

               //    newFood.stock += stockByTower;
               //    newFood.resetStock += resetStockByTower;

               //    newFood.withTower[menu.towers[0]._id] = {
               //       stock: stockByTower,
               //       resetStock: resetStockByTower,
               //    };
               // });

               return newFood;
            });

            console.log(foodReformated);
            this.setState({
               filterFoods: foodReformated,
               foods: foodReformated,
            });
            // this.props.addListFood(foodReformated);
         })
         .catch((err) => {
            console.error(err);
         });
   }
   async _getMenus() {
      return await http('/menu/active', 'GET', {})
         .then((res) => {
            this.setState({
               menus: res.data.data,
            });

            // console.log

            return res.data.data;
         })
         .catch((err) => {
            console.error(err);
            return [];
         });
   }

   _onResetFilter() {
      this.setState(
         {
            towerFilter: null,
         },
         () => {
            this._onFilter();
         },
      );
   }

   _onFilter() {
      const { towerFilter, foods } = this.state;
      let filterFoods = foods;

      if (towerFilter !== null) {
         filterFoods = filterFoods
            .filter((req) => req.withTower[towerFilter] !== undefined)
            .map((food) => {
               return {
                  ...food,
                  stock: food.withTower[towerFilter].stock,
                  resetStock: food.withTower[towerFilter].resetStock,
               };
            });
      }

      // if (timeFilter) {
      //    filterFoods = filterFoods.filter(
      //       (req) =>
      //          isAfter(new Date(req.createdAt), timeFilter) ||
      //          isEqual(timeFilter, new Date(req.createdAt)),
      //    );
      // }

      this.setState({ filterFoods });
   }

   onFilterTower(evt) {
      const value = evt.target.value;
      this.setState(
         {
            towerFilter: value === null ? null : value,
         },
         () => {
            this._onFilter();
         },
      );
   }

   _showModal() {
      this.setState({ showModal: true });
   }

   _hideModal() {
      this._getFoods();
      this.setState({ showModal: false, foodSelected: null });
   }

   _handleRowClicked(value) {
      const user = value.data;
      this.setState({ showModal: true, foodSelected: user });
   }

   render() {
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Alimentos y Bebidas</h1>
               </div>
               <div className="header-actions">
                  <Tooltip title="Refrescar" aria-label="Refrescar">
                     <IconButton onClick={this._getFoods.bind(this)}>
                        <i className="material-icons">refresh</i>
                     </IconButton>
                  </Tooltip>
                  <Tooltip title="Crear" aria-label="Crear">
                     <IconButton onClick={this._showModal.bind(this)}>
                        <i className="material-icons">add</i>
                     </IconButton>
                  </Tooltip>
               </div>
            </div>
            <div className="Page-content">
               <div className="FilterContainer">
                  <FormControl variant="outlined" className="inputSelectFilter">
                     <InputLabel>Club</InputLabel>
                     <Select
                        name="type"
                        label="Club"
                        value={this.state.towerFilter}
                        onChange={this.onFilterTower.bind(this)}>
                        <MenuItem value={null}>Selecione un Club</MenuItem>
                        {this.props.towers.map((tower) => (
                           <MenuItem key={tower._id} value={tower._id}>
                              {tower.name}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>

                  <Button onClick={this._onResetFilter.bind(this)}>
                     Reset
                  </Button>
               </div>
               <div className="ReportContent">
                  <AgGrid
                     rowData={this.state.filterFoods}
                     columnDefs={[
                        ...columns,
                        {
                           headerName: 'Acciones',
                           cellRenderer: 'agDeleteFood',
                           width: 150,
                           cellRendererParams: {
                              del: {
                                 url: '/food',
                                 action: this._getFoods,
                                 redux: false,
                              },
                           },
                        },
                     ]}
                     onRowDoubleClicked={this._handleRowClicked.bind(this)}
                  />
               </div>
            </div>
            <EditModalFood
               isOpen={this.state.showModal}
               onClose={this._hideModal.bind(this)}
               food={this.state.foodSelected}
            />
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   foods: state.foodReducer.foods,
   towers: state.towerReducer.towers,
});

const mapDispatchToProps = (dispatch) => ({
   addListFood: (foods) => dispatch(addListFoodAction(foods)),
   addListTower: (towers) => dispatch(addListTowerAction(towers)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FoodPage);
