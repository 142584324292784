import {
   deleteAmenity,
   updateAmenity,
} from '../../redux/actions/amenity.action';
import ms from 'ms';
export const columns = [
   {
      headerName: 'Nombre',
      field: 'name',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Numero',
      field: 'number',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Aminidades',
      field: 'amenities.length',
      filter: true,
      sortable: true,
      resizable: true,
   },
];
