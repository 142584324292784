import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './Drawer.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
//import LogoYama from '../../assets/images/yama-logo-white.svg';
import { signOutAction } from '../../redux/actions/auth.action';
import { removeStorage } from '../../utils/storage';
// import YamaLogo from "../../assets/images/yama-logo-white.svg";
const Drawer = ({ user, isOpenDrawer, signOut }) => {
   const onSignOut = () => {
      removeStorage('token');
      removeStorage('user');
      signOut();
   };

   return (
      <aside className="Drawer">
         <div
            className={classNames('Drawer-container', {
               'open-drawer': isOpenDrawer,
            })}>
            <div className="Drawer-header">
               <div className="Drawer-header_image">
                  {/* <img src={LogoYama} alt={user.name} /> */}
               </div>
               {isOpenDrawer && (
                  <div className="Drawer-header_user">
                     <span className="user-name">{user.fullName}</span>
                     <span className="user-email">{user.email}</span>
                  </div>
               )}
            </div>

            <div className="Drawer-navigation">
               <ul className="navigationList">
                  {routes.map((route, index) => {
                     if (route.only) {
                        if (route.only === user.type) {
                           return (
                              <li key={index} className="navigationItem">
                                 <Link
                                    to={route.path}
                                    className="navigationItem-link">
                                    <i className="material-icons">
                                       {route.icon}
                                    </i>
                                    {isOpenDrawer && <span>{route.title}</span>}
                                 </Link>
                              </li>
                           );
                        }
                     } else {
                        return (
                           <li key={index} className="navigationItem">
                              <Link
                                 to={route.path}
                                 className="navigationItem-link">
                                 <i className="material-icons">{route.icon}</i>
                                 {isOpenDrawer && <span>{route.title}</span>}
                              </Link>
                           </li>
                        );
                     }
                  })}
               </ul>
            </div>
            <div className="Drawer-footer">
               <button className="footerItem" onClick={onSignOut}>
                  <i className="material-icons">exit_to_app</i>
                  {isOpenDrawer && <span>Cerrar sesión</span>}
               </button>
            </div>
         </div>
      </aside>
   );
};

Drawer.propTypes = {
   isOpenDrawer: PropTypes.bool,
};

const mapStateToProps = (state) => ({
   user: state.authReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
   signOut: () => dispatch(signOutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);

const routes = [
   {
      icon: 'people_alt',
      title: 'Usuarios',
      path: '/user',
   },

   {
      icon: 'fastfood',
      title: 'Alimentos',
      path: '/food',
   },
   {
      icon: 'menu_book',
      title: 'Menús',
      path: '/menu',
   },

   {
      icon: 'redeem',
      title: 'Cortesías',
      path: '/courtesy',
   },

   {
      icon: 'meeting_room',
      title: 'Amenidades',
      path: '/amenity',
   },
   {
      icon: 'announcement',
      title: 'Novedades',
      path: '/news',
   },
   {
      icon: 'business',
      title: 'Torres',
      path: '/towers',
   },
   {
      icon: 'business',
      title: 'Reservaciones al día',
      path: '/reservation-day',
   },
   {
      icon: 'money',
      title: 'Precio de creditos',
      path: '/price-credit',
   },
];

/*
 {
      icon: 'meeting_room',
      title: 'floors',
      path: '/floors',
      only: 'DEV',
   },

      {
      icon: 'business',
      title: 'Clubs',
      path: '/club',
      only: 'DEV',
   },
    {
      icon: 'local_parking',
      title: 'Valet Parking',
      path: '/valet-parking',
   },
*/
