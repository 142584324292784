import { Button } from '@material-ui/core';

import React, { useState } from 'react';
import HistoryModalUser from '../../../pages/User/components/HistoryModalUser/HistoryModalUser';

const AgButtonHistoryUserCellRenderer = ({ title, data }) => {
   const [open, setOpen] = useState(false);

   const handleClickOpen = () => {
      setOpen(true);
   };

   const handleClose = () => {
      setOpen(false);
   };

   return (
      <span>
         <Button onClick={handleClickOpen}>{title}</Button>

         {open && (
            <HistoryModalUser
               showModal={open}
               dismissModal={handleClose}
               userId={data._id}
            />
         )}
      </span>
   );
};

export default AgButtonHistoryUserCellRenderer;
