import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../components/AgGrid/AgGrid';
import { http } from '../../utils/http';
import './ReservationPage.scss';
import { columns } from './columns';
import {
   IconButton,
   Tooltip,
   Select,
   MenuItem,
   FormControl,
   InputLabel,
   Button,
   TextField,
} from '@material-ui/core';
import {
   addListReservation as addListReservationAction,
   addListClub as addListClubAction,
} from '../../redux/actions/reservation.action';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import CreateExcel from '../../class/CreateExcel';

class ReservationPage extends Component {
   state = {
      clubs: [],
      reservations: [],
      clubFilter: null,
      filterReservations: [],
      start: null,
      end: null,
   };

   componentDidMount() {
      const date = new Date();
      this.setState({
         start: format(startOfMonth(date), 'yyyy-MM-dd'),
         end: format(endOfMonth(date), 'yyyy-MM-dd'),
      });
      this._getClubs();
   }

   _getReservations({ cludId, start, end }) {
      http(`/reservation?clubId=${cludId}&start=${start}&end=${end}`, 'GET', {})
         .then((res) => {
            this.props.addListReservation(res.data.data);
            this.setState({
               reservations: res.data.data,
               filterReservations: res.data.data,
            });
         })
         .catch((err) => console.error(err));
   }

   _getClubs() {
      http('/tower', 'GET', {})
         .then((res) => {
            this.props.addListClub(res.data.data);
            this.setState({
               clubs: res.data.data,
            });
         })
         .catch((err) => console.error(err));
   }

   _onFilter() {
      const { clubFilter, reservations } = this.state;
      let filterReservations = reservations;

      if (clubFilter !== null) {
         filterReservations = filterReservations.filter(
            (item) => item.tower._id === clubFilter,
         );
      }

      this.setState({ filterReservations });
   }

   _onResetFilter() {
      this.setState(
         {
            clubFilter: null,
         },
         () => {
            this._onFilter();
         },
      );
   }
   _showModal() {}
   render() {
      const { filterReservations } = this.state;
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Reservaciones</h1>
                  <div className="FilterContainer">
                     <FormControl
                        variant="outlined"
                        className="inputSelectFilter">
                        <InputLabel>Club</InputLabel>
                        <Select
                           name="type"
                           label="Club"
                           value={this.state.clubFilter}
                           onChange={(event) => {
                              const value = event.target.value;
                              this.setState({ clubFilter: value });
                           }}>
                           <MenuItem value={null}>
                              Selecciona una opción
                           </MenuItem>
                           {this.props.clubs.map((club) => (
                              <MenuItem key={club._id} value={club._id}>
                                 {club.name}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     <div variant="outlined" className="inputSelectFilter">
                        <InputLabel>Fecha Inicio</InputLabel>
                        <TextField
                           type="date"
                           placeholder=""
                           value={this.state.start}
                           onChange={(event) => {
                              console.log(event.target.value);
                              this.setState({
                                 start: event.target.value,
                              });
                           }}
                        />
                     </div>
                     <div variant="outlined" className="inputSelectFilter">
                        <InputLabel>Fecha fin</InputLabel>
                        <TextField
                           type="date"
                           placeholder=""
                           value={this.state.end}
                           onChange={(event) => {
                              this.setState({
                                 end: event.target.value,
                              });
                           }}
                        />
                     </div>
                     <Button
                        onClick={() => {
                           const { end, start, clubFilter } = this.state;
                           this._getReservations({
                              cludId: clubFilter,
                              end,
                              start,
                           });
                        }}>
                        Buscar
                     </Button>
                     <Button
                        onClick={() => {
                           if (filterReservations) {
                              const excel = new CreateExcel(
                                 'Reservaciones',
                                 [
                                    {
                                       headerName: 'Usuario',
                                       field: 'fullName',
                                    },
                                    {
                                       headerName: 'Hora de inicio',
                                       field: 'start',
                                    },
                                    {
                                       headerName: 'Hora de fin',
                                       field: 'end',
                                    },
                                    {
                                       headerName: 'Amenidad',
                                       field: 'amenity',
                                    },
                                    { headerName: 'Club', field: 'club' },
                                    {
                                       headerName: 'Créditos usados',
                                       field: 'creditsUsage',
                                    },
                                    {
                                       headerName: 'Estatus',
                                       field: 'state',
                                    },
                                 ],
                                 filterReservations.map((row) => {
                                    return {
                                       fullName: row.user.fullName,
                                       start: row.schedule.start,
                                       end: row.schedule.end,
                                       amenity: row.amenity.name,
                                       club: row.tower.name,
                                       creditsUsage: row.creditsUsage,
                                       state: row.state,
                                    };
                                 }),
                              );
                              excel.createDefaultExcel();
                           }
                        }}>
                        EXPORTAR A EXCEL
                     </Button>
                  </div>
               </div>
               <div className="header-actions">
                  <Tooltip title="Refrescar" aria-label="Refrescar">
                     <IconButton onClick={this._getReservations.bind(this)}>
                        <i className="material-icons">refresh</i>
                     </IconButton>
                  </Tooltip>
               </div>
            </div>
            <div className="Page-content">
               <AgGrid
                  rowData={this.state.filterReservations}
                  columnDefs={columns}
               />
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   reservations: state.reservationReducer.reservations,
   clubs: state.reservationReducer.clubs,
});

const mapDispatchToProps = (dispatch) => ({
   addListReservation: (res) => dispatch(addListReservationAction(res)),
   addListClub: (res) => dispatch(addListClubAction(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationPage);
