import { ADD_LIST_TOWER, ADD_TOWER, DELETE_TOWER, UPDATE_TOWER, ADD_LIST_AMENITY } from '../types/tower.type';

export function addListTower(data) {
   return {
      type: ADD_LIST_TOWER,
      payload: data,
   };
}

export function addListAmenity(data) {
   return {
      type: ADD_LIST_AMENITY,
      payload: data,
   };
}

export function addTower(data) {
   return {
      type: ADD_TOWER,
      payload: data,
   };
}

export function updateTower(data) {
   return {
      type: UPDATE_TOWER,
      payload: data,
   };
}

export function deleteTower(data) {
   return {
      type: DELETE_TOWER,
      payload: data,
   };
}
