import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { getHours, isAfter, isBefore, isSameDay } from 'date-fns';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../../../components/AgGrid/AgGrid';
import DateInputFilter from '../../../../components/DateInputFilter/DateInputFilter';
import { http } from '../../../../utils/http';
import { columns } from './columns';

const initialState = {
   valetParkings: [],
   filterValetParkings: [],
   towers: [],
   weekDateFilter: null,
   dayDateFilter: null,
   hourDateFilter: null,
   towerFilter: null,
};
class ReportValetParkingPage extends Component {
   state = initialState;

   async componentDidMount() {
      await Promise.all([this._getTowers(), this._getValetParking()]);
   }

   async _getValetParking() {
      await http('/valet-parking', 'GET', {})
         .then((res) => {
            const data = res.data.data.reverse();
            this.setState({ valetParkings: data, filterValetParkings: data });
         })
         .catch((err) => {
            console.error(err);
         });
   }

   async _getTowers() {
      await http('/tower', 'GET', {})
         .then((res) => {
            console.log(res);

            this.setState({
               towers: res.data.data,
            });
         })
         .catch((err) => console.log(err));
   }

   _onFilterWeek(evt) {
      // console.log(evt);
      this.setState(
         {
            weekDateFilter: evt,
            dayDateFilter: null,
         },
         () => {
            this._onFilter();
         },
      );
   }
   _onFilterDay(evt) {
      // console.log(evt);
      this.setState(
         {
            dayDateFilter: evt.selectedDate,
            weekDateFilter: null,
         },
         () => {
            this._onFilter();
         },
      );
   }

   onFilterTower(evt) {
      const value = evt.target.value;
      this.setState(
         {
            towerFilter: value === null ? null : value,
         },
         () => {
            this._onFilter();
         },
      );
   }

   _onFilterHour(evt) {
      // console.log(evt);
      this.setState(
         {
            hourDateFilter: evt.selectedDate,
         },
         () => {
            this._onFilter();
         },
      );
   }

   _onResetFilter() {
      this.setState(
         {
            weekDateFilter: null,
            dayDateFilter: null,
            hourDateFilter: null,
            towerFilter: null,
         },
         () => {
            this._onFilter();
         },
      );
   }

   _onFilter() {
      const {
         weekDateFilter,
         dayDateFilter,
         hourDateFilter,
         towerFilter,
      } = this.state;
      let filterValetParkings = this.state.valetParkings;

      if (towerFilter !== null) {
         filterValetParkings = filterValetParkings.filter(
            (req) => req.tower._id === towerFilter,
         );
      }

      if (weekDateFilter) {
         filterValetParkings = filterValetParkings.filter(
            (req) =>
               isAfter(new Date(req.createdAt), weekDateFilter.startWeek) &&
               isBefore(new Date(req.createdAt), weekDateFilter.endWeek),
         );
      } else if (dayDateFilter) {
         filterValetParkings = filterValetParkings.filter((req) =>
            isSameDay(new Date(req.createdAt), dayDateFilter),
         );
      }

      if (hourDateFilter) {
         filterValetParkings = filterValetParkings.filter((req) => {
            return (
               getHours(new Date(req.createdAt)) === getHours(hourDateFilter)
            );
         });
      }

      console.log(filterValetParkings);
      this.setState({ filterValetParkings });
   }

   render() {
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Reporte de solicitudes de Valet Parking</h1>
               </div>
               {/* <div className="header-actions">
                  <Tooltip title="Crear" aria-label="Crear">
                     <IconButton onClick={this._showModal.bind(this)}>
                        <i className="material-icons">add</i>
                     </IconButton>
                  </Tooltip>
               </div> */}
            </div>
            <div className="Page-content">
               <div className="FilterContainer">
                  <DateInputFilter
                     valueWeekPicker={
                        this.state.weekDateFilter !== null
                           ? this.state.weekDateFilter.startWeek
                           : null
                     }
                     valueDayPicker={this.state.dayDateFilter}
                     valueHourPicker={this.state.hourDateFilter}
                     onChangeWeek={this._onFilterWeek.bind(this)}
                     onChangeDay={this._onFilterDay.bind(this)}
                     onChangeHour={this._onFilterHour.bind(this)}
                  />
                  <FormControl variant="outlined" className="inputSelectFilter">
                     <InputLabel>Club</InputLabel>
                     <Select
                        name="type"
                        label="Club"
                        value={this.state.towerFilter}
                        onChange={this.onFilterTower.bind(this)}>
                        <MenuItem value={null}>Selecione un Club</MenuItem>
                        {this.state.towers.map((tower) => (
                           <MenuItem key={tower._id} value={tower._id}>
                              {tower.name}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
                  <Button onClick={this._onResetFilter.bind(this)}>
                     Reset
                  </Button>
               </div>
               <div className="ReportContent">
                  <AgGrid
                     rowData={this.state.filterValetParkings}
                     columnDefs={columns}
                  />
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
   mapStateToProps,
   mapDispatchToProps,
)(ReportValetParkingPage);
