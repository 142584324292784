export const columns = [
   {
      headerName: 'Empresa',
      field: 'companyName',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Créditos Extras',
      field: 'creditsExtra',
      filter: true,
      resizable: true,
      sortable: true,
   },
];
