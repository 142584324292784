export const columns = [
   { headerName: 'Codigo', field: 'code', filter: true, sortable: true },
   { headerName: 'Nombre', field: 'name', filter: true, sortable: true },
   {
      headerName: 'Tipo de Cocinero',
      field: 'type',
      filter: true,
      sortable: true,
      valueGetter: ({ data }) => {
         return data.type === 'CHEF' ? 'COCINA' : 'BAR';
      },
   },
   {
      headerName: 'Descripcion',
      field: 'descriptionShort',
      filter: true,
      sortable: true,
   },
   {
      headerName: 'Descripcion larga',
      field: 'descriptionLong',
      filter: true,
      sortable: true,
   },
   {
      headerName: 'Precio',
      field: 'price.$numberDecimal',
      filter: true,
      sortable: true,
   },
   {
      headerName: 'Peso',
      field: 'weight.$numberDecimal',
      filter: true,
      sortable: true,
   },
   
];
