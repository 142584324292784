import { IconButton, Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../components/AgGrid/AgGrid';
import { http } from '../../utils/http';
import { columns } from './columns';
import EditModalNews from './components/EditModalNew/EditModalNew';
import { addListNews as addListNewsAction } from '../../redux/actions/news.action';

class NewsPage extends Component {
   state = {
      newSelected: null,
      showModal: false,
   };

   async componentDidMount() {
      await this._getNews();
   }

   async _getNews() {
      await http('/news', 'GET', {})
         .then((res) => {
            this.props.addListNews(res.data.data);
         })
         .catch((err) => console.error(err));
   }

   _showModal() {
      this.setState({ showModal: true });
   }

   _hideModal() {
      this.setState({ showModal: false, newSelected: null });
   }

   _handleRowClicked(value) {
      const newSelected = value.data;
      this.setState({ showModal: true, newSelected });
   }

   render() {
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Novedades</h1>
               </div>
               <div className="header-actions">
                  <Tooltip title="Refrescar" aria-label="Refrescar">
                     <IconButton onClick={this._getNews.bind(this)}>
                        <i className="material-icons">refresh</i>
                     </IconButton>
                  </Tooltip>
                  <Tooltip title="Crear" aria-label="Crear">
                     <IconButton onClick={this._showModal.bind(this)}>
                        <i className="material-icons">add</i>
                     </IconButton>
                  </Tooltip>
               </div>
            </div>
            <div className="Page-content">
               <AgGrid
                  rowData={this.props.news}
                  columnDefs={columns}
                  onRowDoubleClicked={this._handleRowClicked.bind(this)}
               />
            </div>
            <EditModalNews
               isOpen={this.state.showModal}
               onClose={this._hideModal.bind(this)}
               newItem={this.state.newSelected}
            />
         </div>
      );
   }
}
const mapStateToProps = (state) => ({
   news: state.newsReducer.news,
});

const mapDispatchToProps = (dispatch) => ({
   addListNews: (towers) => dispatch(addListNewsAction(towers)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NewsPage);
