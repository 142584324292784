import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
   FormControl,
   InputLabel,
   MenuItem,
   Select,
   TextField,
} from '@material-ui/core';
import Button from '../../../../components/Buttons/Button';
import Modal from '../../../../components/Modal/Modal';
import { http } from '../../../../utils/http';
import { connect } from 'react-redux';
import {
   addtChef as addChefAction,
   updateChef as updateChefAction,
} from '../../../../redux/actions/chef.action';
import { convertArrayToObjectForError } from '../../../../utils/convertArrayToObject';

const initialState = {
   email: '',
   name: '',
   lastName: '',

   tower: '',
   typeChef: '',
};
const initialStateError = {
   email: false,
   name: false,
   lastName: false,
   tower: false,
   typeChef: false,
};

const EditModalChef = ({
   isOpen,
   onClose,
   chef,
   addChef,
   updateChef,
   towers,
   isConcierge,
}) => {
   const [formModalUser, setFormModalUser] = useState(initialState);
   const [loading, setLoading] = useState(false);
   const [errors, setErrors] = useState(initialStateError);

   useEffect(() => {
      if (chef) {
         console.log(chef);
         setFormModalUser({
            email: chef.email,
            name: chef.name,
            lastName: chef.lastName,

            tower: chef.tower._id,
            typeChef: chef.typeChef,
         });
      }

      return () => {
         setFormModalUser(initialState);
         setErrors(initialStateError);
      };
   }, [chef, isOpen]);

   const _onCreateUser = (isConcierge) => {
      // evt.preventDefault();
      setLoading(true);
      http(isConcierge ? '/user/concierge' : '/user/chef', 'POST', formModalUser)
         .then((res) => {
            console.log(res);
            addChef(res.data.data);
            onClose();
         })
         .catch((err) => {
            console.error(err);
            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const _onEditUser = (isConcierge) => {
      // evt.preventDefault();
      setLoading(true);
      http(isConcierge ? `/user/concierge/${chef._id}` : `/user/chef/${chef._id}`, 'PUT', formModalUser)
         .then((res) => {
            console.log(res);
            updateChef(res.data.data);
            onClose();
         })
         .catch((err) => {
            console.error(err);
            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const _isDisableButtonModal = (isConcierge) => {
      const {
         email,
         name,
         lastName,

         tower,
         typeChef,
      } = formModalUser;
      if (isConcierge) {
         return (
            email === '' ||
            name === '' ||
            lastName === '' ||
            tower === '' 
         );
      } else {
         return (
            email === '' ||
            name === '' ||
            lastName === '' ||
            tower === '' ||
            typeChef === ''
         );
      }
   };

   const _changeHandleText = (evt, key) => {
      setFormModalUser({
         ...formModalUser,
         [key]: evt.target.value,
      });
   };

   const _onlyNumber = (value) => {
      //   this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
   };

   return (
      <Modal
         actions={
            <div className="createUser-actions-modal">
               <Button className="actions-item" onClick={onClose}>
                  Cancelar
               </Button>
               <Button
                  loading={loading}
                  className="actions-item"
                  onClick={()=>{
                     chef ? 
                     _onEditUser(isConcierge):
                     _onCreateUser(isConcierge)
                  }}
                  disabled={_isDisableButtonModal(isConcierge)}>
                  Aceptar
               </Button>
            </div>
         }
         isOpen={isOpen}
         title={
            chef !== null
               ? isConcierge
                  ? 'Editar concierge'
                  : 'Editar Cocina'
               : isConcierge
               ? 'Agregar concierge'
               : 'Agregar Cocina'
         }
         description={
            chef !== null
               ? `Actualice la información necesaria para editar un usuario de ${isConcierge ? 'concierge': 'cocina'}` 
               : `Agrege la información necesaria para crear un nuevo usuario de ${isConcierge ? 'concierge': 'cocina'}`
         }
         onClose={onClose}>
         <div className="createUser-content-modal">
            <TextField
               name="name"
               className="createUser-inputs"
               label="Nombre"
               variant="outlined"
               value={formModalUser.name}
               error={errors.name !== false}
               helperText={errors.name}
               onChange={(evt) => _changeHandleText(evt, 'name')}
            />
            <TextField
               name="lastName"
               className="createUser-inputs"
               label="Apellido"
               variant="outlined"
               value={formModalUser.lastName}
               error={errors.lastName !== false}
               helperText={errors.lastName}
               onChange={(evt) => _changeHandleText(evt, 'lastName')}
            />
            <TextField
               name="email"
               className="createUser-inputs"
               label="Correo"
               variant="outlined"
               disabled={chef !== null}
               autoComplete="email"
               value={formModalUser.email}
               error={errors.email !== false}
               helperText={errors.email}
               onChange={(evt) => _changeHandleText(evt, 'email')}
            />

            <FormControl
               variant="outlined"
               className="createUser-inputs"
               error={errors.tower !== false}>
               <InputLabel>Club</InputLabel>
               <Select
                  name="type"
                  label="Club"
                  value={formModalUser.tower}
                  onChange={(evt) => _changeHandleText(evt, 'tower')}>
                  <MenuItem value={''}>Selecione un Club</MenuItem>
                  {towers.map((tower) => (
                     <MenuItem key={tower._id} value={tower._id}>
                        {tower.name}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
            {!isConcierge && (
               <FormControl
                  variant="outlined"
                  className="createUser-inputs"
                  error={errors.typeChef !== false}>
                  <InputLabel>Tipo de cocina</InputLabel>
                  <Select
                     name="type"
                     label="Tipo de cocina"
                     value={formModalUser.typeChef}
                     onChange={(evt) => _changeHandleText(evt, 'typeChef')}>
                     <MenuItem value="">Selecione un tipo de cocina</MenuItem>
                     <MenuItem value={'BAR'}>Bar</MenuItem>
                     <MenuItem value={'CHEF'}>Cocina</MenuItem>
                  </Select>
               </FormControl>
            )}
         </div>
      </Modal>
   );
};

EditModalChef.propTypes = {};

const mapStateToProps = (state) => ({
   towers: state.towerReducer.towers,
});

const mapDispatchToProps = (dispatch) => ({
   addChef: (user) => dispatch(addChefAction(user)),
   updateChef: (user) => dispatch(updateChefAction(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditModalChef);
