import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
   TextField,
   Accordion,
   AccordionDetails,
   AccordionSummary,
} from '@material-ui/core';
import Button from '../../../../components/Buttons/Button';
import Modal from '../../../../components/Modal/Modal';
import { http } from '../../../../utils/http';
import { connect } from 'react-redux';
import {
   addTower as addTowerAction,
   updateTower as updateTowerAction,
} from '../../../../redux/actions/tower.action';

import ChangeImage from '../../../../components/ChangeImage/ChangeImage';
import ListItemAmenity from '../ListItemAmenity/ListItemAmenity';
import { convertArrayToObjectForError } from '../../../../utils/convertArrayToObject';
const initialState = {
   name: '',
   address: '',
   time_start: '',
   time_end: '',
   // weekSchedule: {
   //    start: '',
   //    end: '',
   // },
   // weekendSchedule: {
   //    start: '',
   //    end: '',
   // },
   image: '',
   floors: [],
};
const initialStateError = {
   name: false,
   address: false,
   time_start: false,
   time_end: false,
   // weekSchedule: {
   //    start: false,
   //    end: false,
   // },
   // weekendSchedule: {
   //    start: false,
   //    end: false,
   // },
   // image: '',
};

const EditModalClub = ({
   isOpen,
   onClose,
   tower,
   addTower,
   updateTower,
   amenitys,
}) => {
   const [formModalTower, setFormModalTower] = useState(initialState);
   const [loading, setLoading] = useState(false);
   const [errors, setErrors] = useState(initialStateError);

   const [imageUpload, setImageUpload] = useState(null);

   useEffect(() => {
      if (tower) {
         setFormModalTower({
            name: tower.name,
            address: tower.address,
            time_start:
               ('0' + new Date(tower.weekSchedule.start).getHours()).slice(-2) +
               ':' +
               ('0' + new Date(tower.weekSchedule.start).getMinutes()).slice(
                  -2,
               ),
            time_end:
               ('0' + new Date(tower.weekSchedule.end).getHours()).slice(-2) +
               ':' +
               ('0' + new Date(tower.weekSchedule.end).getMinutes()).slice(-2),
            image: tower.image,
            floors: tower.floors,
         });
      }

      return () => {
         setFormModalTower(initialState);
         setImageUpload(null);
         setErrors(initialStateError);
      };
   }, [tower, isOpen]);

   const _onCreateClub = () => {
      // evt.preventDefault();
      setLoading(true);
      let date_init = new Date();
      let date_end = new Date();
      date_init.setHours(
         formModalTower.time_start.substr(0, 2),
         formModalTower.time_start.substr(4, 5),
      );
      date_end.setHours(
         formModalTower.time_end.substr(0, 2),
         formModalTower.time_end.substr(4, 5),
      );

      let dataToSend = {
         ...formModalTower,
         weekSchedule: {
            start: date_init,
            end: date_end,
         },
         weekendSchedule: {
            start: new Date(),
            end: new Date(),
         },
      };
      delete dataToSend.time_end;
      delete dataToSend.time_start;
      http('/tower', 'POST', dataToSend)
         .then(async (res) => {
            console.log(res);
            addTower(res.data.data);
            updateTower(res.data.data);
            if (imageUpload !== null) {
               await onSubmitImage(res.data.data._id);
            }
            onClose();
         })
         .catch((err) => console.error(err))
         .finally(() => {
            setLoading(false);
         });
   };

   const _onEditClub = () => {
      // evt.preventDefault();
      // setLoading(true);
      let date_init = new Date();
      let date_end = new Date();
      date_init.setHours(
         formModalTower.time_start.substr(0, 2),
         formModalTower.time_start.substr(4, 5),
      );
      date_end.setHours(
         formModalTower.time_end.substr(0, 2),
         formModalTower.time_end.substr(4, 5),
      );

      let dataToSend = {
         ...formModalTower,
         weekSchedule: {
            start: date_init,
            end: date_end,
         },
         weekendSchedule: {
            start: new Date(),
            end: new Date(),
         },
      };
      // delete dataToSend.time_end;
      // delete dataToSend.time_start;
      // if (dataToSend.floors) {
      //    for (let i = 0; i < dataToSend.floors.length; i++) {
      //       if (dataToSend.floors[i].amenities) {
      //          let arr = dataToSend.floors[i].amenities.map((item) => item._id);
      //          dataToSend.floors[i].amenities = arr;
      //          if (dataToSend.floors[i].isNew) {
      //             delete dataToSend.floors[i].isNew;
      //             delete dataToSend.floors[i]._id;
      //          }
      //       }
      //    }
      // }
      
      http(`/tower/${tower._id}`, 'PUT', dataToSend)
         .then(async (res) => {
            updateTower(res.data.data);
            if (imageUpload !== null) {
               await onSubmitImage(res.data.data._id);
            }
            onClose();
         })
         .catch((err) => {
            console.error(err);
            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const onSubmitImage = async (id) => {
      let formData = new FormData();
      formData.append('towerImage', imageUpload);

      return await http(`/tower/${id}/upload`, 'POST', formData)
         .then((res) => {
            updateTower(res.data.data);
            console.log(res);
         })
         .catch((err) => {
            console.log(err);
            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         });
   };

   const _isDisableButtonModal = () => {
      const {
         name,
         address,
         time_start,
         time_end,
         // allergens,
      } = formModalTower;

      return (
         name === '' || address === '' || time_start === '' || time_end === ''
      );
   };

   const _changeHandleText = (evt, key) => {
      setFormModalTower({
         ...formModalTower,
         [key]: evt.target.value,
      });
   };

   const getFloors = () => {
      return formModalTower.floors.map((floor) => {
         return getFloor(floor);
      });
   };

   const _getFloorForSubmit = () => {
      console.log(formModalTower);
      return formModalTower.floors.map((floor) => {
         return {
            name: floor.name,
            amenities: floor.amenities.map((item) => {
               return {
                  amenities: item._id,
               };
            }),
         };
      });
   };

   const _addNewFloor = () => {
      setFormModalTower({
         ...formModalTower,
         floors: [
            ...formModalTower.floors,
            {
               _id: Date.now(),
               name: '',
               amenities: [],
               isNew: true,
               number: 0,
            },
         ],
      });
   };

   const _updateFloorItems = (amenities, floorId) => {
      setFormModalTower({
         ...formModalTower,
         floors: formModalTower.floors.map((floor) => {
            if (floor._id === floorId) {
               return {
                  ...floor,
                  amenities,
               };
            }
            return floor;
         }),
      });
      console.log(formModalTower);
   };

   const _changeHandleTextNameSection = (evt, floorId) => {
      setFormModalTower({
         ...formModalTower,
         floors: formModalTower.floors.map((floor) => {
            if (floor._id === floorId) {
               return {
                  ...floor,
                  name: evt.target.value,
               };
            }
            return floor;
         }),
      });
   };

   const _changeHandleNumberSection = (evt, floorId) => {
      setFormModalTower({
         ...formModalTower,
         floors: formModalTower.floors.map((floor) => {
            if (floor._id === floorId) {
               return {
                  ...floor,
                  number: evt.target.value,
               };
            }
            return floor;
         }),
      });
   };

   const getFloor = (floor) => {
      return (
         <Accordion
            key={floor._id}
            style={{ margin: '20px 0', backgroundColor: '#f6f6f6' }}>
            <AccordionSummary
               // expandIcon={() => <i className="material-icons">expand_more</i>}
               aria-controls="panel1a-content"
               id="panel1a-header">
               <TextField
                  value={floor.name}
                  onChange={(val) =>
                     _changeHandleTextNameSection(val, floor._id)
                  }
               />
               <TextField
                  value={floor.number}
                  onChange={(val) => _changeHandleNumberSection(val, floor._id)}
                  disabled={floor.isNew ? false : true}
                  style={{ marginLeft: 20 }}
                  type="number"
               />
            </AccordionSummary>
            <AccordionDetails>
               <ListItemAmenity
                  floor={floor}
                  onChange={(items) => _updateFloorItems(items, floor._id)}
               />
            </AccordionDetails>
         </Accordion>
      );
   };

   // const _onlyNumber = (value) => {
   //    //   this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
   // };

   return (
      <Modal
         actions={
            <div className="createUser-actions-modal">
               <Button className="actions-item" onClick={onClose}>
                  Cancelar
               </Button>
               <Button
                  loading={loading}
                  className="actions-item"
                  onClick={tower ? _onEditClub : _onCreateClub}
                  disabled={_isDisableButtonModal()}>
                  Aceptar
               </Button>
            </div>
         }
         isOpen={isOpen}
         title={tower !== null ? 'Editar Club' : 'Agregar Club'}
         description={
            tower !== null
               ? 'Actualice la información necesaria para editar un club'
               : 'Agrege la información necesaria para crear un nuevo club'
         }
         onClose={onClose}>
         <div className="createUser-content-modal">
            <TextField
               name="name"
               className="createUser-inputs"
               label="Nombre"
               variant="outlined"
               value={formModalTower.name}
               error={errors.name !== false}
               helperText={errors.name}
               onChange={(evt) => _changeHandleText(evt, 'name')}
            />
            <TextField
               name="address"
               className="createUser-inputs"
               label="Dirección"
               variant="outlined"
               value={formModalTower.address}
               error={errors.address !== false}
               helperText={errors.address}
               onChange={(evt) => _changeHandleText(evt, 'address')}
            />
            <TextField
               name="time_start"
               className="createUser-inputs"
               label="Hora Inicio"
               variant="outlined"
               type="time"
               value={formModalTower.time_start}
               error={errors.time_start !== false}
               helperText={errors.time_start}
               onChange={(evt) => _changeHandleText(evt, 'time_start')}
            />
            <TextField
               name="time_end"
               className="createUser-inputs"
               label="Hora Fin"
               variant="outlined"
               type="time"
               value={formModalTower.time_end}
               error={errors.time_end !== false}
               helperText={errors.time_end}
               onChange={(evt) => _changeHandleText(evt, 'time_end')}
            />

            <ChangeImage
               previewUrl={formModalTower.image}
               onChangeImage={(file) => setImageUpload(file)}
            />
         </div>
      </Modal>
   );
};

EditModalClub.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
   addTower: (food) => dispatch(addTowerAction(food)),
   updateTower: (food) => dispatch(updateTowerAction(food)),
});

const mapStateToProps = (state) => ({
   amenitys: state.towerReducer.amenitys,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditModalClub);
