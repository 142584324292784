import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getStorage } from './utils/storage';
import RootRouting from './router/root.routing';
import { setLoggedAction, signInAction } from './redux/actions/auth.action';
import "./index.css"
export class App extends Component {
   constructor(props) {
      super(props);
      const { signIn } = props;
      const user = getStorage('user');
      if (user) signIn(user);
   }

   async componentDidMount() {}

   render() {
      return (
         <Fragment>
            <RootRouting />
         </Fragment>
      );
   }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
   setLogged: (token) => dispatch(setLoggedAction(token)),
   signIn: (user) => dispatch(signInAction(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
