import { IconButton } from '@material-ui/core';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import './Modal.scss';

// 'modal-root' is a sibling to 'app-root'
const modalRoot = document.getElementById('modal');

const el = document.createElement('div');
const Modal = ({
   isOpen,
   children,
   onClose,
   title = 'Modal',
   description,
   actions,
   width,
   height
}) => {
   // element to which the modal will be rendered

   useEffect(() => {
      // append to root when the children of Modal are mounted
      modalRoot.appendChild(el);

      // do a cleanup
      return () => {
         if (modalRoot.hasChildNodes(el)) {
            modalRoot.removeChild(el);
         }
      };
   }, [el]);

   return (
      isOpen &&
      createPortal(
         // child element
         <div className="Modal">
            <div
               className="Modal-card"
               style={{
                  width: width || 700,
                  height: height || '80vh',
               }}>
               <div className="Modal-card-header">
                  <div className="header-left">
                     <h5 className="title">{title}</h5>
                     {description && (
                        <span className="description">{description}</span>
                     )}
                  </div>
                  <div className="header-right">
                     <IconButton onClick={onClose}>
                        <i className="material-icons">close</i>
                     </IconButton>
                  </div>
               </div>
               <div className="Modal-card-content" children={children} />
               <div className="Modal-card-footer" children={actions} />
            </div>
         </div>,
         // target container
         el,
      )
   );
};

export default Modal;
