import {
   ADD_LIST_VALET_PARKING,
   ADD_VALET_PARKING,
   DELETE_VALET_PARKING,
   UPDATE_VALET_PARKING,
} from '../types/valetParking.type';

export function addListValetParking(data) {
   return {
      type: ADD_LIST_VALET_PARKING,
      payload: data,
   };
}

export function addValetParking(data) {
   return {
      type: ADD_VALET_PARKING,
      payload: data,
   };
}

export function updateValetParking(data) {
   return {
      type: UPDATE_VALET_PARKING,
      payload: data,
   };
}


export function deleteValetParking(data) {
   return {
      type: DELETE_VALET_PARKING,
      payload: data,
   };
}

