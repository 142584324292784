import { IconButton, Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../components/AgGrid/AgGrid';
import { http } from '../../utils/http';
import './ClubPage.scss';
import { columns } from './columns';
import EditModalClub from './components/EditModalClub/EditModalClub';
import { 
   addListTower as addListTowerAction,
   addListAmenity as addListAmenityAction,
} from '../../redux/actions/tower.action';

class ClubPage extends Component {
   state = {
      towerSelected: null,
      showModal: false,
   };

   async componentDidMount() {
      await this._getTowers();
      await this._getAmenitys();
   }

   async _getTowers() {
      http('/tower', 'GET', {})
         .then((res) => {
            this.props.addListTower(res.data.data);
         })
         .catch((err) => console.error(err));
   }

   async _getAmenitys() {
      http('/amenity', 'GET', {})
         .then((res) => {
            this.props.addListAmenity(res.data.data);
         })
         .catch((err) => console.error(err));
   }

   _showModal() {
      this.setState({ showModal: true });
   }

   _hideModal() {
      this.setState({ showModal: false, towerSelected: null });
   }

   _handleRowClicked(value) {
      const tower = value.data;
      this.setState({ showModal: true, towerSelected: tower });
   }

   render() {
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Club</h1>
               </div>
               <div className="header-actions">
                  <Tooltip title="Refrescar" aria-label="Refrescar">
                     <IconButton onClick={this._getTowers.bind(this)}>
                        <i className="material-icons">refresh</i>
                     </IconButton>
                  </Tooltip>
                  <Tooltip title="Crear" aria-label="Crear">
                     <IconButton onClick={this._showModal.bind(this)}>
                        <i className="material-icons">add</i>
                     </IconButton>
                  </Tooltip>
               </div>
            </div>
            <div className="Page-content">
               <AgGrid
                  rowData={this.props.towers}
                  columnDefs={columns}
                  onRowDoubleClicked={this._handleRowClicked.bind(this)}
               />
            </div>
            <EditModalClub
               isOpen={this.state.showModal}
               onClose={this._hideModal.bind(this)}
               tower={this.state.towerSelected}
            />
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   towers: state.towerReducer.towers,
});

const mapDispatchToProps = (dispatch) => ({
   addListTower: (towers) => dispatch(addListTowerAction(towers)),
   addListAmenity: (amenitys) => dispatch(addListAmenityAction(amenitys)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClubPage);
