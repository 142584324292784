const {
   ADD_LIST_RESERVATION,
   UPDATE_RESERVATION,
   ADD_RESERVATION,
   ADD_LIST_CLUB,
} = require('../types/reservation.type');

const initialState = {
   reservations: [],
   clubs: [],
};

const reservationReducer = (state = initialState, action) => {
   switch (action.type) {
      case ADD_LIST_RESERVATION:
         return {
            ...state,
            reservations: [...action.payload],
         };

      case ADD_LIST_CLUB:
         return {
            ...state,
            clubs: [...action.payload],
         };
      
      case ADD_RESERVATION:
         return {
            ...state,
            reservations: [...state.reservations, action.payload],
         };

      case UPDATE_RESERVATION:
         return {
            ...state,
            reservations: state.reservations.map((res, index) => {
               if (res._id !== action.payload._id) {
                  // This isn't the item we care about - keep it as-is
                  return res;
               }

               // Otherwise, this is the one we want - return an updated value
               return {
                  ...res,
                  ...action.payload,
               };
            }),
         };

      default:
         return state;
   }
};

export { reservationReducer };
