import { format } from 'date-fns';
import React from 'react';

const AgTimeCellRenderer = ({ value }) => {
   const getTime = () => {
      return format(new Date(value), 'HH:mm');
   };

   return <span>{getTime()}</span>;
};

export default AgTimeCellRenderer;
