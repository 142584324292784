import * as excel from 'exceljs';

export default class CreateExcel {
   constructor(nameReport, columns, rows) {
      this._nameReport = nameReport;
      this._columns = columns;
      this._rows = rows;
      this._workbook = this.createWorkbook();
      this._worksheet = this.createWorksheet();
   }
   createWorkbook() {
      const workbook = new excel.Workbook();
      workbook.creator = 'Yama-Reservaciones';
      workbook.created = new Date();
      return workbook;
   }
   createWorksheet() {
      this._workbook.addWorksheet(this._nameReport);
      return this._workbook.getWorksheet(1);
   }
   styleTitle(bgTitle = '7E8382', cTitle = 'FFFFFF') {
      this._columns.map((d, index) => {
         let letter = `${this.columnToLetter(index + 1)}1`;
         this._worksheet.getCell(letter).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: bgTitle },
         };
         this._worksheet.getCell(letter).font = {
            name: 'Arial',
            size: 12,
            color: { argb: cTitle },
            alignment: { vertical: 'center', horizontal: 'center' },
         };
         this._worksheet.getCell(letter).alignment = {
            vertical: 'middle',
            horizontal: 'center',
         };
      });
   }
   insertFilter() {
      const end = this.columnToLetter(this._columns.length + 1) + 1;
      this._worksheet.autoFilter = `A1:${end}`;
   }
   createColumns() {
      let array = [];
      this._columns.map((d) => {
         array.push({
            key: d.field,
            header: d.headerName,
            style: {
               border: { left: { style: 'thin' }, right: { style: 'thin' } },
            },
         });
      });
      this._worksheet.columns = array;
   }
   styleRows(rowPar = 'E8E8E8', rowImpar = 'FFFFFF') {
      for (let a = 2; a <= this._worksheet.rowCount; a++) {
         if (a % 2 == 0) {
            this._worksheet.getRow(a).fill = {
               type: 'pattern',
               pattern: 'solid',
               fgColor: { argb: rowPar },
            };
         } else {
            this._worksheet.getRow(a).fill = {
               type: 'pattern',
               pattern: 'solid',
               fgColor: { argb: rowImpar },
            };
         }
      }
   }
   createRows() {
      this._rows.map((d) => this._worksheet.addRow(d));
      this._worksheet.columns.forEach(function (column, i) {
         // var maxLength = 0;
         // column["eachCell"]({ includeEmpty: true }, function (cell) {
         //     var columnLength = cell.value ? cell.value.toString().length : 10;
         //     if (columnLength > maxLength) {
         //         maxLength = columnLength;
         //     }
         // });
         column.width = 20;
      });
   }
   async createDefaultExcel() {
      this.createColumns();
      this.createRows();
      this.styleTitle();
      this.styleRows();
      this.insertFilter();
      await this.exportExcel();
   }
   createDefaultExcelTG() {
      this.createColumns();
      this.createRows();
      this.styleTitle();
      this.styleRows('00AC99');
      this.insertFilter();
      this.exportExcel();
   }
   async exportExcel() {
      const buf = await this._workbook.xlsx.writeBuffer();
      const a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(
         new Blob([buf], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
         }),
      );
      a.href = url;
      a.download = `${this._nameReport || 'Rerpote'}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      if (a && a.parentElement) {
         a.parentElement.removeChild(a);
      }
   }
   columnToLetter(column) {
      var temp,
         letter = '';
      while (column > 0) {
         temp = (column - 1) % 26;
         letter = String.fromCharCode(temp + 65) + letter;
         column = (column - temp - 1) / 26;
      }
      return letter;
   }
}
