import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
   TextField,
   Accordion,
   AccordionDetails,
   AccordionSummary,
   IconButton,
   Box,
   Tabs,
   Typography,
   Tab,
} from '@material-ui/core';
import Button from '../../../../components/Buttons/Button';
import Modal from '../../../../components/Modal/Modal';
import { http } from '../../../../utils/http';
import { connect } from 'react-redux';

import ListItemAmenity from '../ListItemAmenity/ListItemAmenity';

function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}>
         {value === index && (
            <Box sx={{ p: 3 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

function a11yProps(index) {
   return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
   };
}

const ModalFloors = ({ isOpen, onClose, tower, x }) => {
   const [loading, setLoading] = useState(false);

   const [floors, setFloors] = useState([]);
   const [formNew, setFormNew] = useState({});

   const [value, setValue] = React.useState(0);

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   useEffect(() => {
      if (tower) {
         getFloorsApi();
      }
   }, [tower]);

   const getFloorsApi = () => {
      http(`/floor/tower/${tower._id}`, 'GET', {}).then((response) => {
         if (response && response.data && response.data.data) {
            setFloors(response.data.data);
         }
      });
   };

   const getFloor = (floor) => {
      return (
         <Accordion
            key={floor._id}
            style={{ margin: '20px 0', backgroundColor: '#f6f6f6' }}>
            <AccordionSummary
               aria-controls="panel1a-content"
               id="panel1a-header">
               <TextField value={floor.name} disabled={true} />
               {floor.status ? (
                  <IconButton
                     onClick={() => {
                        http(`/floor/status/${floor._id}`, 'PATCH', {
                           status: false,
                        }).then((response) => {
                           getFloorsApi();
                        });
                     }}
                     style={{
                        color: 'red',
                     }}
                     title="Inactivar">
                     <i className="material-icons">block</i>
                  </IconButton>
               ) : (
                  <IconButton
                     onClick={() => {
                        http(`/floor/status/${floor._id}`, 'PATCH', {
                           status: true,
                        }).then((response) => {
                           getFloorsApi();
                        });
                     }}
                     style={{
                        color: 'green',
                     }}
                     title="Activar">
                     <i className="material-icons">check</i>
                  </IconButton>
               )}
            </AccordionSummary>
            <AccordionDetails>
               <ListItemAmenity
                  floor={floor}
                  onChange={({ news, floor }) => {
                     http(`/floor/${floor}`, 'PATCH', {
                        amenities: news,
                     }).then((response) => {
                        getFloorsApi && getFloorsApi();
                     });
                  }}
               />
            </AccordionDetails>
         </Accordion>
      );
   };

   return (
      <Modal
         actions={
            <div className="createUser-actions-modal">
               <Button className="actions-item" onClick={onClose}>
                  Cancelar
               </Button>
               <Button className="actions-item" onClick={onClose}>
                  Aceptar
               </Button>
            </div>
         }
         isOpen={isOpen}
         title={`Agregar pisos y amenidades a ${tower?.name}`}
         description={
            'Agrege lo necesario para que la torre tenga la información correcta'
         }
         onClose={onClose}
         width={x}>
         <div className="createUser-content-modal">
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
               <TextField
                  placeholder="Nombre del piso"
                  value={formNew?.name}
                  onChange={(event) => {
                     setFormNew({ ...formNew, name: event.target.value });
                  }}
                  className="w-[80%]"
               />

               <IconButton
                  title="Agregar nuevo piso"
                  onClick={() => {
                     if (!formNew.name) return null;
                     const { number, ...rest } = formNew;
                     http(`/floor`, 'POST', {
                        ...rest,
                        number: 0,
                        tower: tower._id,
                        amenities: null,
                     }).then((response) => {
                        setFormNew({});
                        getFloorsApi();
                     });
                  }}>
                  <i className="material-icons">add</i>
               </IconButton>
            </div>

            <Box sx={{ width: '100%' }}>
               <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                     value={value}
                     onChange={handleChange}
                     aria-label="basic tabs example">
                     <Tab label="Pisos Activos" {...a11yProps(0)} />
                     <Tab label="Pisos Inactivos" {...a11yProps(1)} />
                  </Tabs>
               </Box>
               <TabPanel value={value} index={0}>
                  {floors &&
                     floors
                        .filter((item) => item.status)
                        .map((item) => {
                           return getFloor(item);
                        })}
               </TabPanel>
               <TabPanel value={value} index={1}>
                  {floors &&
                     floors
                        .filter((item) => !item.status)
                        .map((item) => {
                           return getFloor(item);
                        })}
               </TabPanel>
            </Box>
         </div>
      </Modal>
   );
};

ModalFloors.propTypes = {};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({
   amenitys: state.towerReducer.amenitys,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalFloors);
