import { IconButton, Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../components/AgGrid/AgGrid';
import { http } from '../../utils/http';
import './AmenityPage.scss';
import { columns } from './columns';
import { addListAmenity as addListAmenityAction } from '../../redux/actions/amenity.action';
import EditModalAmenity from './components/EditModalAmenity/EditModalAmenity';

class AmenityPage extends Component {
   state = {
      amenitySelected: null,
      showModal: false,
   };

   async componentDidMount() {
      await this._getAmenity();
   }

   async _getAmenity() {
      await http('/amenity', 'GET', {})
         .then((res) => {
            this.props.addListAmenity(res.data.data);
         })
         .catch((err) => console.error(err));
   }

   _showModal() {
      this.setState({ showModal: true });
   }

   _hideModal() {
      this.setState({ showModal: false, amenitySelected: null });
   }

   _handleRowClicked(value) {
      const tower = value.data;
      this.setState({ showModal: true, amenitySelected: tower });
   }
   render() {
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Amenidades</h1>
               </div>
               <div className="header-actions">
                  <Tooltip title="Refrescar" aria-label="Refrescar">
                     <IconButton onClick={this._getAmenity.bind(this)}>
                        <i className="material-icons">refresh</i>
                     </IconButton>
                  </Tooltip>
                  <Tooltip title="Crear" aria-label="Crear">
                     <IconButton onClick={this._showModal.bind(this)}>
                        <i className="material-icons">add</i>
                     </IconButton>
                  </Tooltip>
               </div>
            </div>
            <div className="Page-content">
               <AgGrid
                  rowData={this.props.amenities}
                  columnDefs={columns}
                  onRowDoubleClicked={this._handleRowClicked.bind(this)}
               />
            </div>
            <EditModalAmenity
               isOpen={this.state.showModal}
               onClose={this._hideModal.bind(this)}
               amenity={this.state.amenitySelected}
            />
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   amenities: state.amenityReducer.amenities,
});

const mapDispatchToProps = (dispatch) => ({
   addListAmenity: (amenity) => dispatch(addListAmenityAction(amenity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AmenityPage);
