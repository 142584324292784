import { store } from '../redux/store';
import Axios from 'axios';
// import { URL_API } from '../constants/config';

export function http(url, method, body) {
   const state = store.getState();
   const headers =
      state.authReducer.user === null
         ? {}
         : {
              Authorization: `Bearer ${state.authReducer.token}`,
              'Content-Type': 'application/json;charset=UTF-8'
           };

   return new Promise(async (resolve, reject) => {
      try {
         const res = await Axios({
            method,
            url: `${process.env.REACT_APP_URL_API}${url}`,
            data: body,
            headers,
         });

         const { isSuccess } = res.data;
         if (isSuccess) {
            resolve(res);
         }else{
             reject(res)
         }
      } catch (error) {
         // console.log(error.response.data);
         if (error.response && error.response?.data) {
            reject(error.response.data);
         } else {
            reject(error);
         }
      }
   });
}
