// import PropTypes from 'prop-types';
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   FormControl,
   InputLabel,
   MenuItem,
   Select,
   TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../../../components/Buttons/Button';
import Modal from '../../../../components/Modal/Modal';
import {
   addMenu as addMenuAction,
   updateMenu as updateMenuAction,
} from '../../../../redux/actions/menu.action';
import { convertArrayToObjectForError } from '../../../../utils/convertArrayToObject';
import { http } from '../../../../utils/http';
import ListItemMenu from '../ListItemMenu/ListItemMenu';

const initialState = {
   name: '',
   tower: '',
   sections: [],
};
const initialStateError = {
   name: false,
   tower: false,
};

const EditModalMenu = ({
   isOpen,
   onClose,
   menu,
   addMenu,
   updateMenu,
   towers,
}) => {
   const [formModalMenu, setFormModalMenu] = useState(initialState);
   const [loading, setLoading] = useState(false);
   const [errors, setErrors] = useState(initialStateError);

   useEffect(() => {
      if (menu) {
         console.log(menu);
         setFormModalMenu({
            name: menu.name,
            tower: menu.towers[0]._id,
            sections: menu.sections,
         });
      }

      return () => {
         setFormModalMenu(initialState);
         setErrors(initialStateError);
      };
   }, [menu, isOpen]);

   const _onCreateUser = () => {
      // evt.preventDefault();
      setLoading(true);
      http('/menu', 'POST', {
         ...formModalMenu,
         towers: [formModalMenu.tower],
         sections: _getSectionForSubmit(),
      })
         .then((res) => {
            console.log(res);
            addMenu(res.data.data);
            onClose();
         })
         .catch((err) => {
            console.error(err);
            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const _onEditUser = () => {
      // evt.preventDefault();
      setLoading(true);
      http(`/menu/${menu._id}`, 'PUT', {
         ...formModalMenu,
         towers: [formModalMenu.tower],
         sections: _getSectionForSubmit(),
      })
         .then((res) => {
            console.log(res);
            updateMenu(res.data.data);
            onClose();
         })
         .catch((err) => {
            console.error(err);
            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const _isDisableButtonModal = () => {
      const { name, tower, sections } = formModalMenu;

      const emptyNameSection = sections.map((sec) => sec.name === '');

      console.log(emptyNameSection, name, tower);
      const result = name === '' || tower === '' || emptyNameSection.indexOf(true) !== -1
      return result
   };

   const _changeHandleText = (evt, key) => {
      setFormModalMenu({
         ...formModalMenu,
         [key]: evt.target.value,
      });
   };

   const _changeHandleTextNameSection = (evt, sectionId) => {
      setFormModalMenu({
         ...formModalMenu,
         sections: formModalMenu.sections.map((section) => {
            console.log(section._id, sectionId);
            if (section._id === sectionId) {
               return {
                  ...section,
                  name: evt.target.value,
               };
            }

            return section;
         }),
      });
   };

   const _getSectionForSubmit = () => {
      return formModalMenu.sections.map((section) => {
         return {
            name: section.name,
            items: section.items.map((item) => {
               return {
                  food: item.food._id,
                  stock: +item.stock,
               };
            }),
         };
      });
   };

   const _updateSectionItems = (items, sectionId) => {
      setFormModalMenu({
         ...formModalMenu,
         sections: formModalMenu.sections.map((section) => {
            console.log(section._id, sectionId);
            if (section._id === sectionId) {
               return {
                  ...section,
                  items,
               };
            }

            return section;
         }),
      });
   };

   // const _onlyNumber = (value) => {
   //    //   this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
   // };

   const getSection = (section) => {
      return (
         <Accordion
            key={section._id}
            style={{ margin: '20px 0', backgroundColor: '#f6f6f6' }}>
            <AccordionSummary
               // expandIcon={() => <i className="material-icons">expand_more</i>}
               aria-controls="panel1a-content"
               id="panel1a-header">
               <TextField
                  value={section.name}
                  onChange={(val) =>
                     _changeHandleTextNameSection(val, section._id)
                  }
               />
            </AccordionSummary>
            <AccordionDetails>
               <ListItemMenu
                  section={section}
                  onChange={(items) => _updateSectionItems(items, section._id)}
                  isUpdate={menu !== undefined}
               />
            </AccordionDetails>
         </Accordion>
      );
   };

   const getSectionMenu = () => {
      return formModalMenu.sections.map((section) => {
         return getSection(section);
      });
   };

   const _addNewSection = () => {
      setFormModalMenu({
         ...formModalMenu,
         sections: [
            ...formModalMenu.sections,
            { _id: Date.now(), name: '', items: [] },
         ],
      });
   };

   return (
      <Modal
         actions={
            <div className="createUser-actions-modal">
               <Button className="actions-item" onClick={onClose}>
                  Cancelar
               </Button>
               <Button
                  loading={loading}
                  className="actions-item"
                  onClick={menu ? _onEditUser : _onCreateUser}
                  disabled={_isDisableButtonModal()}>
                  Aceptar
               </Button>
            </div>
         }
         isOpen={isOpen}
         title={menu !== null ? 'Editar Menú' : 'Agregar Menú'}
         description={
            menu !== null
               ? 'Actualice la información necesaria para editar un Menú'
               : 'Agrege la información necesaria para crear un nuevo Menú'
         }
         onClose={onClose}>
         <div className="createUser-content-modal">
            <TextField
               name="name"
               className="createUser-inputs"
               label="Nombre"
               variant="outlined"
               value={formModalMenu.name}
               error={errors.name !== false}
               helperText={errors.name}
               onChange={(evt) => _changeHandleText(evt, 'name')}
            />

            <FormControl
               variant="outlined"
               className="createUser-inputs"
               error={errors.tower !== false}>
               <InputLabel>Club</InputLabel>
               <Select
                  name="type"
                  label="Club"
                  value={formModalMenu.tower}
                  onChange={(evt) => _changeHandleText(evt, 'tower')}>
                  <MenuItem value={''}>Selecione un Club</MenuItem>
                  {towers.map((tower) => (
                     <MenuItem key={tower._id} value={tower._id}>
                        {tower.name}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
            <div style={{ padding: '0 20px' }}>
               <span>Secciones</span>

               <div className="SectionMenu">{getSectionMenu()}</div>

               <div>
                  <Button onClick={_addNewSection}>
                     Agregar nueva seccion
                  </Button>
               </div>
            </div>
         </div>
      </Modal>
   );
};

EditModalMenu.propTypes = {};

const mapStateToProps = (state) => ({
   towers: state.towerReducer.towers,
});

const mapDispatchToProps = (dispatch) => ({
   addMenu: (user) => dispatch(addMenuAction(user)),
   updateMenu: (user) => dispatch(updateMenuAction(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditModalMenu);
