import { deleteValetParking as deleteValetParkingAction } from '../../redux/actions/valetParking.action';

export const columns = [
   {
      headerName: 'Nombre',
      field: 'name',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Apellido',
      field: 'lastName',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Correo',
      field: 'email',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Club',
      field: 'tower.name',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Acciones',
      cellRenderer: 'agActionButtonCellRenderer',
      width: 110,
      cellRendererParams: {
         del: {
            url: '/user/valet-parking',
            action: deleteValetParkingAction,
         },
      },
   },
];
