import {
   Button,
   FormControl,
   InputLabel,
   MenuItem,
   Select,
} from '@material-ui/core';
import { getHours, isAfter, isBefore, isSameDay } from 'date-fns';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../../../components/AgGrid/AgGrid';
import UserAutocomplete from '../../../../components/autocomplete/autocomplete';
import DateInputFilter from '../../../../components/DateInputFilter/DateInputFilter';
import { http } from '../../../../utils/http';
import { columnsReportFood } from './columnsReportFood';

const initialState = {
   request: [],
   filterRequest: [],
   foods: [],
   towers: [],
   userFilter: null,
   foodFilter: null,
   towerFilter: null,
   weekDateFilter: null,
   dayDateFilter: null,
   hourDateFilter: null,
};
export class ReportFoodPage extends Component {
   state = initialState;

   componentDidMount() {
      this._getRequestFoods();
      this._getFoods();
      this._getTowers();
   }
   _getRequestFoods() {
      http('/request-food', 'GET', {})
         .then((res) => {
            console.log(res);
            const request = res.data.data;
            let listItemRequest = [];

            for (const req of request) {
               for (const item of req.items) {
                  listItemRequest.push({
                     ...req,
                     ...item,
                     createdAt: req.createdAt,
                  });
               }
            }
            console.log(listItemRequest);
            this.setState({
               request: listItemRequest,
               filterRequest: listItemRequest,
            });
         })
         .catch((err) => console.log(err));
   }

   _getFoods() {
      http('/food', 'GET', {})
         .then((res) => {
            console.log(res);

            this.setState({
               foods: res.data.data,
            });
         })
         .catch((err) => console.log(err));
   }

   _getTowers() {
      http('/tower', 'GET', {})
         .then((res) => {
            console.log(res);

            this.setState({
               towers: res.data.data,
            });
         })
         .catch((err) => console.log(err));
   }

   onFilterUser(user) {
      this.setState(
         {
            userFilter: user === null ? null : user._id,
         },
         () => {
            this._onFilter();
         },
      );
   }

   onFilterFood(evt) {
      const value = evt.target.value;
      this.setState(
         {
            foodFilter: value === null ? null : value,
         },

         () => {
            this._onFilter();
         },
      );

      // this._onFilter();
   }

   onFilterTower(evt) {
      const value = evt.target.value;
      this.setState(
         {
            towerFilter: value === null ? null : value,
         },
         () => {
            this._onFilter();
         },
      );
   }

   // onFilterTime(evt) {
   //    console.log(evt._d);
   //    this.setState(
   //       {
   //          timeFilter: evt._d,
   //       },
   //       () => {
   //          this._onFilter();
   //       },
   //    );
   // }

   _onFilterWeek(evt) {
      // console.log(evt);
      this.setState(
         {
            weekDateFilter: evt,
            dayDateFilter: null,
         },
         () => {
            this._onFilter();
         },
      );
   }
   _onFilterDay(evt) {
      // console.log(evt);
      this.setState(
         {
            dayDateFilter: evt.selectedDate,
            weekDateFilter: null,
         },
         () => {
            this._onFilter();
         },
      );
   }
   _onFilterHour(evt) {
      // console.log(evt);
      this.setState(
         {
            hourDateFilter: evt.selectedDate,
         },
         () => {
            this._onFilter();
         },
      );
   }

   _onResetFilter() {
      this.setState(
         {
            userFilter: null,
            foodFilter: null,
            towerFilter: null,
            weekDateFilter: null,
            dayDateFilter: null,
            hourDateFilter: null,
         },
         () => {
            this._onFilter();
         },
      );
   }

   _onFilter() {
      const {
         foodFilter,
         towerFilter,
         userFilter,
         weekDateFilter,
         dayDateFilter,
         hourDateFilter,
      } = this.state;
      let filterRequest = this.state.request;

      if (userFilter !== null) {
         filterRequest = filterRequest.filter(
            (req) => req.user._id === userFilter,
         );
      }

      if (foodFilter !== null) {
         filterRequest = filterRequest.filter((req) => req._id === foodFilter);
      }

      if (towerFilter !== null) {
         filterRequest = filterRequest.filter(
            (req) => req.reservation.tower._id === towerFilter,
         );
      }

      // if (timeFilter) {
      //    filterRequest = filterRequest.filter(
      //       (req) =>
      //          isAfter(new Date(req.createdAt), timeFilter) ||
      //          isEqual(timeFilter, new Date(req.createdAt)),
      //    );
      // }

      if (weekDateFilter) {
         filterRequest = filterRequest.filter(
            (req) =>
               isAfter(new Date(req.createdAt), weekDateFilter.startWeek) &&
               isBefore(new Date(req.createdAt), weekDateFilter.endWeek),
         );
      } else if (dayDateFilter) {
         filterRequest = filterRequest.filter((req) =>
            isSameDay(new Date(req.createdAt), dayDateFilter),
         );
      }

      if (hourDateFilter) {
         filterRequest = filterRequest.filter((req) => {
            return (
               getHours(new Date(req.createdAt)) === getHours(hourDateFilter)
            );
         });
      }

      console.log(filterRequest);
      this.setState({ filterRequest });
   }

   render() {
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Reporte de Alimentos y bebidas</h1>
               </div>
               {/* <div className="header-actions">
                  <Tooltip title="Refrescar" aria-label="Refrescar">
                     <IconButton onClick={this._getRequestFood.bind(this)}>
                        <i className="material-icons">refresh</i>
                     </IconButton>
                  </Tooltip>
               </div> */}
            </div>
            <div className="Page-content">
               <div className="FilterContainer">
                  <UserAutocomplete onChange={this.onFilterUser.bind(this)} />
                  <DateInputFilter
                     valueWeekPicker={
                        this.state.weekDateFilter !== null
                           ? this.state.weekDateFilter.startWeek
                           : null
                     }
                     valueDayPicker={this.state.dayDateFilter}
                     valueHourPicker={this.state.hourDateFilter}
                     onChangeWeek={this._onFilterWeek.bind(this)}
                     onChangeDay={this._onFilterDay.bind(this)}
                     onChangeHour={this._onFilterHour.bind(this)}
                  />
                  <FormControl variant="outlined" className="inputSelectFilter">
                     <InputLabel>Club</InputLabel>
                     <Select
                        name="type"
                        label="Club"
                        value={this.state.towerFilter}
                        onChange={this.onFilterTower.bind(this)}>
                        <MenuItem value={null}>Selecione un Club</MenuItem>
                        {this.state.towers.map((tower) => (
                           <MenuItem key={tower._id} value={tower._id}>
                              {tower.name}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
                  <FormControl variant="outlined" className="inputSelectFilter">
                     <InputLabel>Platillo</InputLabel>
                     <Select
                        name="type"
                        label="Club"
                        value={this.state.foodFilter}
                        onChange={this.onFilterFood.bind(this)}>
                        <MenuItem value={null}>Selecione un Platillo</MenuItem>
                        {this.state.foods.map((food) => (
                           <MenuItem key={food._id} value={food._id}>
                              {food.name}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
                  <Button onClick={this._onResetFilter.bind(this)}>
                     Reset
                  </Button>
               </div>
               <div className="ReportContent">
                  <AgGrid
                     rowData={this.state.filterRequest}
                     columnDefs={columnsReportFood}
                  />
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReportFoodPage);
