import {
   ADD_LIST_VALET_PARKING,
   ADD_VALET_PARKING,
   DELETE_VALET_PARKING,
   UPDATE_VALET_PARKING,
} from '../types/valetParking.type';

const initialState = {
   valets: [],
};

const valetParkingReducer = (state = initialState, action) => {
   switch (action.type) {
      case ADD_LIST_VALET_PARKING:
         return {
            ...state,
            valets: [...action.payload],
         };

      case ADD_VALET_PARKING:
         return {
            ...state,
            valets: [...state.valets, action.payload],
         };

      case UPDATE_VALET_PARKING:
         return {
            ...state,
            valets: state.valets.map((valet, index) => {
               if (valet._id !== action.payload._id) {
                  // This isn't the item we care about - keep it as-is
                  return valet;
               }

               // Otherwise, this is the one we want - return an updated value
               return {
                  ...valet,
                  ...action.payload,
               };
            }),
         };
      case DELETE_VALET_PARKING:
         return {
            ...state,
            valets: state.valets.filter(
               (valet, index) => valet._id !== action.payload._id,
            ),
         };
      default:
         return state;
   }
};

export { valetParkingReducer };
