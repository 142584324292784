import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import { http } from '../../../../utils/http';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import ChangeImage from '../../../../components/ChangeImage/ChangeImage';
import { convertArrayToObjectForError } from '../../../../utils/convertArrayToObject';
import {
   addNews as addNewsAction,
   updateNews as updateNewsAction,
} from '../../../../redux/actions/news.action';
import Button from '../../../../components/Buttons/Button';
const initialState = {
   title: '',
   body: '',
   link: '',
   image: '',
};

const initialStateError = {
   title: false,
   body: false,
   link: false,
};

const EditModalNews = ({ isOpen, onClose, newItem, addNews, updateNews }) => {
   const [formModalNews, setFormModalNews] = useState(initialState);
   const [errors, setErrors] = useState(initialStateError);
   const [imageUpload, setImageUpload] = useState(null);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      if (newItem) {
         setFormModalNews({
            title: newItem.title,
            body: newItem.body,
            link: newItem.link,
            image: newItem.image,
         });
      }

      return () => {
         setFormModalNews(initialState);
         setImageUpload(null);
         setErrors(initialStateError);
      };
   }, [newItem, isOpen]);

   const onSubmitImage = async (id) => {
      let formData = new FormData();
      formData.append('newsImage', imageUpload);

      return await http(`/news/${id}/upload`, 'POST', formData)
         .then((res) => {
            updateNews(res.data.data);
            console.log(res);
         })
         .catch((err) => console.log(err));
   };

   const _onCreateNew = () => {
      // evt.preventDefault();
      setLoading(true);
      http('/news', 'POST', {
         ...formModalNews,
      })
         .then(async (res) => {
            console.log(res);
            addNews(res.data.data);
            if (imageUpload !== null) {
               await onSubmitImage(res.data.data._id);
            }
            onClose();
         })
         .catch((err) => {
            console.error(err);
            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const _onEditNew = () => {
      // evt.preventDefault();
      setLoading(true);
      http(`/news/${newItem._id}`, 'PUT', {
         ...formModalNews,
      })
         .then(async (res) => {
            console.log(res);
            updateNews(res.data.data);
            if (imageUpload !== null) {
               await onSubmitImage(res.data.data._id);
            }
            onClose();
         })
         .catch((err) => {
            console.error(err);
            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const _changeHandleText = (evt, key) => {
      setFormModalNews({
         ...formModalNews,
         [key]: evt.target.value,
      });
   };

   const _isDisableButtonModal = () => {
      const {
         title,
         body,
         // allergens,
      } = formModalNews;

      return title === '' || body === '';
   };

   return (
      <Modal
         isOpen={isOpen}
         title={newItem !== null ? 'Editar Novedad' : 'Agregar Novedad'}
         description={
            newItem !== null
               ? 'Actualice la información necesaria para editar una novedad'
               : 'Agrege la información necesaria para crear una nueva novedad'
         }
         onClose={onClose}
         actions={
            <div className="createUser-actions-modal">
               <Button className="actions-item" onClick={onClose}>
                  Cancelar
               </Button>
               <Button
                  loading={loading}
                  className="actions-item"
                  onClick={newItem ? _onEditNew : _onCreateNew}
                  disabled={_isDisableButtonModal()}>
                  Aceptar
               </Button>
            </div>
         }>
         <div className="createUser-content-modal">
            <TextField
               name="title"
               className="createUser-inputs"
               label="Titulo"
               variant="outlined"
               value={formModalNews.title}
               error={errors.title !== false}
               helperText={errors.title}
               onChange={(evt) => _changeHandleText(evt, 'title')}
            />
            <TextField
               name="body"
               className="createUser-inputs"
               label="body"
               variant="outlined"
               value={formModalNews.body}
               error={errors.body !== false}
               helperText={errors.body}
               multiline
               rows={6}
               onChange={(evt) => _changeHandleText(evt, 'body')}
            />
            <TextField
               name="link"
               className="createUser-inputs"
               label="Link"
               variant="outlined"
               value={formModalNews.link}
               error={errors.link !== false}
               helperText={errors.link}
               onChange={(evt) => _changeHandleText(evt, 'link')}
            />

            <ChangeImage
               previewUrl={formModalNews.image}
               onChangeImage={(file) => setImageUpload(file)}
            />
         </div>
      </Modal>
   );
};

EditModalNews.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
   addNews: (food) => dispatch(addNewsAction(food)),
   updateNews: (food) => dispatch(updateNewsAction(food)),
});

export default connect(null, mapDispatchToProps)(EditModalNews);
