import { Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

const AgButtonViewUserChildCellRenderer = ({ title, data }) => {
   const history = useHistory();

   const goTo = () => {
      const urlNavigate = `/user/${data._id}/child`;
      history.push({
         pathname: urlNavigate,
         state: { user: data },
      });
   };
   return (
      <span>
         <Button onClick={goTo}>{title}</Button>
      </span>
   );
};

export default AgButtonViewUserChildCellRenderer;
