import { format } from 'date-fns';
import React from 'react';

const AgDateCellRenderer = ({ value }) => {
   const getTime = () => {
      return format(new Date(value), 'dd/MM/yyyy');
   };

   return <span>{getTime()}</span>;
};

export default AgDateCellRenderer;
