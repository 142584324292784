import { IconButton, Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../components/AgGrid/AgGrid';
import { http } from '../../utils/http';
// import { http } from '../../utils/http';
import { columns } from './columns';
import AddModalCortesy from './components/AddModalCortesy/AddModalCortesy';
import './Courtesy.scss';
import { addListCourtesy as addListCourtesyAction } from '../../redux/actions/courtesy.action';

class CourtesyPage extends Component {
   state = {
      // courtesies: [],
      courtesySelected: null,
      showModal: false,
   };

   componentDidMount() {
      this._getCourtesys();
   }
   _getCourtesys() {
      http('/courtesy', 'GET', {})
         .then((res) => {
            console.log(res);
            this.props.addListCourtesy(res.data.data);
         })
         .catch((err) => {
            console.error(err);
         });
   }

   _showModal() {
      this.setState({ showModal: true });
   }

   _hideModal() {
      this.setState({ showModal: false, courtesySelected: null });
   }

   render() {
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Cortesías</h1>
               </div>
               <div className="header-actions">
                  <Tooltip title="Refrescar" aria-label="Refrescar">
                     <IconButton onClick={this._getCourtesys.bind(this)}>
                        <i className="material-icons">refresh</i>
                     </IconButton>
                  </Tooltip>
                  <Tooltip title="Crear" aria-label="Crear">
                     <IconButton onClick={this._showModal.bind(this)}>
                        <i className="material-icons">add</i>
                     </IconButton>
                  </Tooltip>
               </div>
            </div>
            <div className="Page-content">
               <AgGrid rowData={this.props.courtesies} columnDefs={columns} />
            </div>
            <AddModalCortesy
               isOpen={this.state.showModal}
               onClose={this._hideModal.bind(this)}
            />
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   courtesies: state.courtesyReducer.courtesies,
});

const mapDispatchToProps = (dispatch) => ({
   addListCourtesy: (courtesies) => dispatch(addListCourtesyAction(courtesies)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CourtesyPage);
