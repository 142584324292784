import { format } from 'date-fns';
import React from 'react';

const AgFullDateCellRenderer = ({ value }) => {
   const getTime = () => {
      return format(new Date(value), 'dd/MM/yyyy HH:mm');
   };

   if (value === null) {
      return <span>Sin datos</span>;
   }

   return <span>{getTime()}</span>;
};

export default AgFullDateCellRenderer;
