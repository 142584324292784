import { IconButton, Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../components/AgGrid/AgGrid';
import { http } from '../../utils/http';
import './AmenityPage.scss';
import { columns } from './columns';
import { addListAmenity as addListAmenityAction } from '../../redux/actions/amenity.action';
import EditModalAmenity from './components/EditModalAmenity/EditModalAmenity';

class FloorsPage extends Component {
   state = {
      amenitySelected: null,
      showModal: false,
   };

   async componentDidMount() {
      await this._getAmenity();
   }

   async _getAmenity() {
      const { addFloors } = this.props;
      await http('/floor', 'GET', {})
         .then((res) => {
            console.log(res.data.data);
            addFloors(res.data.data);
         })
         .catch((err) => console.error(err));
   }

   _showModal() {
      this.setState({ showModal: true });
   }

   _hideModal() {
      this.setState({ showModal: false, amenitySelected: null });
   }

   _handleRowClicked(value) {
      const tower = value.data;
      this.setState({ showModal: true, amenitySelected: tower });
   }
   render() {
      const { floors } = this.props;
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Floors</h1>
               </div>
               <div className="header-actions">
                  <Tooltip title="Refrescar" aria-label="Refrescar">
                     <IconButton onClick={this._getAmenity.bind(this)}>
                        <i className="material-icons">refresh</i>
                     </IconButton>
                  </Tooltip>
               </div>
            </div>
            <div className="Page-content">
               <AgGrid
                  rowData={floors}
                  columnDefs={columns}
                  onRowDoubleClicked={this._handleRowClicked.bind(this)}
               />
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   floors: state.floorsReducer.floors,
});

const mapDispatchToProps = (dispatch) => ({
   addFloors: (floors) =>
      dispatch({ type: 'ADD_LIST_FLOORS', payload: floors }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FloorsPage);
