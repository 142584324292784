import clsx from 'clsx';

const Tab = ({ label, isActive, onClick }) => (
   <li className="mr-2">
      <a
         href="#"
         className={clsx(
            'inline-block px-4 py-3 rounded-lg',
            isActive
               ? 'text-white bg-blue-600'
               : 'hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white',
         )}
         onClick={onClick}>
         {label}
      </a>
   </li>
);

export default Tab;
