import React, { Component, PureComponent } from 'react';
import { createStyles } from '@material-ui/styles';
import { IconButton, withStyles } from '@material-ui/core';
import {
   endOfWeek,
   format,
   isSameDay,
   isValid,
   isWithinInterval,
   startOfDay,
   startOfHour,
   startOfMonth,
   startOfWeek,
} from 'date-fns';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import classNames from 'classnames';

class InputWeek extends PureComponent {
   //    state = {
   //       selectedDate: new Date(),
   //    };

   handleWeekChange = (date) => {
      this.props.onChange({
         selectedDate: date._d,
         beforeDate: date._i,
         startWeek: startOfWeek(date._d),
         endWeek: endOfWeek(date._d),
      });
      //   this.setState({ selectedDate: startOfWeek(date._d) });
   };

   formatWeekSelectLabel = (date, invalidLabel) => {
      if (date !== null) {
         let dateClone = date._d;
         return dateClone && isValid(dateClone)
            ? `${format(startOfWeek(dateClone), 'dd/MM/yyyy')} - ${format(
                 endOfWeek(dateClone),
                 'dd/MM/yyyy',
              )}`
            : invalidLabel;
      } else {
         return '';
      }
   };

   renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
      const { classes } = this.props;
      let dateClone = date._d;
      let selectedDateClone = selectedDate._d;

      const start = startOfWeek(selectedDateClone);
      const end = endOfWeek(selectedDateClone);

      const dayIsBetween = isWithinInterval(dateClone, { start, end });
      const isFirstDay = isSameDay(dateClone, start);
      const isLastDay = isSameDay(dateClone, end);

      const wrapperClassName = classNames({
         [classes.highlight]: dayIsBetween,
         [classes.firstHighlight]: isFirstDay,
         [classes.endHighlight]: isLastDay,
      });

      const dayClassName = classNames(classes.day, {
         [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
         [classes.highlightNonCurrentMonthDay]:
            !dayInCurrentMonth && dayIsBetween,
      });

      return (
         <div className={wrapperClassName}>
            <IconButton className={dayClassName}>
               <span> {format(dateClone, 'd')} </span>
            </IconButton>
         </div>
      );
   };

   render() {
      const { value, onChange, ...props } = this.props;

      return (
         <DatePicker
            {...props}
            value={value}
            label="Semana"
            inputVariant="outlined"
            disableToolbar
            variant="inline"
            onChange={this.handleWeekChange}
            renderDay={this.renderWrappedWeekDay}
            labelFunc={this.formatWeekSelectLabel}
         />
      );
   }
}

const styles = createStyles((theme) => ({
   dayWrapper: {
      position: 'relative',
   },
   day: {
      width: 36,
      height: 36,
      fontSize: theme.typography.caption.fontSize,
      margin: '0 2px',
      color: 'inherit',
   },
   customDayHighlight: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '2px',
      right: '2px',
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '50%',
   },
   nonCurrentMonthDay: {
      color: theme.palette.text.disabled,
   },
   highlightNonCurrentMonthDay: {
      color: '#676767',
   },
   highlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
   },
   firstHighlight: {
      extend: 'highlight',
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
   },
   endHighlight: {
      extend: 'highlight',
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
   },
}));

const Week = withStyles(styles)(InputWeek);

export default class DateInputFilter extends PureComponent {
   render() {
      const {
         showMonthPicker = false,
         valueMonthPicker = new Date(),
         valueWeekPicker = new Date(),
         valueHourPicker = new Date(),
         valueDayPicker = new Date(),
         onChangeMonth = () => {},
         onChangeWeek = () => {},
         onChangeDay = () => {},
         onChangeHour = () => {},
      } = this.props;

      return (
         <div>
            {showMonthPicker && (
               <DatePicker
                  label="Mes"
                  autoOk={true}
                  inputVariant="outlined"
                  disableToolbar={false}
                  views={['year', 'month']}
                  variant="inline"
                  value={valueMonthPicker}
                  onChange={(date) =>
                     onChangeMonth({
                        selectedDate: startOfMonth(date._d),
                        beforeDate: date._i,
                     })
                  }
                  style={{ marginRight: 10 }}
                  clearable={true}
               />
            )}
            <Week
               value={valueWeekPicker}
               onChange={onChangeWeek}
               style={{ marginRight: 10 }}
               clearable={true}
            />
            <DatePicker
               label="Día"
               inputVariant="outlined"
               disableToolbar
               variant="inline"
               value={valueDayPicker}
               onChange={(date) =>
                  onChangeDay({
                     selectedDate: startOfDay(date._d),
                     beforeDate: date._i,
                  })
               }
               style={{ marginRight: 10 }}
               clearable={true}
            />
            <TimePicker
               label="Hora"
               ampm={true}
               inputVariant="outlined"
               variant="inline"
               openTo="hours"
               views={['hours']}
               clearable={true}
               value={valueHourPicker}
               onChange={(date) =>
                  onChangeHour({
                     selectedDate: startOfHour(date._d),
                     beforeDate: date._i,
                  })
               }
            />
         </div>
      );
   }
}
