import {
   ADD_LIST_CHEF,
   ADD_CHEF,
   UPDATE_CHEF,
   DELETE_CHEF,
} from '../types/chef.type';

export function addListChef(data) {
   return {
      type: ADD_LIST_CHEF,
      payload: data,
   };
}

export function addtChef(data) {
   return {
      type: ADD_CHEF,
      payload: data,
   };
}

export function updateChef(data) {
   return {
      type: UPDATE_CHEF,
      payload: data,
   };
}
export function deleteChef(data) {
   return {
      type: DELETE_CHEF,
      payload: data,
   };
}
