import { ADD_LIST_MENU, ADD_MENU, UPDATE_MENU } from '../types/menu.type';
const initialState = {
   menus: [],
};

const menuReducer = (state = initialState, action) => {
   switch (action.type) {
      case ADD_LIST_MENU:
         return {
            ...state,
            menus: [...action.payload],
         };

      case ADD_MENU:
         return {
            ...state,
            menus: [...state.menus, action.payload],
         };

      case UPDATE_MENU:
         return {
            ...state,
            menus: state.menus.map((menu, index) => {
               if (menu._id !== action.payload._id) {
                  // This isn't the item we care about - keep it as-is
                  return menu;
               }

               // Otherwise, this is the one we want - return an updated value
               return {
                  ...menu,
                  ...action.payload,
               };
            }),
         };
      default:
         return state;
   }
};

export { menuReducer };
