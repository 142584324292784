import {
   ADD_LIST_AMENITY,
   ADD_AMENITY,
   UPDATE_AMENITY,
   DELETE_AMENITY,
} from '../types/amenity.type';

export function addListAmenity(data) {
   return {
      type: ADD_LIST_AMENITY,
      payload: data,
   };
}

export function addAmenity(data) {
   return {
      type: ADD_AMENITY,
      payload: data,
   };
}

export function updateAmenity(data) {
   return {
      type: UPDATE_AMENITY,
      payload: data,
   };
}

export function deleteAmenity(data) {
   return {
      type: DELETE_AMENITY,
      payload: data,
   };
}
