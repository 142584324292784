import { combineReducers } from 'redux';

import { authReducer } from './auth.reducer';
import { chefReducer } from './chef.reducer';
import { towerReducer } from './tower.reducer';
import { userReducer } from './user.reducer';
import { reservationReducer } from './reservation.reducer';
import { valetParkingReducer } from './valetParking.reducer';
import { menuReducer } from './menu.reducer';
import { foodReducer } from './food.reducer';
import { amenityReducer } from './amenity.reducer';
import { newsReducer } from './news.reducer';
import { courtesyReducer } from './courtesy.reducer';
import { floorsReducer } from './floors.reducer';

const rootReducer = combineReducers({
   authReducer,
   chefReducer,
   towerReducer,
   userReducer,
   reservationReducer,
   valetParkingReducer,
   menuReducer,
   foodReducer,
   amenityReducer,
   newsReducer,
   courtesyReducer,
   floorsReducer,
});

export { rootReducer };
