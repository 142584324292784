import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   IconButton,
   Slide,
   TextField,
   Tooltip,
} from '@material-ui/core';

import React, { forwardRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { http } from '../../../utils/http';

const Transition = forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const AgDeleteFood = ({ data, del, active, sendEmail }) => {
   const dispatch = useDispatch();
   const [openDeleteModal, setOpenDeleteModal] = useState(false);
   const [openActiveModal, setOpenActiveModal] = useState(false);
   const [openSendEmailModal, setOpenSendEmailModal] = useState(false);
   const [emailFormSendEmail, setEmailFormSendEmail] = useState('');

   const handleClickOpenDeleteModal = () => {
      setOpenDeleteModal(true);
   };

   const handleCloseDeleteModal = (isDelete) => {
      if (isDelete) {
         deleteItem();
      }
      setOpenDeleteModal(false);
   };

   const handleClickOpenActiveModal = () => {
      setOpenActiveModal(true);
   };

   const handleClickOpenSendEmailModal = () => {
      setOpenSendEmailModal(true);
   };
   const handleCloseSendEmailModal = (isSendEmail) => {
      if (isSendEmail) {
         sendEmailRecoverPassword();
      }
      setOpenSendEmailModal(false);
   };

   const handleCloseActiveModal = (isActive) => {
      if (isActive) {
         activeItem();
      }
      setOpenActiveModal(false);
   };

   const deleteItem = () => {
      http(`${del.url}/${data._id}`, 'DELETE', {})
         .then((res) => {
            if (del.redux) {
               dispatch(del.action(res.data.data));
            } else {
               del.action && del.action();
            }
         })
         .catch((err) => console.log(err));
   };

   const activeItem = () => {
      const isActive = data.active;
      http(
         `${active.url}/${data._id}/${isActive ? 'inactive' : 'active'}`,
         'PATCH',
         {},
      )
         .then((res) => {
            console.log(res);
            dispatch(active.action(res.data.data));
         })
         .catch((err) => console.log(err));
   };

   const sendEmailRecoverPassword = () => {
      http(`/auth/recovery-password-alt`, 'POST', {
         email: data.email,
         emailReceive: emailFormSendEmail,
      })
         .then((res) => {
            console.log(res);
         })
         .catch((err) => console.log(err));
   };

   return (
      <span>
         {del && (
            <Tooltip title="Borrar" aria-label="Borrar">
               <IconButton onClick={handleClickOpenDeleteModal}>
                  <i className="material-icons">delete</i>
               </IconButton>
            </Tooltip>
         )}

         {/* Dialog */}
         <Dialog
            open={openDeleteModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseDeleteModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle id="alert-dialog-slide-title">
               ¿Está seguro?
            </DialogTitle>
            <DialogContent>
               <DialogContentText id="alert-dialog-slide-description">
                  Este registro será eliminado permanentemente. Esta acción no
                  puede ser desecha.
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button
                  onClick={() => handleCloseDeleteModal(true)}
                  color="primary">
                  Eliminar
               </Button>
               <Button
                  onClick={() => handleCloseDeleteModal(false)}
                  color="primary">
                  Cancelar
               </Button>
            </DialogActions>
         </Dialog>
      </span>
   );
};

export default AgDeleteFood;
