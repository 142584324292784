import { ADD_LIST_CHEF, ADD_CHEF, UPDATE_CHEF, DELETE_CHEF } from '../types/chef.type';

const initialState = {
   chefs: [],
};

const chefReducer = (state = initialState, action) => {
   switch (action.type) {
      case ADD_LIST_CHEF:
         return {
            ...state,
            chefs: [...action.payload],
         };

      case ADD_CHEF:
         return {
            ...state,
            chefs: [...state.chefs, action.payload],
         };

      case UPDATE_CHEF:
         return {
            ...state,
            chefs: state.chefs.map((chef, index) => {
               if (chef._id !== action.payload._id) {
                  // This isn't the item we care about - keep it as-is
                  return chef;
               }

               // Otherwise, this is the one we want - return an updated value
               return {
                  ...chef,
                  ...action.payload,
               };
            }),
         };
         case DELETE_CHEF:
         return {
            ...state,
            chefs: state.chefs.filter(
               (chef, index) => chef._id !== action.payload._id,
            ),
         };
      default:
         return state;
   }
};

export { chefReducer };
