import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
   TextField,
   Select,
   MenuItem,
   FormControl,
   InputLabel,
} from '@material-ui/core';
import Button from '../../../../components/Buttons/Button';
import Modal from '../../../../components/Modal/Modal';
import { http } from '../../../../utils/http';
import { connect } from 'react-redux';
import {
   addtFood as addFoodAction,
   updateFood as updateFoodAction,
} from '../../../../redux/actions/food.action';
import ChangeImage from '../../../../components/ChangeImage/ChangeImage';
import { convertArrayToObjectForError } from '../../../../utils/convertArrayToObject';

const initialState = {
   name: '',
   price: '',
   code: '',
   descriptionShort: '',
   descriptionLong: '',
   weight: '',
   type: '',
   allergens: '',
   image: '',
};
const initialStateError = {
   name: false,
   price: false,
   code: false,
   descriptionShort: false,
   descriptionLong: false,
   weight: false,
   type: false,
   allergens: false,
   // image: '',
};

const EditModalFood = ({ isOpen, onClose, food, addFood, updateFood }) => {
   const [formModalFood, setFormModalFood] = useState(initialState);
   const [loading, setLoading] = useState(false);
   const [imageUpload, setImageUpload] = useState(null);
   const [errors, setErrors] = useState(initialStateError);

   useEffect(() => {
      if (food) {
         setFormModalFood({
            name: food.name,
            price: food.price.$numberDecimal,
            code: food.code,
            descriptionShort: food.descriptionShort,
            descriptionLong: food.descriptionLong,
            weight: food.weight.$numberDecimal,
            allergens: food.allergens.join(', '),
            type: food.type,
            image: food.image,
         });
      }

      return () => {
         setFormModalFood(initialState);
         setErrors(initialStateError);
      };
   }, [food, isOpen]);

   const _onCreateUser = () => {
      // evt.preventDefault();
      setLoading(true);
      http('/food', 'POST', {
         ...formModalFood,
         price: Number(formModalFood.price),
         weight: Number(formModalFood.weight),
         allergens: formModalFood.allergens
            .split(',')
            .filter(Boolean)
            .map((r) => r.trim()),
      })
         .then(async (res) => {
            addFood(res.data.data);
            if (imageUpload !== null) {
               await onSubmitImage(res.data.data._id);
            }
            onClose();
         })
         .catch((err) => {
            console.error(err);

            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const _onEditUser = () => {
      // evt.preventDefault();
      setLoading(true);
      http(`/food/${food._id}`, 'PUT', {
         ...formModalFood,
         price: Number(formModalFood.price),
         weight: Number(formModalFood.weight),
         allergens: formModalFood.allergens
            .split(',')
            .filter(Boolean)
            .map((r) => r.trim()),
      })
         .then(async (res) => {
            console.log(res);
            updateFood(res.data.data);
            if (imageUpload !== null) {
               await onSubmitImage(res.data.data._id);
            }
            onClose();
         })
         .catch((err) => {
            console.error(err);
            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const onSubmitImage = async (id) => {
      let formData = new FormData();
      formData.append('foodImage', imageUpload);

      return await http(`/food/${id}/upload`, 'POST', formData)
         .then((res) => {
            console.log(res);
            updateFood(res.data.data);
         })
         .catch((err) => console.log(err));
   };

   const _isDisableButtonModal = () => {
      const {
         name,
         price,
         code,
         descriptionShort,
         descriptionLong,
         weight,
         type,
         // allergens,
      } = formModalFood;

      return (
         name === '' ||
         price === '' ||
         code === '' ||
         descriptionShort === '' ||
         descriptionLong === '' ||
         weight === '' ||
         type === ''
      );
   };

   const _changeHandleText = (evt, key) => {
      setFormModalFood({
         ...formModalFood,
         [key]: evt.target.value,
      });
   };

   // const _onlyNumber = (value) => {
   //    //   this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
   // };

   return (
      <Modal
         actions={
            <div className="createUser-actions-modal">
               <Button className="actions-item" onClick={onClose}>
                  Cancelar
               </Button>
               <Button
                  loading={loading}
                  className="actions-item"
                  onClick={food ? _onEditUser : _onCreateUser}
                  disabled={_isDisableButtonModal()}>
                  Aceptar
               </Button>
            </div>
         }
         isOpen={isOpen}
         title={food !== null ? 'Editar Alimento' : 'Agregar Alimento'}
         description={
            food !== null
               ? 'Actualice la información necesaria para editar un alimento'
               : 'Agrege la información necesaria para crear un nuevo alimento'
         }
         onClose={onClose}>
         <div className="createUser-content-modal">
            <TextField
               name="name"
               className="createUser-inputs"
               label="Nombre"
               variant="outlined"
               value={formModalFood.name}
               error={errors.name !== false}
               helperText={errors.name}
               onChange={(evt) => _changeHandleText(evt, 'name')}
            />
            <TextField
               name="price"
               className="createUser-inputs"
               label="Precio"
               variant="outlined"
               value={formModalFood.price}
               error={errors.price !== false}
               helperText={errors.price}
               onChange={(evt) => _changeHandleText(evt, 'price')}
            />
            <TextField
               name="code"
               className="createUser-inputs"
               label="Codigo"
               variant="outlined"
               value={formModalFood.code}
               error={errors.code !== false}
               helperText={errors.code}
               onChange={(evt) => _changeHandleText(evt, 'code')}
            />
            <TextField
               name="descriptionShort"
               className="createUser-inputs"
               label="Descripción corta"
               variant="outlined"
               value={formModalFood.descriptionShort}
               error={errors.descriptionShort !== false}
               helperText={errors.descriptionShort}
               onChange={(evt) => _changeHandleText(evt, 'descriptionShort')}
            />
            <TextField
               name="descriptionLong"
               className="createUser-inputs"
               label="Descripción larga"
               variant="outlined"
               value={formModalFood.descriptionLong}
               error={errors.descriptionLong !== false}
               helperText={errors.descriptionLong}
               onChange={(evt) => _changeHandleText(evt, 'descriptionLong')}
            />
            <TextField
               name="weight"
               className="createUser-inputs"
               label="Peso"
               variant="outlined"
               value={formModalFood.weight}
               error={errors.weight !== false}
               helperText={errors.weight}
               onChange={(evt) => _changeHandleText(evt, 'weight')}
            />
            <TextField
               name="allergens"
               className="createUser-inputs"
               label="Alérngenos"
               variant="outlined"
               value={formModalFood.allergens}
               error={errors.allergens !== false}
               helperText={errors.allergens}
               onChange={(evt) => _changeHandleText(evt, 'allergens')}
            />
            {/* <span>Separe cada alérngeno con coma (,)</span> */}
            <FormControl
               variant="outlined"
               className="createUser-inputs"
               error={errors.type !== false}>
               <InputLabel>Tipo de cocina</InputLabel>
               <Select
                  name="type"
                  label="Tipo de cocina"
                  value={formModalFood.type}
                  onChange={(evt) => _changeHandleText(evt, 'type')}>
                  <MenuItem value={''}>Selecione un tipo de cocina</MenuItem>
                  <MenuItem value={'BAR'}>Bar</MenuItem>
                  <MenuItem value={'CHEF'}>Cocina</MenuItem>
               </Select>
            </FormControl>

            {/* <TextField
               name="image"
               className="createUser-inputs"
               label="Imagen"
               variant="outlined"
               type="file"
            /> */}
            <ChangeImage
               previewUrl={formModalFood.image}
               onChangeImage={(file) => setImageUpload(file)}
            />
         </div>
      </Modal>
   );
};

EditModalFood.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
   addFood: (food) => dispatch(addFoodAction(food)),
   updateFood: (food) => dispatch(updateFoodAction(food)),
});

export default connect(null, mapDispatchToProps)(EditModalFood);
