import {
   FormControl,
   IconButton,
   InputLabel,
   MenuItem,
   Select,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './ListItemAmenity.scss';
// import PropTypes from 'prop-types'
 
function ListItemAmenity({ floor, amenitys, onChange }) {
   const [items, setItems] = useState([]);
   const [floorFilter, setFloorFilter] = useState([]);
 
   useEffect(() => {
     setItems(floor.amenities);
     _getAmenitySelect(floor);
   //   onChange(floor.floors);
   }, []);
 
   const _handleChangeSelectAmenity = (evt) => {
      const value = evt.target.value;
      if (value === '') return;
      const itemsUpdate = [
         ...items,
         value,
      ];
      setItems(itemsUpdate);
      onChange(itemsUpdate);
   };
  
   const _deleteItem = (id) => {
      const itemsUpdate = items.filter((item) => item._id !== id);
      setItems(itemsUpdate);
      onChange(itemsUpdate);
   };
 
   const _getAmenitySelect = (items) => {
      const floorsUpdate = amenitys.filter((amenity) => {
         for (const item of items.amenities) {
            if (item._id === amenity._id) {
               return false;
            }
            return true;
         }
         return true;
      });
      setFloorFilter(floorsUpdate);
   };
 
   const _getItem = (item) => {
      return (
         <li key={item._id} className="ListItemMenu-item">
            <div className="ListItemMenu-itemContainer">
               <div className="ListItemMenu-item_left">
                  <div className="ListItemMenu-item_info">
                    <span className="itemTitle">{item.name}</span>
                  </div>
               </div>
               <div className="ListItemMenu-item_right">
                  <IconButton
                     edge="end"
                     aria-label="delete"
                     onClick={() => _deleteItem(item._id)}>
                     <i className="material-icons">delete</i>
                  </IconButton>
               </div>
            </div>
         </li>
      );
   };
 
   return (
      <div className="ListItemMenu">
         <ul className="ListItemMenu-list">
            {items.map((item) => _getItem(item))}
         </ul>
         <FormControl variant="outlined" className="ListItemMenu-select">
            <InputLabel>Amenidad</InputLabel>
               <Select
                  name="amenity"
                  label="Amenity"
                  onChange={_handleChangeSelectAmenity}>
                  <MenuItem value={''}>Selecione una Amenidad</MenuItem>
                  {floorFilter.map((amenitys) => (
                     <MenuItem key={amenitys._id} value={amenitys}>
                        {amenitys.name}
                     </MenuItem>
                  ))}
               </Select>
         </FormControl>
      </div>
   );
}
 
ListItemAmenity.propTypes = {};

const mapStateToProps = (state) => ({
   amenitys: state.towerReducer.amenitys,
});

export default connect(mapStateToProps)(ListItemAmenity);
