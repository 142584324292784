export const columns = [
   {
      headerName: 'Usuario',
      field: 'user.fullName',
      filter: true,
      sortable: true,
   },
   {
      headerName: 'Hora de inicio',
      field: 'schedule.start',
      filter: true,
      sortable: true,
      cellRenderer: 'agFullDateCellRenderer',
   },
   {
      headerName: 'Hora de fin',
      field: 'schedule.end',
      filter: true,
      sortable: true,
      cellRenderer: 'agFullDateCellRenderer',
   },
   {
      headerName: 'Amenidad',
      field: 'amenity.name',
      filter: true,
      sortable: true,
   },
   { headerName: 'Club', field: 'tower.name', filter: true, sortable: true },
   {
      headerName: 'Créditos usados',
      field: 'creditsUsage',
      filter: true,
      sortable: true,
   },
   {
      headerName: 'Estatus',
      field: 'state',
      filter: true,
      sortable: true,
      valueGetter: ({ data }) => {
         if (data.state === 'ON_RESERVATION') {
            return 'RESERVED';
         }

         return data.state;
      },
   },
];
