export const columns = [
   {
      headerName: 'Usuario',
      field: 'user.fullName',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Placa',
      field: 'carPlate',
      filter: true,
      resizable: true,
      sortable: true,
   },
   {
      headerName: 'Club',
      field: 'tower.name',
      filter: true,
      resizable: true,
      sortable: true,
   },
   {
      headerName: 'Estado',
      field: 'status',
      filter: true,
      resizable: true,
      sortable: true,
   },

   {
      headerName: 'Solicitado',
      field: 'createdAt',
      filter: true,
      sortable: true,
      resizable: true,

      cellRenderer: 'agDateCellRenderer',
   },
   {
      headerName: 'Actualización',
      field: 'updatedAt',
      filter: true,
      resizable: true,
      sortable: true,
      cellRenderer: 'agDateCellRenderer',
   },
];
