export const columns = [
   {
      headerName: 'Usuario',
      field: 'user.fullName',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Club',
      field: 'reservation.tower.name',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Amenidad',
      field: 'reservation.amenity.name',
      filter: true,
      sortable: true,
      resizable: true,
   },
//    {
//       headerName: 'Usuario',
//       field: 'user.fullName',
//       filter: true,
//       sortable: true,
//       resizable: true,
//    },
//    {
//       headerName: 'Usuario',
//       field: 'user.fullName',
//       filter: true,
//       sortable: true,
//       resizable: true,
//    },
];
