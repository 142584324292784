import React, { useState } from 'react';
import Drawer from '../../Drawer/Drawer';
import './DefaultLayout.scss';
import classnames from 'classnames';
function DefaultLayout({ children }) {
   const [isOpenDrawer, setIsOpenDrawer] = useState(false);

   const onHoverDrawer = () => {
      setIsOpenDrawer(true);
   };

   const onExitDrawer = () => {
      setIsOpenDrawer(false);
   };
   return (
      <div className="DefaultLayout">
         <div
            className={classnames('DefaultLayout-container', {
               'open-drawer': isOpenDrawer,
            })}>
            <div
               className="DefaultLayout-drawer"
               onMouseOver={onHoverDrawer}
               onMouseLeave={onExitDrawer}>
               <Drawer isOpenDrawer={isOpenDrawer} />
            </div>
            <div className="DefaultLayout-main">{children}</div>
         </div>
      </div>
   );
}

export default DefaultLayout;
