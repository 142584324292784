export const columnsReservation = [
   {
      headerName: 'Usuario',
      field: 'user.fullName',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Correo',
      field: 'user.email',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Amenidad',
      field: 'amenity.name',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Usuario',
      field: 'tower.name',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Reservaciones',
      field: 'cantReservation',
      filter: true,
      sortable: true,
      resizable: true,
   },
];
