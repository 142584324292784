import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import Button from '../../../../components/Buttons/Button';
import Modal from '../../../../components/Modal/Modal';
import { http } from '../../../../utils/http';
import { connect } from 'react-redux';

const EditModalClub = ({ isOpen, onClose, data, typeEdit }) => {
   const [formModalTower, setFormModalTower] = useState({});
   const [loading, setLoading] = useState(false);
   const [errors, setErrors] = useState({});

   useEffect(() => {
      setFormModalTower(data);
      return () => {};
   }, [data]);

   const _onCreateClub = () => {
      setLoading(true);

      const dataToSend = {
         ...data,
         price: typeEdit === 'PRICE' ? +formModalTower.price : data.price,
         startPrice:
            typeEdit === 'DATES' ? formModalTower.startPrice : data.startPrice,
         endPrice: typeEdit === 'DATES' ? formModalTower.endPrice : data.endPrice,
         newPrice: typeEdit === 'DATES' ? +formModalTower.newPrice : data.newPrice,
      };

      http(`/price/${dataToSend._id}`, 'PUT', dataToSend)
         .then(async (res) => {
            onClose();
         })
         .catch((err) => console.error(err))
         .finally(() => {
            setLoading(false);
         });
   };

   const _changeHandleText = (evt, key) => {
      setFormModalTower({
         ...formModalTower,
         [key]: evt.target.value,
      });
   };

   return (
      <Modal
         actions={
            <div className="createUser-actions-modal">
               <Button className="actions-item" onClick={onClose}>
                  Cancelar
               </Button>
               <Button
                  loading={loading}
                  className="actions-item"
                  onClick={_onCreateClub}>
                  Aceptar
               </Button>
            </div>
         }
         isOpen={isOpen}
         title={'Precio de credito'}
         description={'Actualize el precio'}
         onClose={onClose}>
         <div className="createUser-content-modal">
            {typeEdit === 'PRICE' && (
               <TextField
                  name="name"
                  className="createUser-inputs"
                  label="Precio"
                  variant="outlined"
                  value={formModalTower.price}
                  onChange={(evt) => _changeHandleText(evt, 'price')}
               />
            )}

            {typeEdit === 'DATES' && (
               <TextField
                  name="name"
                  className="createUser-inputs"
                  label="Fecha Inicio"
                  variant="outlined"
                  value={formModalTower.startPrice}
                  onChange={(evt) => _changeHandleText(evt, 'startPrice')}
                  type="date"
               />
            )}
            {typeEdit === 'DATES' && (
               <TextField
                  name="name"
                  className="createUser-inputs"
                  label="Fecha fin"
                  variant="outlined"
                  value={formModalTower.endPrice}
                  onChange={(evt) => _changeHandleText(evt, 'endPrice')}
                  type="date"
                  InputProps={{ inputProps: { min: formModalTower.startPrice } }}

               />
            )}
            {typeEdit === 'DATES' && (
               <TextField
                  name="name"
                  className="createUser-inputs"
                  label="Precio"
                  variant="outlined"
                  value={formModalTower.newPrice}
                  onChange={(evt) => _changeHandleText(evt, 'newPrice')}
               />
            )}
         </div>
      </Modal>
   );
};

EditModalClub.propTypes = {};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EditModalClub);
