import { IS_LOGGED, SIGN_IN, SIGN_OUT } from '../types/auth.type';

const initialState = {
   user: null,
   isLogged: false,
   token: null,
};

const authReducer = (state = initialState, action) => {
   switch (action.type) {
      case SIGN_IN:
         return {
            ...state,
            user: action.payload,
            isLogged: true,
            token: action.payload.token,
         };
      case SIGN_OUT:
         return {
            ...state,
            user: null,
            isLogged: false,
            token: null,
         };
      case IS_LOGGED:
         return {
            ...state,
            token: action.payload,
            isLogged: action.payload !== null,
         };

      default:
         return state;
   }
};

export { authReducer };
