import { format, parseISO } from 'date-fns';
import {
   deleteAmenity,
   updateAmenity,
} from '../../redux/actions/amenity.action';
import ms from 'ms';
export const columns = [
   {
      headerName: 'Nombre',
      field: 'name',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Descripción',
      field: 'description',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: '# de personas',
      field: 'cantPerson',
      filter: true,
      sortable: true,
      resizable: true,
      width: 200
   },
   {
      headerName: 'Reservación única',
      valueGetter: ({ data }) => {
         return data.onlyUniqueReservation ? 'SI' : 'NO';
      },
      sortable: true,
      resizable: true,
   },

   {
      headerName: 'Costo',
      field: 'cost',
      filter: true,
      sortable: true,
      resizable: true,
      width: 80
   },
   {
      headerName: 'Minimo de reserva',
      valueGetter: ({ data }) => {
         return ms(data.timeMinReservation);
      },
      filter: true,
      sortable: true,
      resizable: true,
      width: 180
   },

   {
      headerName: 'Hora inicio y fin',
      field: 'schedule.start',
      filter: true,
      sortable: true,
      resizable: true,
      valueGetter: ({ data }) => {
         return (
            format(parseISO(data.schedule.start), 'HH:mm') +
            '-' +
            format(parseISO(data.schedule.end), 'HH:mm')
         );
      },
   },

   {
      headerName: 'Acciones',
      cellRenderer: 'agActionButtonCellRenderer',
      width: 180,
      cellRendererParams: {
         del: {
            url: '/amenity',
            action: deleteAmenity,
         },
         active: {
            url: '/amenity',
            action: updateAmenity,
         },
      },
   },
];
