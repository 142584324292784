import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AgGridReact } from 'ag-grid-react';
import AgTimeCellRenderer from './cellCustoms/AgTimeCellRenderer';
import AgDateCellRenderer from './cellCustoms/AgDateCellRenderer';
import AgFullDateCellRenderer from './cellCustoms/AgFullDateCellRenderer';
import AgButtonHistoryUserCellRenderer from './cellCustoms/AgButtonHistoryUserCellRenderer';
import AgActionButtonCellRenderer from './cellCustoms/AgActionsButtonCellRenderer';
import AgButtonViewUserChildCellRenderer from './cellCustoms/AgButtonViewUserChildCellRenderer';
import AgDeleteFood from './cellCustoms/AgDeleteFood';

const AgGrid = ({ rowData, columnDefs, onRowClicked, onRowDoubleClicked }) => {
   const [gridApi, setGridApi] = useState(null);
   const [gridColumnApi, setGridColumnApi] = useState(null);

   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };

   if (!rowData) return null;

   return (
      <div
         className="ag-theme-material"
         style={{ height: '100%', width: '100%' }}>
         <AgGridReact
            onRowClicked={onRowClicked}
            onRowDoubleClicked={onRowDoubleClicked}
            onGridReady={onGridReady}
            rowData={rowData}
            columnDefs={columnDefs}
            frameworkComponents={{
               agTimeCellRenderer: AgTimeCellRenderer,
               agDateCellRenderer: AgDateCellRenderer,
               agFullDateCellRenderer: AgFullDateCellRenderer,
               agButtonHistoryUserCellRenderer: AgButtonHistoryUserCellRenderer,
               agButtonViewUserChildCellRenderer:
                  AgButtonViewUserChildCellRenderer,
               agActionButtonCellRenderer: AgActionButtonCellRenderer,
               agDeleteFood: AgDeleteFood,
            }}
         />
      </div>
   );
};

AgGrid.propTypes = {
   rowData: PropTypes.array,
   columnDefs: PropTypes.array,
};

export default AgGrid;
