import {
   Card,
   CardContent,
   CardHeader,
   FormControl,
   Grid,
   IconButton,
   InputLabel,
   MenuItem,
   Select,
   Tooltip,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import {
   addDays,
   eachHourOfInterval,
   format,
   isAfter,
   isBefore,
   isEqual,
   isFriday,
   isMonday,
   isSaturday,
   isSunday,
   isThursday,
   isWednesday,
   startOfDay,
} from 'date-fns';
import { isTuesday } from 'date-fns/esm';
import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { http } from '../../../../utils/http';

class ReportSalesFoodPage extends Component {
   state = {
      requestFood: [],
      // requestFoodByFormated: [],
      filterRequestFoodByFormated: [],
      filterCompanyBuyByFormated: [],
      filterDayTotalByFormate: [],
      filterHoursTotalByFormate: [],
      isAscRequestFood: true,
      isAscCompany: true,
      towers: [],
      towerFilter: null,
      dateFilter: null,
      cantDaysFilter: 1,
      // isOpenDrawer: false,
   };

   componentDidMount() {
      this._getTowers();
      this._getRequestFood();
   }

   _getOrderDaysData(date, item, cant) {
      return {
         lunes: isMonday(date)
            ? cant + (item?.days?.lunes || 0)
            : item?.days?.lunes || 0,
         martes: isTuesday(date)
            ? cant + (item?.days?.martes || 0)
            : item?.days?.martes || 0,
         miercoles: isWednesday(date)
            ? cant + (item?.days?.miercoles || 0)
            : item?.days?.miercoles || 0,
         jueves: isThursday(date)
            ? cant + (item?.days?.jueves || 0)
            : item?.days?.jueves || 0,
         viernes: isFriday(date)
            ? cant + (item?.days?.viernes || 0)
            : item?.days?.viernes || 0,
         sabado: isSaturday(date)
            ? cant + (item?.days?.sabado || 0)
            : item?.days?.sabado || 0,
         domingo: isSunday(date)
            ? cant + (item?.days?.domingo || 0)
            : item?.days?.domingo || 0,
      };
   }

   async _getRequestFood(users = this.state.users) {
      await http('/request-food/', 'GET', {})
         .then((res) => {
            console.log(res);

            const requestFood = res.data.data;
            let food = {};
            let companyBuyFood = {};
            let dayRequestFood = {
               days: {
                  lunes: 0,
                  martes: 0,
                  miercoles: 0,
                  jueves: 0,
                  viernes: 0,
                  sabado: 0,
                  domingo: 0,
               },
            };
            let hoursRequestFood = {};

            //Crear datos para la cantidad de horas
            eachHourOfInterval({
               start: new Date(2020, 1, 1, 0),
               end: new Date(2020, 1, 1, 23),
            }).forEach((date) => {
               const hour = format(date, 'HH');
               hoursRequestFood['i' + hour] = 0;
            });

            for (const request of requestFood) {
               const dateRequest = new Date(request.createdAt);

               if (request.courtesy && !request.pay) {
                  continue;
               }

               const companyId = request.user.user
                  ? request.user.user.nameCompany
                  : request.user.nameCompany;
               // console.log(request, companyId);

               //Filtro de empresas con mayor gasto
               let amountString = request.pay.amount.toString();
               // console.log(amountString);
               const amountPay = +amountString.slice(
                  0,
                  amountString.length - 2,
               );

               const formatDate = format(dateRequest, 'dd/MM');
               companyBuyFood[companyId] = {
                  company: companyId,
                  amountTotal:
                     amountPay + (companyBuyFood[companyId]?.amountTotal || 0),
                  days: {
                     ...(companyBuyFood[companyId]?.days || {}),
                     [formatDate]:
                        amountPay +
                        (companyBuyFood[companyId]?.days[formatDate] || 0),
                  },
               };

               // Filtro por horarios
               const hour = format(dateRequest, 'HH');
               hoursRequestFood['i' + hour] += 1;

               dayRequestFood = {
                  days: this._getOrderDaysData(dateRequest, dayRequestFood, 1),
               };
               console.log(dayRequestFood);

               // Venta de platillo individual por dia
               request.items.forEach((item) => {
                  food[item._id] = {
                     food: item,
                     cantSold: item.cant + (food[item._id]?.cantSold || 0),
                     days: {
                        ...(food[item._id]?.days || {}),
                        [formatDate]:
                           item.cant + (food[item._id]?.days[formatDate] || 0),
                     },
                  };
               });
            }

            // console.log(food);

            const foodSort = Object.values(food).sort(
               (a, b) => b.cantSold - a.cantSold,
            );
            const companyBuyFoodSort = Object.values(companyBuyFood).sort(
               (a, b) => b.amountTotal - a.amountTotal,
            );

            this.setState({
               requestFood,
               // requestFoodByFormated: foodSort,
               filterRequestFoodByFormated: foodSort,
               isAscRequestFood: true,
               isAscCompany: true,
               filterCompanyBuyByFormated: companyBuyFoodSort,
               filterDayTotalByFormate: Object.values(dayRequestFood.days),
               filterHoursTotalByFormate: Object.values(hoursRequestFood),
            });
         })
         .catch((err) => console.log(err));
   }

   _getTowers() {
      http('/tower', 'GET', {})
         .then((res) => {
            console.log(res);

            this.setState({
               towers: res.data.data,
            });
         })
         .catch((err) => console.log(err));
   }

   _getDatasetChatLine(label = '', data = []) {
      return [
         {
            label: label,
            // .length > 15 ? label.slice(0, 15) + '...' : label,
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(0, 62, 85,0.4)',
            borderColor: 'rgb(0, 62, 85)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgb(0, 62, 85)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgb(0, 62, 85)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data,
         },
      ];
   }

   _getDataForChartHourDay(label = '', data = [], isHours = false) {
      return {
         labels: !isHours
            ? ['L', 'M', 'M', 'J', 'V', 'S', 'D']
            : eachHourOfInterval({
                 start: new Date(2020, 1, 1, 0),
                 end: new Date(2020, 1, 1, 23),
              }).map((date) => format(date, 'hh a')),
         datasets: this._getDatasetChatLine(label, data),
      };
   }
   _getDataForChartDate(label = '', data = {}) {
      // if(Object.values(data).length > 7){

      // }

      return {
         labels: Object.keys(data),
         datasets: this._getDatasetChatLine(label, Object.values(data)),
      };
   }

   onFilterTower(evt) {
      const value = evt.target.value;
      this.setState(
         {
            towerFilter: value === null ? null : value,
         },
         () => {
            this._onFilter();
         },
      );
   }
   _onFilterDate(evt) {
      console.log(evt);
      const value = evt._d;
      this.setState(
         {
            dateFilter: value === null ? null : value,
         },
         () => {
            this._onFilter();
         },
      );
   }
   _onFilterDayRange(evt) {
      const value = evt.target.value;
      this.setState(
         {
            cantDaysFilter: value === null ? null : value,
         },
         () => {
            this._onFilter();
         },
      );
   }

   _onFilter() {
      const { towerFilter, dateFilter, cantDaysFilter } = this.state;
      // let filterRequestFoodByFormated = this.state.requestFoodByFormated;

      const requestFood = this.state.requestFood;
      let food = {};
      let companyBuyFood = {};
      let dayRequestFood = {
         days: {
            lunes: 0,
            martes: 0,
            miercoles: 0,
            jueves: 0,
            viernes: 0,
            sabado: 0,
            domingo: 0,
         },
      };
      let hoursRequestFood = {};

      //Crear datos para la cantidad de horas
      eachHourOfInterval({
         start: new Date(2020, 1, 1, 0),
         end: new Date(2020, 1, 1, 23),
      }).forEach((date) => {
         const hour = format(date, 'HH');
         hoursRequestFood['i' + hour] = 0;
      });

      for (const request of requestFood) {
         // console.log(request);
         const dateRequest = new Date(request.createdAt);

         if (dateFilter !== null) {
            const startDayFilter = startOfDay(dateFilter);
            const endDateFilter = addDays(startDayFilter, cantDaysFilter);

            console.log(dateFilter, endDateFilter);
            if (
               isEqual(startDayFilter, new Date(request.createdAt)) ||
               isAfter(startDayFilter, new Date(request.createdAt)) ||
               isBefore(endDateFilter, new Date(request.createdAt))
            ) {
               continue;
            }
         }

         if (towerFilter !== null) {
            if (towerFilter !== request.reservation.tower._id) {
               continue;
            }
         }

         if (request.courtesy && !request.pay) {
            continue;
         }

         const companyId = request.user.user
            ? request.user.user.nameCompany
            : request.user.nameCompany;
         // console.log(request, companyId);
         //Filtro de empresas con mayor gasto
         let amountString = request.pay.amount.toString();
         // console.log(amountString);
         const amountPay = +amountString.slice(0, amountString.length - 2);
         const formatDate = format(dateRequest, 'dd/MM');
         companyBuyFood[companyId] = {
            company: companyId,
            amountTotal:
               amountPay + (companyBuyFood[companyId]?.amountTotal || 0),
            days: {
               ...(companyBuyFood[companyId]?.days || {}),
               [formatDate]:
                  amountPay +
                  (companyBuyFood[companyId]?.days[formatDate] || 0),
            },
         };

         // Filtro por horarios
         const hour = format(dateRequest, 'HH');
         hoursRequestFood['i' + hour] += 1;

         dayRequestFood = {
            days: this._getOrderDaysData(dateRequest, dayRequestFood, 1),
         };
         console.log(dayRequestFood);

         // Venta de platillo individual por dia
         request.items.forEach((item) => {
            food[item._id] = {
               food: item,
               cantSold: item.cant + (food[item._id]?.cantSold || 0),
               days: {
                  ...(food[item._id]?.days || {}),
                  [formatDate]:
                     item.cant + (food[item._id]?.days[formatDate] || 0),
               },
            };
         });
      }

      // console.log(food);

      const foodSort = Object.values(food).sort(
         (a, b) => b.cantSold - a.cantSold,
      );
      const companyBuyFoodSort = Object.values(companyBuyFood).sort(
         (a, b) => b.amountTotal - a.amountTotal,
      );

      // console.log(dayRequestFood);
      let hoursRequestFoodFormated = [];
      for (const hour in hoursRequestFood) {
         if (hoursRequestFood.hasOwnProperty(hour)) {
            const cantRequestFood = hoursRequestFood[hour];
            hoursRequestFoodFormated.push(cantRequestFood);
         }
      }

      console.log(foodSort);
      this.setState({
         // requestFood,
         // requestFoodByFormated: foodSort,
         isAscRequestFood: true,
         isAscCompany: true,
         filterRequestFoodByFormated: foodSort,
         filterCompanyBuyByFormated: companyBuyFoodSort,
         filterDayTotalByFormate: Object.values(dayRequestFood.days),
         filterHoursTotalByFormate: hoursRequestFoodFormated,
      });
   }

   _changeOrderSortFood() {
      const isAsc = this.state.isAscRequestFood;

      this.setState({
         isAscRequestFood: !isAsc,
         filterRequestFoodByFormated: this.state.filterRequestFoodByFormated.reverse(),
      });
   }
   _changeOrderSortCompany() {
      const isAsc = this.state.isAscCompany;

      this.setState({
         isAscCompany: !isAsc,
         filterCompanyBuyByFormated: this.state.filterCompanyBuyByFormated.reverse(),
      });
   }

   render() {
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Reporte de ventas de Alimentos</h1>
               </div>
               <div className="header-actions">
                  {/* <Tooltip title="Gráficas" aria-label="Gráficas">
                     <IconButton onClick={this._toggleDrawer.bind(this)}>
                        <i className="material-icons">analytics</i>
                     </IconButton>
                  </Tooltip> */}
               </div>
            </div>
            <div className="Page-content">
               <div className="FilterContainer">
                  <FormControl variant="outlined" className="inputSelectFilter">
                     <InputLabel>Club</InputLabel>
                     <Select
                        name="type"
                        label="Club"
                        value={this.state.towerFilter}
                        onChange={this.onFilterTower.bind(this)}>
                        {/* <MenuItem value={undefined}>Selecione un Club</MenuItem> */}
                        {this.state.towers.map((tower) => (
                           <MenuItem key={tower._id} value={tower._id}>
                              {tower.name}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
                  <DatePicker
                     autoOk
                     value={this.state.dateFilter}
                     inputVariant="outlined"
                     variant="inline"
                     placeholder="Fecha"
                     className="inputSelectFilter"
                     onChange={this._onFilterDate.bind(this)}
                  />
                  <FormControl variant="outlined" className="inputSelectFilter">
                     <InputLabel>Rango de tiempo</InputLabel>
                     <Select
                        name="type"
                        label="Rango de tiempo"
                        value={this.state.cantDaysFilter}
                        onChange={this._onFilterDayRange.bind(this)}>
                        <MenuItem value={1}>1 día</MenuItem>
                        <MenuItem value={7}>7 días</MenuItem>
                        <MenuItem value={15}>15 días</MenuItem>
                        <MenuItem value={28}>28 días</MenuItem>
                     </Select>
                  </FormControl>
               </div>
               <div className="ReportContent">
                  <Grid container spacing={3}>
                     <Grid item xs={12} md={6} sm={12}>
                        <Card className="card-chart">
                           <CardHeader
                              title="Platillos más vendidos"
                              action={
                                 <Tooltip
                                    title={
                                       this.state.isAscRequestFood
                                          ? 'Descendente'
                                          : 'Ascendente'
                                    }
                                    aria-label={
                                       this.state.isAscRequestFood
                                          ? 'Descendente'
                                          : 'Ascendente'
                                    }>
                                    <IconButton
                                       aria-label="sort"
                                       onClick={this._changeOrderSortFood.bind(
                                          this,
                                       )}>
                                       <i className="material-icons">
                                          sort_by_alpha
                                       </i>
                                    </IconButton>
                                 </Tooltip>
                              }
                           />
                           <CardContent
                              style={{
                                 height: 'calc(100% - 72px)',
                                 overflow: 'auto',
                              }}>
                              <ul
                                 style={{
                                    listStyle: 'none',
                                 }}>
                                 {this.state.filterRequestFoodByFormated
                                    .map((value) => {
                                       // const labelId = `checkbox-list-secondary-label-${value}`;
                                       return (
                                          <li
                                             key={value.food._id}
                                             style={{ height: 200 }}>
                                             <div
                                                style={{
                                                   display: 'flex',
                                                   height: '100%',
                                                   justifyContent:
                                                      'space-between',
                                                   alignItems: 'center',
                                                }}>
                                                <div>
                                                   <span>
                                                      {value.food.name}
                                                   </span>
                                                </div>
                                                <div
                                                   style={{
                                                      width: '50%',
                                                      height: '100%',
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      alignItems: 'center',
                                                   }}>
                                                   <Line
                                                      data={this._getDataForChartDate(
                                                         value.food.name,
                                                         value.days,
                                                      )}
                                                   />
                                                </div>
                                             </div>
                                          </li>
                                       );
                                    })
                                    .slice(0, 9)}
                              </ul>
                           </CardContent>
                        </Card>
                     </Grid>
                     <Grid item xs={12} md={6} sm={12}>
                        <Card className="card-chart">
                           <CardHeader
                              title="Empresas con más gastos en platillos"
                              action={
                                 <Tooltip
                                    title={
                                       this.state.isAscCompany
                                          ? 'Descendente'
                                          : 'Ascendente'
                                    }
                                    aria-label={
                                       this.state.isAscCompany
                                          ? 'Descendente'
                                          : 'Ascendente'
                                    }>
                                    <IconButton
                                       aria-label="sort"
                                       onClick={this._changeOrderSortCompany.bind(
                                          this,
                                       )}>
                                       <i className="material-icons">
                                          sort_by_alpha
                                       </i>
                                    </IconButton>
                                 </Tooltip>
                              }
                           />
                           <CardContent
                              style={{
                                 height: 'calc(100% - 72px)',
                                 overflow: 'auto',
                                 paddingBottom: 10,
                              }}>
                              <ul
                                 style={{
                                    listStyle: 'none',
                                 }}>
                                 {this.state.filterCompanyBuyByFormated
                                    .map((value) => {
                                       // const labelId = `checkbox-list-secondary-label-${value}`;
                                       return (
                                          <li
                                             key={value.company}
                                             style={{ height: 200 }}>
                                             <div
                                                style={{
                                                   display: 'flex',
                                                   height: '100%',
                                                   justifyContent:
                                                      'space-between',
                                                   alignItems: 'center',
                                                }}>
                                                <div>
                                                   <span>{value.company}</span>
                                                </div>
                                                <div
                                                   style={{
                                                      width: '50%',
                                                      height: '100%',
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      alignItems: 'center',
                                                   }}>
                                                   <Line
                                                      data={this._getDataForChartDate(
                                                         value.company,
                                                         value.days,
                                                      )}
                                                   />
                                                </div>
                                             </div>
                                          </li>
                                       );
                                    })
                                    .slice(0, 9)}
                              </ul>
                           </CardContent>
                        </Card>
                     </Grid>
                     <Grid item xs={12}>
                        <Card className="card-chart">
                           <CardHeader title="Ventas por Día y Hora" />
                           <CardContent
                              style={{
                                 height: 'calc(100% - 120px)',
                                 overflow: 'auto',
                              }}>
                              <div
                                 style={{
                                    display: 'flex',
                                    height: '100%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                 }}>
                                 <div
                                    style={{
                                       width: '90%',
                                       height: '100%',
                                    }}>
                                    <Line
                                       data={this._getDataForChartHourDay(
                                          'Cantidad de solicitudes de alimentos por día',
                                          this.state.filterDayTotalByFormate,
                                       )}
                                    />
                                 </div>
                                 <div
                                    style={{
                                       width: '90%',
                                       height: '100%',
                                    }}>
                                    <Line
                                       data={this._getDataForChartHourDay(
                                          'Cantidad de solicitudes de alimentos por horarios',
                                          this.state.filterHoursTotalByFormate,
                                          true,
                                       )}
                                    />
                                 </div>
                              </div>
                           </CardContent>
                        </Card>
                     </Grid>
                  </Grid>
                  {/*<Drawer
                     classes={{ paper: 'expand-drawer' }}
                     style={{ width: 500 }}
                     anchor={'right'}
                     open={this.state.isOpenDrawer}
                     onClose={this._toggleDrawer.bind(this)}>
                     <div className="content-chart-drawer">
                        <span className="title-chart-drawer">
                           Cantidad de alimentos vendidos por día de la semana
                        </span>
                        <Polar data={this._getDataForChartDayWeek()} />
                     </div>
                     <div className="content-chart-drawer">
                        <span className="title-chart-drawer">
                           Cantidad de alimentos vendidos por hora del día
                        </span>
                        <Line data={this._getDataForChartHourDay()} />
                     </div>
                     <div className="content-chart-drawer">
                        <span className="title-chart-drawer">
                           Empresas con mayor gasto
                        </span>
                        <Pie data={this._getDataForChartCompany()} />
                     </div>
                  </Drawer> */}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
   mapStateToProps,
   mapDispatchToProps,
)(ReportSalesFoodPage);
