import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { http } from '../../utils/http';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import {
   addListUser as addListUserAction,
   addListUserChild as addListUserChildAction,
} from '../../redux/actions/user.action';
const UserAutoComplete = ({
   addListUserChild,
   addListUser,
   users,
   usersChildren,
   onChange,
   showEmail = false
}) => {
   // const [user, setData] = useState([]);
   const [userSelected, setUserSelected] = useState(null);

   useEffect(() => {
      _getUser();
      _getUserChildren();
   }, []);

   const _getUser = () => {
      http('/user', 'GET', {})
         .then((u) => {
            addListUser(u.data.data);
         })
         .catch((err) => {
            console.error(err);
         });
   };

   const _getUserChildren = () => {
      http('/user/child', 'GET', {})
         .then((u) => {
            addListUserChild(u.data.data);
         })
         .catch((err) => {
            console.error(err);
         });
   };

   return (
      <div>
         <Autocomplete
            clearOnBlur={true}
            getOptionLabel={(user) => `${user.fullName} (${user.email})`}
            options={[...users, ...usersChildren].sort((a, b) =>
               a.fullName.localeCompare(b.fullName),
            )}
            renderInput={(params) => (
               <TextField {...params} label="Usuario" variant="outlined" />
            )}
            onChange={(event, newUserSelected) => {
               onChange(newUserSelected);
            }}
         />
      </div>
   );
};

const mapStateToProps = (state) => ({
   users: state.userReducer.users,
   usersChildren: state.userReducer.usersChildren,
});

const mapDispatchToProps = (dispatch) => ({
   addListUserChild: (children) => dispatch(addListUserChildAction(children)),
   addListUser: (children) => dispatch(addListUserAction(children)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAutoComplete);
