import {
   ADD_LIST_COURTESY,
   ADD_COURTESY,
   UPDATE_COURTESY,
} from '../types/courtesy.type';

const initialState = {
   courtesies: [],
};

const courtesyReducer = (state = initialState, action) => {
   switch (action.type) {
      case ADD_LIST_COURTESY:
         return {
            ...state,
            courtesies: [...action.payload],
         };

      case ADD_COURTESY:
         return {
            ...state,
            courtesies: [...state.courtesies, action.payload],
         };

      case UPDATE_COURTESY:
         return {
            ...state,
            courtesies: state.courtesies.map((courtesy, index) => {
               if (courtesy._id !== action.payload._id) {
                  // This isn't the item we care about - keep it as-is
                  return courtesy;
               }

               // Otherwise, this is the one we want - return an updated value
               return {
                  ...courtesy,
                  ...action.payload,
               };
            }),
         };
      default:
         return state;
   }
};

export { courtesyReducer };
