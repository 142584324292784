import { format, parseISO } from 'date-fns';
import { updateUserChild } from '../../../../redux/actions/user.action';

export const columns = [
   { headerName: 'ID', field: '_id', resizable: true },
   {
      headerName: 'Nombre',
      field: 'name',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Apellido',
      field: 'lastName',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Correo',
      field: 'email',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Estado',
      field: 'credits.active',
      resizable: true,
      valueGetter: ({ data }) => {
         return data.active ? 'ACTIVO' : 'INACTIVO';
      },
   },
   {
      headerName: 'Fecha Alta',
      field: 'createdAt',
      filter: true,
      sortable: true,
      resizable: true,
      valueGetter: ({ data }) => {
         return format(parseISO(data.createdAt), 'yy-MM-dd hh:mm');
      },
   },
   {
      headerName: 'Historial',
      filter: true,
      sortable: true,
      width: 180,
      cellRendererParams: {
         title: 'Ver Historial',
      },
      cellRenderer: 'agButtonHistoryUserCellRenderer',
   },
   {
      headerName: 'Acciones',
      cellRenderer: 'agActionButtonCellRenderer',
      width: 150,
      cellRendererParams: {
         active: {
            url: '/user/child',
            action: updateUserChild,
         },
         del: {
            url: '/user/child',
            action: updateUserChild,
         },
         sendEmail: true,
      },
   },
];
