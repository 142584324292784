import clsx from 'clsx';

const TowerList = ({ towers, towerSelected, onSelectTower }) => (
   <div className="flex flex-row gap-x-4 my-4">
      {towers.map(({ id, _id, name }) => (
         <span
            key={id}
            className={clsx('text-[14px] rounded-[10px] p-2 cursor-pointer', {
               'bg-white': _id === towerSelected,
               'bg-white-400': _id !== towerSelected,
            })}
            onClick={() => onSelectTower(_id)}>
            {name}
         </span>
      ))}
   </div>
);

export default TowerList