import React from 'react';
import Prototype from 'prop-types';
import { Button as MButton, CircularProgress } from '@material-ui/core';
const Button = ({
   style,
   className,
   type,
   loading = false,
   children,
   disabled,
   ...props
}) => {
   return (
      <MButton
         {...props}
         type={type}
         disabled={disabled || loading}
         className={className}
         style={style}
         variant="contained">
         {loading && (
            <CircularProgress
               style={{ marginRight: 10 }}
               size={16}
               thickness={5}
            />
         )}
         {children}
      </MButton>
   );
};

Button.prototype = {
   style: Prototype.object,
   className: Prototype.string || Prototype.any,
   type: Prototype.string,
   loading: Prototype.bool,
   disabled: Prototype.bool,
};

export default Button;
