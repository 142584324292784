import {
   Button,
   FormControl,
   InputLabel,
   MenuItem,
   Select,
} from '@material-ui/core';
import { getHours, isAfter, isBefore, isSameDay } from 'date-fns';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../../../components/AgGrid/AgGrid';
import UserAutocomplete from '../../../../components/autocomplete/autocomplete';
import DateInputFilter from '../../../../components/DateInputFilter/DateInputFilter';
import { http } from '../../../../utils/http';
import { columnsReportReservation } from './columnsReportReservation';

const initialState = {
   reservations: [],
   filterReservations: [],
   amenity: [],
   towers: [],
   userFilter: null,
   amenityFilter: null,
   towerFilter: null,
   weekDateFilter: null,
   dayDateFilter: null,
   hourDateFilter: null,
};
export class ReportReservationPage extends Component {
   state = initialState;
   componentDidMount() {
      this._getReservations();
      this._getAmenities();
      this._getTowers();
   }
   _getReservations() {
      http('/reservation', 'GET', {})
         .then((res) => {
            console.log(res);
            this.setState({
               reservations: res.data.data,
               filterReservations: res.data.data,
            });
         })
         .catch((err) => console.log(err));
   }

   _getAmenities() {
      http('/amenity', 'GET', {})
         .then((res) => {
            console.log(res);

            this.setState({
               amenity: res.data.data,
            });
         })
         .catch((err) => console.log(err));
   }

   _getTowers() {
      http('/tower', 'GET', {})
         .then((res) => {
            console.log(res);

            this.setState({
               towers: res.data.data,
            });
         })
         .catch((err) => console.log(err));
   }

   onFilterUser(user) {
      this.setState(
         {
            userFilter: user === null ? null : user._id,
         },
         () => {
            this._onFilter();
         },
      );
   }

   onFilterAmenity(evt) {
      const value = evt.target.value;
      this.setState(
         {
            amenityFilter: value === null ? null : value,
         },

         () => {
            this._onFilter();
         },
      );

      // this._onFilter();
   }

   onFilterTower(evt) {
      const value = evt.target.value;
      this.setState(
         {
            towerFilter: value === null ? null : value,
         },
         () => {
            this._onFilter();
         },
      );
   }

   _onFilterWeek(evt) {
      // console.log(evt);
      this.setState(
         {
            weekDateFilter: evt,
            dayDateFilter: null,
         },
         () => {
            this._onFilter();
         },
      );
   }
   _onFilterDay(evt) {
      // console.log(evt);
      this.setState(
         {
            dayDateFilter: evt.selectedDate,
            weekDateFilter: null,
         },
         () => {
            this._onFilter();
         },
      );
   }
   _onFilterHour(evt) {
      // console.log(evt);
      this.setState(
         {
            hourDateFilter: evt.selectedDate,
         },
         () => {
            this._onFilter();
         },
      );
   }

   _onResetFilter() {
      this.setState(
         {
            userFilter: null,
            amenityFilter: null,
            towerFilter: null,
            weekDateFilter: null,
            dayDateFilter: null,
            hourDateFilter: null,
         },
         () => {
            this._onFilter();
         },
      );
   }
   _onFilter() {
      const {
         amenityFilter,
         towerFilter,
         userFilter,
         weekDateFilter,
         dayDateFilter,
         hourDateFilter,
      } = this.state;
      let filterReservations = this.state.reservations;

      if (userFilter !== null) {
         filterReservations = filterReservations.filter(
            (req) => req.user._id === userFilter,
         );
      }

      if (amenityFilter !== null) {
         filterReservations = filterReservations.filter(
            (req) => req.amenity._id === amenityFilter,
         );
      }

      if (towerFilter !== null) {
         filterReservations = filterReservations.filter(
            (req) => req.tower._id === towerFilter,
         );
      }

      if (weekDateFilter) {
         filterReservations = filterReservations.filter(
            (req) =>
               isAfter(new Date(req.createdAt), weekDateFilter.startWeek) &&
               isBefore(new Date(req.createdAt), weekDateFilter.endWeek),
         );
      } else if (dayDateFilter) {
         filterReservations = filterReservations.filter((req) =>
            isSameDay(new Date(req.createdAt), dayDateFilter),
         );
      }

      if (hourDateFilter) {
         filterReservations = filterReservations.filter((req) => {
            return (
               getHours(new Date(req.createdAt)) === getHours(hourDateFilter)
            );
         });
      }

      console.log(filterReservations);
      this.setState({ filterReservations });
   }

   render() {
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Reporte de Resevaciones</h1>
               </div>
            </div>
            <div className="Page-content">
               <div className="ReportContent">
                  <div className="FilterContainer">
                     <UserAutocomplete
                        onChange={this.onFilterUser.bind(this)}
                     />
                     <DateInputFilter
                        valueWeekPicker={
                           this.state.weekDateFilter !== null
                              ? this.state.weekDateFilter.startWeek
                              : null
                        }
                        valueDayPicker={this.state.dayDateFilter}
                        valueHourPicker={this.state.hourDateFilter}
                        onChangeWeek={this._onFilterWeek.bind(this)}
                        onChangeDay={this._onFilterDay.bind(this)}
                        onChangeHour={this._onFilterHour.bind(this)}
                     />
                     <FormControl
                        variant="outlined"
                        className="inputSelectFilter">
                        <InputLabel>Club</InputLabel>
                        <Select
                           name="type"
                           label="Club"
                           value={this.state.towerFilter}
                           onChange={this.onFilterTower.bind(this)}>
                           <MenuItem value={null}>Selecione un Club</MenuItem>
                           {this.state.towers.map((tower) => (
                              <MenuItem key={tower._id} value={tower._id}>
                                 {tower.name}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     <FormControl
                        variant="outlined"
                        className="inputSelectFilter">
                        <InputLabel>Amenidad</InputLabel>
                        <Select
                           name="type"
                           label="Amenidad"
                           value={this.state.amenityFilter}
                           onChange={this.onFilterAmenity.bind(this)}>
                           <MenuItem value={null}>
                              Selecione un Amenidad
                           </MenuItem>
                           {this.state.amenity.map((amenity) => (
                              <MenuItem key={amenity._id} value={amenity._id}>
                                 {amenity.name}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     <Button onClick={this._onResetFilter.bind(this)}>
                        Reset
                     </Button>
                  </div>
                  <AgGrid
                     rowData={this.state.filterReservations}
                     columnDefs={columnsReportReservation}
                  />
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
   mapStateToProps,
   mapDispatchToProps,
)(ReportReservationPage);
