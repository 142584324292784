import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// Layout Component
import DefaultLayout from '../components/Layouts/DefaultLayout/DefaultLayout';
// Pages
import ClubPage from '../pages/Club/ClubPage';
import DashboardPage from '../pages/Dashboard/DashboardPage';
import FoodPage from '../pages/Food/FoodPage';
import MenuPage from '../pages/Menu/MenuPage';
import UserPage from '../pages/User/pages/UserPage/UserPage';
import UserChildPage from '../pages/User/pages/UserChildPage/UserChildPage';
import AmenityPage from '../pages/Amenity/AmenityPage';
import ReservationPage from '../pages/Reservation/ReservationPage';
import ValetParkingPage from '../pages/ValetParking/ValetParkingPage';
import CourtesyPage from '../pages/Courtesy/CourtesyPage';
import ChefPage from '../pages/Chef/ChefPage';
import ReportPage from '../pages/Report/ReportPage';
import ReportReservation from '../pages/Report/pages/ReportReservation/ReportReservationPage';
import ReportFood from '../pages/Report/pages/ReportFood/ReportFoodPage';
import ReportCredits from '../pages/Report/pages/ReportCredits/ReportCreditsPage';
import ReportPaymentPage from '../pages/Report/pages/ReportPayment/ReportPaymentPage';
import NewsPage from '../pages/News/NewsPage';
import ReportValetParkingPage from '../pages/Report/pages/ReportValetParking/ReportValetParkingPage';
import ReportRequestFoodPage from '../pages/Report/pages/ReportRequestFood/ReportRequestFoodPage';
import ReportSalesFoodPage from '../pages/Report/pages/ReportSales/ReportSalesFoodPage';
import ReportCreditsExtraPage from '../pages/Report/pages/ReportCreditsExtra/ReportCreditsExtraPage';
import ReportCourtesyPage from '../pages/Report/pages/ReportCourtesy/ReportCourtesyPage';
import ReportSalesReservationPage from '../pages/Report/pages/ReportSales/ReportSalesReservationPage';
import FloorsPage from '../pages/Floors/FloorsPage';
import ReportTipsPage from '../pages/Report/pages/ReportTips/ReportPaymentPage';
import TowersPage from '../pages/Towers/TowersPage';
import MomentReservaction from '../pages/MomentReservation';
import CreditPrice from '../pages/CreditPrice/CreditPrice';

const AppRouting = () => {
   return (
      <Router>
         <DefaultLayout>
            <Switch>
               <Route path="/" exact component={DashboardPage} />
               <Route path="/user" exact component={UserPage} />
               <Route path="/user/:id/child" exact component={UserChildPage} />
               <Route path="/club" exact component={ClubPage} />
               <Route
                  path="/valet-parking"
                  exact
                  component={ValetParkingPage}
               />
               <Route path="/menu" exact component={MenuPage} />
               <Route path="/food" exact component={FoodPage} />
               <Route path="/kitchen" exact component={ChefPage} />
               <Route
                  path="/concierge"
                  exact
                  component={() => <ChefPage isConcierge={true} />}
               />

               <Route path="/report" exact component={ReportPage} />
               <Route path="/report/credit" exact component={ReportCredits} />
               <Route path="/report/food" exact component={ReportFood} />
               <Route
                  path="/report/valet-parking"
                  exact
                  component={ReportValetParkingPage}
               />
               <Route
                  path="/report/request-food"
                  exact
                  component={ReportRequestFoodPage}
               />
               <Route
                  path="/report/reservation"
                  exact
                  component={ReportReservation}
               />
               <Route
                  path="/report/sales/food"
                  exact
                  component={ReportSalesFoodPage}
               />
               <Route
                  path="/report/sales/reservation"
                  exact
                  component={ReportSalesReservationPage}
               />
               <Route
                  path="/report/credit-extra"
                  exact
                  component={ReportCreditsExtraPage}
               />
               <Route
                  path="/report/credit-pay"
                  exact
                  component={ReportPaymentPage}
               />
               <Route
                  path="/report/tips-pay"
                  exact
                  component={ReportTipsPage}
               />
               <Route
                  path="/report/courtesy"
                  exact
                  component={ReportCourtesyPage}
               />
               <Route path="/amenity" exact component={AmenityPage} />
               <Route path="/floors" exact component={FloorsPage} />
               <Route path="/news" exact component={NewsPage} />
               <Route path="/courtesy" exact component={CourtesyPage} />
               <Route path="/reservation" exact component={ReservationPage} />
               <Route path={'/towers'} exact component={TowersPage} />
               <Route
                  path={'/reservation-day'}
                  exact
                  component={MomentReservaction}
               />
               <Route path={'/price-credit'} exact component={CreditPrice} />
            </Switch>
         </DefaultLayout>
      </Router>
   );
};

export default AppRouting;
