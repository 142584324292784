import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
   Checkbox,
   FormControlLabel,
   Switch,
   TextField,
} from '@material-ui/core';
import Button from '../../../../components/Buttons/Button';
import Modal from '../../../../components/Modal/Modal';
import { http } from '../../../../utils/http';
import { connect } from 'react-redux';
import {
   addUserChild as addUserChildAction,
   updateUserChild as updateUserChildAction,
} from '../../../../redux/actions/user.action';
import { addListTower as addListTowerAction } from '../../../../redux/actions/tower.action';
import { convertArrayToObjectForError } from '../../../../utils/convertArrayToObject';

const initialState = {
   email: '',
   name: '',
   lastName: '',
   isRequestCredits: false,
   isRequestFood: false,
   towers: [],
};
const initialStateError = {
   email: false,
   name: false,
   lastName: false,
   towers: [],
};

const EditModalUser = ({
   isOpen,
   onClose,
   userParentId,
   user,
   addUserChild,
   updateUserChild,
   addListTower,
   towers,
}) => {
   const [formModalUser, setFormModalUser] = useState(initialState);
   const [errors, setErrors] = useState(initialStateError);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      if (user) {
         setFormModalUser({
            email: user.email,
            name: user.name,
            lastName: user.lastName,
            isRequestCredits: user.isRequestCredits,
            isRequestFood: user.isRequestFood,
            towers: user.towers,
         });
      }
      _getTowers();
      return () => {
         setFormModalUser(initialState);
         setErrors(initialStateError);
      };
   }, [user, isOpen]);

   const _getTowers = () => {
      http('/tower', 'GET', {})
         .then((res) => {
            addListTower(res.data.data);
         })
         .catch((err) => {
            console.error(err);
         });
   };

   const _onCreateUser = () => {
      // evt.preventDefault();
      setLoading(true);

      http(`/user/${userParentId}/child`, 'POST', {
         ...formModalUser,
      })
         .then((res) => {
            console.log(res);
            addUserChild(res.data.data);
            onClose();
         })
         .catch((err) => {
            console.error(err);
            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         })

         .finally(() => {
            setLoading(false);
         });
   };

   const _onEditUser = () => {
      // evt.preventDefault();
      setLoading(true);

      http(`/user/child/${user._id}`, 'PUT', {
         ...formModalUser,
      })
         .then((res) => {
            console.log(res);
            updateUserChild(res.data.data);
            onClose();
         })
         .catch((err) => {
            console.error(err);
            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const _isDisableButtonModal = () => {
      const { email, name, lastName, towers } = formModalUser;

      return (
         email === '' ||
         name === '' ||
         lastName === '' ||
         towers.length === 0
      );
   };

   const _changeHandleText = (evt, key) => {
      setFormModalUser({
         ...formModalUser,
         [key]: evt.target.value,
      });
   };

   const isCheckedItem = (towerId) => {
      return formModalUser.towers.indexOf(towerId) !== -1;
   };

   const onChangeChecked = (towerId) => {
      if (isCheckedItem(towerId)) {
         setFormModalUser({
            ...formModalUser,
            towers: formModalUser.towers.filter((tower) => tower !== towerId),
         });
      } else {
         setFormModalUser({
            ...formModalUser,
            towers: [...formModalUser.towers, towerId],
         });
      }
   };

   const handleChangeIsRequestCredits = () => {
      setFormModalUser({
         ...formModalUser,
         isRequestCredits: !formModalUser.isRequestCredits,
      });
   };

   const handleChangeIsRequestFood = () => {
      setFormModalUser({
         ...formModalUser,
         isRequestFood: !formModalUser.isRequestFood,
      });
   };

   return (
      <Modal
         actions={
            <div className="createUser-actions-modal">
               <Button className="actions-item" onClick={onClose}>
                  Cancelar
               </Button>
               <Button
                  loading={loading}
                  className="actions-item"
                  onClick={user ? _onEditUser : _onCreateUser}
                  disabled={_isDisableButtonModal()}>
                  Aceptar
               </Button>
            </div>
         }
         isOpen={isOpen}
         title={user !== null ? 'Editar Usuario' : 'Agregar Usuario'}
         description={
            user !== null
               ? `Actualice la información necesaria para editar un usuario`
               : 'Agrege la información necesaria para crear un nuevo usuario'
         }
         onClose={onClose}>
         <div className="createUser-content-modal">
            <TextField
               name="name"
               className="createUser-inputs"
               label="Nombre"
               variant="outlined"
               value={formModalUser.name}
               error={errors.name !== false}
               helperText={errors.name}
               onChange={(evt) => _changeHandleText(evt, 'name')}
            />
            <TextField
               name="lastName"
               className="createUser-inputs"
               label="Apellido"
               variant="outlined"
               value={formModalUser.lastName}
               error={errors.lastName !== false}
               helperText={errors.lastName}
               onChange={(evt) => _changeHandleText(evt, 'lastName')}
            />
            <TextField
               name="email"
               className="createUser-inputs"
               label="Correo"
               variant="outlined"
               disabled={user !== null}
               autoComplete="email"
               value={formModalUser.email}
               error={errors.email !== false}
               helperText={errors.email}
               onChange={(evt) => _changeHandleText(evt, 'email')}
            />
            <div
               style={{
                  marginLeft: 5,
                  display: 'flex',
                  flexDirection: 'column',
               }}>
               {towers.map((tower) => (
                  <FormControlLabel
                     key={tower._id}
                     control={
                        <Checkbox
                           value={tower}
                           onChange={() => onChangeChecked(tower._id)}
                           checked={isCheckedItem(tower._id)}
                           inputProps={{ 'aria-label': tower._id }}
                        />
                     }
                     label={tower.name}
                  />
               ))}

               <FormControlLabel
                  control={
                     <Switch
                        checked={formModalUser.isRequestCredits}
                        onChange={handleChangeIsRequestCredits}
                        name="isRequestCredits"
                     />
                  }
                  label="Solicitar créditos"
               />
               <FormControlLabel
                  control={
                     <Switch
                        checked={formModalUser.isRequestFood}
                        onChange={handleChangeIsRequestFood}
                        name="isRequestFood"
                     />
                  }
                  label="Solicitar comida"
               />
            </div>
         </div>
      </Modal>
   );
};

EditModalUser.propTypes = {};

const mapStateToProps = (state) => ({
   towers: state.towerReducer.towers,
});

const mapDispatchToProps = (dispatch) => ({
   addUserChild: (user) => dispatch(addUserChildAction(user)),
   updateUserChild: (user) => dispatch(updateUserChildAction(user)),
   addListTower: (towers) => dispatch(addListTowerAction(towers)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditModalUser);
