import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
   FormControl,
   InputLabel,
   MenuItem,
   Select,
   TextField,
} from '@material-ui/core';
import Button from '../../../../components/Buttons/Button';
import Modal from '../../../../components/Modal/Modal';
import { http } from '../../../../utils/http';
import { connect } from 'react-redux';
import {
   addValetParking as addValetParkingAction,
   updateValetParking as updateValetParkingAction,
} from '../../../../redux/actions/valetParking.action';
import { convertArrayToObjectForError } from '../../../../utils/convertArrayToObject';

const initialState = {
   email: '',
   name: '',
   lastName: '',
   tower: '',
};
const initialStateError = {
   email: false,
   name: false,
   lastName: false,
   tower: false,
};

const EditModalValet = ({
   isOpen,
   onClose,
   valet,
   addValetParking,
   updateValetParking,
   towers,
}) => {
   const [formModalUser, setFormModalUser] = useState(initialState);
   const [loading, setLoading] = useState(false);
   const [errors, setErrors] = useState(initialStateError);

   useEffect(() => {
      if (valet) {
         console.log(valet);
         setFormModalUser({
            email: valet.email,
            name: valet.name,
            lastName: valet.lastName,
            tower: valet.tower._id,
         });
      }

      return () => {
         setFormModalUser(initialState);
         setErrors(initialStateError);
      };
   }, [valet, isOpen]);

   const _onCreateUser = () => {
      // evt.preventDefault();
      setLoading(true);
      http('/user/valet-parking', 'POST', formModalUser)
         .then((res) => {
            console.log(res);
            addValetParking(res.data.data);
            onClose();
         })
         .catch((err) => {
            console.error(err);
            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const _onEditUser = () => {
      // evt.preventDefault();
      setLoading(true);
      http(`/user/valet-parking/${valet._id}`, 'PUT', formModalUser)
         .then((res) => {
            console.log(res);
            updateValetParking(res.data.data);
            onClose();
         })
         .catch((err) => {
            console.error(err);
            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const _isDisableButtonModal = () => {
      const { email, name, lastName, tower } = formModalUser;

      return (
         email === '' ||
         name === '' ||
         lastName === '' ||
         tower === ''
      );
   };

   const _changeHandleText = (evt, key) => {
      setFormModalUser({
         ...formModalUser,
         [key]: evt.target.value,
      });
   };

   // const _onlyNumber = (value) => {
   //    //   this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
   // };

   return (
      <Modal
         actions={
            <div className="createUser-actions-modal">
               <Button className="actions-item" onClick={onClose}>
                  Cancelar
               </Button>
               <Button
                  loading={loading}
                  className="actions-item"
                  onClick={valet ? _onEditUser : _onCreateUser}
                  disabled={_isDisableButtonModal()}>
                  Aceptar
               </Button>
            </div>
         }
         isOpen={isOpen}
         title={
            valet !== null ? 'Editar Valet Parking' : 'Agregar Valet Parking'
         }
         description={
            valet !== null
               ? 'Actualice la información necesaria para editar un valet parking'
               : 'Agrege la información necesaria para crear un nuevo valet parking'
         }
         onClose={onClose}>
         <div className="createUser-content-modal">
            <TextField
               name="name"
               className="createUser-inputs"
               label="Nombre"
               variant="outlined"
               value={formModalUser.name}
               error={errors.name !== false}
               helperText={errors.name}
               onChange={(evt) => _changeHandleText(evt, 'name')}
            />
            <TextField
               name="lastName"
               className="createUser-inputs"
               label="Apellido"
               variant="outlined"
               value={formModalUser.lastName}
               error={errors.lastName !== false}
               helperText={errors.lastName}
               onChange={(evt) => _changeHandleText(evt, 'lastName')}
            />
            <TextField
               name="email"
               className="createUser-inputs"
               label="Correo"
               variant="outlined"
               disabled={valet !== null}
               autoComplete="email"
               value={formModalUser.email}
               error={errors.email !== false}
               helperText={errors.email}
               onChange={(evt) => _changeHandleText(evt, 'email')}
            />
            <FormControl
               variant="outlined"
               className="createUser-inputs"
               error={errors.tower !== false}>
               <InputLabel>Club</InputLabel>
               <Select
                  name="type"
                  label="Club"
                  value={formModalUser.tower}
                  onChange={(evt) => _changeHandleText(evt, 'tower')}>
                  <MenuItem value={''}>Selecione un Club</MenuItem>
                  {towers.map((tower) => (
                     <MenuItem key={tower._id} value={tower._id}>
                        {tower.name}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
         </div>
      </Modal>
   );
};

EditModalValet.propTypes = {};

const mapStateToProps = (state) => ({
   towers: state.towerReducer.towers,
});

const mapDispatchToProps = (dispatch) => ({
   addValetParking: (user) => dispatch(addValetParkingAction(user)),
   updateValetParking: (user) => dispatch(updateValetParkingAction(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditModalValet);
