import React, { useState } from 'react';
import { http } from '../../utils/http';
import Swal from 'sweetalert2';
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Buttons/Button';
import CreateExcel from '../../class/CreateExcel';
import TowerList from './components/TowerList';
import Tab from './components/Tab';
import ReservationCard from './components/ReservationCard';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

import ServiceCard from './components/ServiceCard';
import ItemPrint from './components/ItemPrint';

const typeView = {
   RESERVACIONES: 'RESERVACIONES',
   SERVICIOS: 'SERVICIOS',
};
export default function MomentReservaction() {
   const [reservaciones, setReservaciones] = React.useState([]);
   const [stop, setStop] = React.useState(false);
   const [tower, setTower] = React.useState([]);
   const [form, setForm] = useState({});
   const [towerSeleted, setTowerSelected] = useState(
      '5f63ac447fd08913d3eef97b',
   );
   const [menus, setMenus] = useState([]);
   const [itemsSelected, setItemsSelected] = useState([]);
   const [showModal, setShowModal] = useState(false);
   const [showModalPenalizacion, setShowModalPenalizacion] = useState(false);

   const [view, setView] = useState(typeView.RESERVACIONES);

   const listReservations = React.useMemo(() => {
      if (!reservaciones) return null;

      return reservaciones
         .sort((a, b) => parseISO(b.schedule.start) - parseISO(a.schedule.end))
         .sort((a, b) => {
            if (a.metric.progress > 100 && b.metric.progress <= 100) {
               return 1;
            }
            if (b.metric.progress > 100 && a.metric.progress <= 100) {
               return -1;
            }
            // En cualquier otro caso, no se cambia el orden.
            return 0;
         });
   }, [reservaciones]);

   React.useEffect(() => {
      const pollData = () => {
         if (!stop) {
            _getReervaciones();
            getFoods();
         }
      };

      const interval = setInterval(pollData, 30000);

      return () => {
         clearInterval(interval);
      };
   }, [stop]);

   React.useEffect(() => {
      _getTowers();
   }, []);

   React.useEffect(() => {
      _getReervaciones();
      getFoods();
   }, [towerSeleted]);

   const _getReervaciones = async () => {
      if (!towerSeleted) return;
      http(`/reservation/moment?tower=${towerSeleted || ''}`, 'GET', {})
         .then((res) => {
            if (res && res.data) {
               setReservaciones(res.data.data.list);
            }
         })
         .catch((err) => console.error(err));
   };

   const getFoods = async () => {
      const endpoint = `/request-food/today-and-tomorrow?tower=${
         towerSeleted || ''
      }`;
      http(endpoint, 'GET', {})
         .then((res) => {
            if (res && res.data) {
               setMenus(res.data.data);
            }
         })
         .catch((err) => console.error(err));
   };

   const _getTowers = async () => {
      http('/tower', 'GET', {})
         .then((res) => {
            setTower(res.data.data);
         })
         .catch((err) => console.error(err));
   };

   const handlerSend = () => {
      if (!form.penalty) {
         Swal.fire({
            title: 'Error!',
            text: 'Ingresa la cantidad a penalizar',
            icon: 'error',
            confirmButtonText: 'ok',
         });
         return;
      }
      http('/reservation/penalty', 'POST', form)
         .then((res) => {
            Swal.fire({
               title: 'Se ha registrado la penalizaciòn',
               showClass: {
                  popup: 'animate__animated animate__fadeInDown',
               },
               hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
               },
            });
         })
         .catch((err) => console.error(err))
         .finally(() => {
            _getReervaciones();
            setStop(false);
            setShowModalPenalizacion(false);
         });
   };

   const buildExport = (data) => {
      const excel = new CreateExcel(
         'Reservaciones',
         [
            {
               headerName: 'TORRE',
               field: 'tower',
            },
            {
               headerName: 'PISO',
               field: 'piso',
            },
            {
               headerName: 'AMENIDAD',
               field: 'amenidad',
            },
            {
               headerName: 'USUARIO',
               field: 'usuario',
            },
            {
               headerName: 'CORREO',
               field: 'email',
            },
            {
               headerName: 'CLAVE',
               field: 'code',
            },
            {
               headerName: 'INICIO',
               field: 'start',
            },
            {
               headerName: 'FIN',
               field: 'end',
            },
            {
               headerName: 'PENALIZADO',
               field: 'penalizado',
            },
         ],
         data.map((item) => {
            const start = parseISO(item.schedule.start);
            const end = parseISO(item.schedule.end);
            const formatStr = 'EEEE dd LLL - HH:mm';
            return {
               tower: item?.tower?.name,
               piso: item?.floor?.name,
               amenidad: item?.amenity?.name,
               usuario: item?.user?.fullName,
               email: item?.user?.email,
               code: item?.code,
               start: format(start, formatStr, { locale: es }),
               end: format(end, formatStr, { locale: es }),
               penalizado: item?.penalty ?? 0,
            };
         }),
      );
      excel.createDefaultExcel();
   };

   const handleConfirm = () => {
      Swal.fire({
         title: 'Exportar reporte',
         html: `<div class="flex flex-col">
                  <div class="flex flex-col">
                     <label for="start-date">Inicio:</label>
                     <input type="date" id="start-date" class="swal2-input">
                  </div>
                  <div class="flex flex-col">
                     <label for="end-date">Fin:</label>
                     <input type="date" id="end-date" class="swal2-input">
                  </div>
                  </div>`,
         focusConfirm: false,
         confirmButtonText: 'Generar',
         preConfirm: () => {
            const startDate = document.getElementById('start-date').value;
            const endDate = document.getElementById('end-date').value;
            if (!startDate || !endDate) {
               Swal.showValidationMessage('Ingrese las fechas');
            }
            return { startDate: startDate, endDate: endDate };
         },
      }).then((result) => {
         if (result.isConfirmed) {
            const { startDate, endDate } = result.value;
            const endpoint = `/reservation/export?tower=${towerSeleted}&start=${startDate}&end=${endDate}`;

            http(endpoint, 'GET', {})
               .then((res) => {
                  if (res.data) {
                     const {
                        data: { list },
                     } = res.data;
                     buildExport(list);
                  }
               })
               .catch((err) => console.error(err));
         }
      });
   };

   return (
      <div className="px-10 py-5 bg-blue-100 h-[100%]  flex flex-col">
         <TowerList
            towers={tower}
            towerSelected={towerSeleted}
            onSelectTower={setTowerSelected}
         />

         <div className="flex flex-row justify-between  items-center">
            <ul class="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400 my-3">
               <Tab
                  label="Reservaciones"
                  isActive={view === typeView.RESERVACIONES}
                  onClick={() => setView(typeView.RESERVACIONES)}
               />
               <Tab
                  label="Servicios a reservaciones"
                  isActive={view === typeView.SERVICIOS}
                  onClick={() => setView(typeView.SERVICIOS)}
               />
            </ul>
            <a
               class={`inline-block px-4 py-3 rounded-lg text-white bg-blue-600 `}
               onClick={handleConfirm}>
               Descargar excel
            </a>
         </div>
         {view === typeView.RESERVACIONES && (
            <div className="overflow-y-auto min-h-[400px] max-h-[600px] grid grid-cols-4 gap-x-2 gap-y-2 pb-10">
               {listReservations.map((reservation) => {
                  return (
                     <ReservationCard
                        key={reservation._id}
                        reservation={reservation}
                        onPenalize={(reservationId, userId) => {
                           setStop(true);
                           setForm({ ...form, reservationId, userId });
                           setShowModalPenalizacion(true);
                        }}
                     />
                  );
               })}
            </div>
         )}

         {view === typeView.SERVICIOS && (
            <div className="grid grid-cols-3 gap-2">
               {menus &&
                  menus.map((item, index) => {
                     return (
                        <ServiceCard
                           item={item}
                           index={index}
                           setShowModal={setShowModal}
                           setItemsSelected={setItemsSelected}
                        />
                     );
                  })}
            </div>
         )}

         <Modal
            actions={
               <div className="createUser-actions-modal">
                  <Button
                     className="actions-item"
                     onClick={() => {
                        setItemsSelected([]);
                        setShowModal(false);
                     }}>
                     Cancelar
                  </Button>
                  <Button
                     className="actions-item"
                     onClick={() => {
                        setItemsSelected([]);
                        setShowModal(false);
                     }}>
                     Aceptar
                  </Button>
               </div>
            }
            isOpen={showModal}
            title={`Servicios`}
            description={
               'Agrege lo necesario para que la torre tenga la información correcta'
            }
            onClose={() => {
               setItemsSelected([]);
               setShowModal(false);
            }}
            width={1000}>
            <div className="grid grid-cols-2 w-full">
               {itemsSelected &&
                  itemsSelected.map((item) => {
                     return <ItemPrint item={item} />;
                  })}
            </div>
         </Modal>

         <Modal
            actions={
               <div className="createUser-actions-modal">
                  <Button
                     className="actions-item"
                     onClick={() => {
                        setStop(false);
                        setShowModalPenalizacion(false);
                     }}>
                     Cancelar
                  </Button>
                  <Button
                     className="actions-item"
                     onClick={() => {
                        handlerSend();
                     }}>
                     Penalizar
                  </Button>
               </div>
            }
            isOpen={showModalPenalizacion}
            title={`Penalizar`}
            description={''}
            onClose={() => {
               setStop(false);
               setShowModalPenalizacion(false);
            }}
            width={500}
            height={300}>
            <div className="grid grid-cols-2 w-full h-full justify-center items-center">
               <input
                  placeholder="0"
                  name="penalty"
                  className="mt-2 flex h-10 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-300 dark:!border-white/10 dark:text-white"
                  type="text"
                  onChange={(event) => {
                     setForm({
                        ...form,
                        penalty: event.target.value,
                     });
                  }}
               />
            </div>
         </Modal>
      </div>
   );
}
