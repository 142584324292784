export const columns = [
   {
      headerName: 'ID',
      field: '_id',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Descripción',
      field: 'description',
      resizable: true,
   },
   {
      headerName: 'Tipo',
      field: 'type',
      filter: true,
      sortable: true,
   },
   {
      headerName: 'Fecha',
      field: 'createdAt',
      cellRenderer: 'agFullDateCellRenderer',
      filter: true,
      sortable: true,
      resizable: true,
   },
];
