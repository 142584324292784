import { Button } from '@material-ui/core';

import React, { useEffect, useState } from 'react';
import AgGrid from '../../../../components/AgGrid/AgGrid';
import Modal from '../../../../components/Modal/Modal';
import { http } from '../../../../utils/http';
import { columns } from './columns';

const initialState = [];

const HistoryModalUser = ({ showModal, dismissModal, userId }) => {
   const [history, setHistory] = useState(initialState);

   useEffect(() => {
      _getHistory(userId);
      return () => {
         setHistory(initialState);
      };
   }, [userId]);

   const _getHistory = (userId) => {
      http(`/history/user/${userId}`, 'GET', {})
         .then((res) => {
            console.log(res);
            setHistory(res.data.data);
         })
         .catch((err) => {
            console.log(err);
         });
   };

   return (
      <Modal isOpen={showModal} onClose={dismissModal} title="Historial" width={950}>
         <AgGrid columnDefs={columns} rowData={history} />
      </Modal>
   );
};

export default HistoryModalUser;
