import { ADD_LIST_USER, ADD_LIST_USER_CHILD, ADD_USER, ADD_USER_CHILD, UPDATE_USER, UPDATE_USER_CHILD } from '../types/user.type';

export function addListUser(data) {
   return {
      type: ADD_LIST_USER,
      payload: data,
   };
}

export function addtUser(data) {
   return {
      type: ADD_USER,
      payload: data,
   };
}

export function updateUser(data) {
   return {
      type: UPDATE_USER,
      payload: data,
   };
}

export function addListUserChild(data) {
   return {
      type: ADD_LIST_USER_CHILD,
      payload: data,
   };
}

export function addUserChild(data) {
   return {
      type: ADD_USER_CHILD,
      payload: data,
   };
}

export function updateUserChild(data) {
   return {
      type: UPDATE_USER_CHILD,
      payload: data,
   };
}
