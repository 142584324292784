import {
   Card,
   CardContent,
   CardHeader,
   Drawer,
   FormControl,
   Grid,
   IconButton,
   InputLabel,
   MenuItem,
   Select,
   Tooltip,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import React, { Component } from 'react';
import { Line, Pie, Polar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import AgGrid from '../../../../components/AgGrid/AgGrid';
import { http } from '../../../../utils/http';
import { columnsReservation } from './columnsReservation';
import {
   addDays,
   compareAsc,
   eachHourOfInterval,
   format,
   isAfter,
   isBefore,
   isEqual,
   isFriday,
   isMonday,
   isSaturday,
   isSunday,
   isThursday,
   isTuesday,
   isWednesday,
   startOfDay,
} from 'date-fns';
class ReportSalesFoodPage extends Component {
   state = {
      reservations: [],
      // reservationByFormated: [],
      filterReservationByFormated: [],
      filterCompanyReservationFormated: [],
      filterDayTotalByFormate: [],
      filterHoursTotalByFormate: [],
      isAscReservation: true,
      isAscCompany: true,
      // isOpenDrawer: false,
      towers: [],
      towerFilter: null,
      dateFilter: null,
      cantDaysFilter: 1,
      isLoad: false,
   };

   componentDidMount() {
      this._getTowers();
   }

   _getOrderDaysData(date, item, cant) {
      return {
         lunes: isMonday(date)
            ? cant + (item?.days?.lunes || 0)
            : item?.days?.lunes || 0,
         martes: isTuesday(date)
            ? cant + (item?.days?.martes || 0)
            : item?.days?.martes || 0,
         miercoles: isWednesday(date)
            ? cant + (item?.days?.miercoles || 0)
            : item?.days?.miercoles || 0,
         jueves: isThursday(date)
            ? cant + (item?.days?.jueves || 0)
            : item?.days?.jueves || 0,
         viernes: isFriday(date)
            ? cant + (item?.days?.viernes || 0)
            : item?.days?.viernes || 0,
         sabado: isSaturday(date)
            ? cant + (item?.days?.sabado || 0)
            : item?.days?.sabado || 0,
         domingo: isSunday(date)
            ? cant + (item?.days?.domingo || 0)
            : item?.days?.domingo || 0,
      };
   }

   async _getRequestFood(users = this.state.users) {
      const { towerFilter } = this.state;
      this.setState({ isLoad: true });
      await http(`/reservation?clubId=${towerFilter}`, 'GET', {})
         .then((res) => {
            let reservationData = res.data.data;
            reservationData = reservationData.sort((a, b) =>
               compareAsc(
                  new Date(a.schedule.start),
                  new Date(b.schedule.start),
               ),
            );
            this.setState(
               {
                  reservations: reservationData,
                  isLoad: false,
               },
               () => {},
            );
         })
         .catch((err) => console.log(err));
   }

   _getTowers() {
      http('/tower', 'GET', {})
         .then((res) => {
            this.setState({
               towers: res.data.data,
            });
         })
         .catch((err) => console.log(err));
   }

   _getDatasetChatLine(label = '', data = []) {
      return [
         {
            label: label,
            // .length > 15 ? label.slice(0, 15) + '...' : label,
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(0, 62, 85,0.4)',
            borderColor: 'rgb(0, 62, 85)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgb(0, 62, 85)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgb(0, 62, 85)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data,
         },
      ];
   }

   _getDataForChartHourDay(label = '', data = [], isHours = false) {
      return {
         labels: !isHours
            ? ['L', 'M', 'M', 'J', 'V', 'S', 'D']
            : eachHourOfInterval({
                 start: new Date(2020, 1, 1, 0),
                 end: new Date(2020, 1, 1, 23),
              }).map((date) => format(date, 'hh a')),
         datasets: this._getDatasetChatLine(label, data),
      };
   }

   _getDataForChartDate(label = '', data = {}) {
      return {
         labels: Object.keys(data),
         datasets: this._getDatasetChatLine(label, Object.values(data)),
      };
   }

   onFilterTower(evt) {
      const value = evt.target.value;
      this.setState(
         {
            towerFilter: value === null ? null : value,
         },
         async () => {
            await this._getRequestFood();
            this._onFilter();
         },
      );
   }
   _onFilterDate(evt) {
      const value = evt._d;
      this.setState(
         {
            dateFilter: value === null ? null : value,
         },
         () => {
            this._onFilter();
         },
      );
   }
   _onFilterDayRange(evt) {
      const value = evt.target.value;
      this.setState(
         {
            cantDaysFilter: value === null ? null : value,
         },
         () => {
            this._onFilter();
         },
      );
   }

   _onFilter() {
      const { towerFilter, dateFilter, cantDaysFilter, reservations } =
         this.state;
      let reservationsData = reservations;

      let amenities = {};
      let companyReservation = {};
      let dayReservation = {
         days: {
            lunes: 0,
            martes: 0,
            miercoles: 0,
            jueves: 0,
            viernes: 0,
            sabado: 0,
            domingo: 0,
         },
      };
      let hoursReservation = {};

      eachHourOfInterval({
         start: new Date(2020, 1, 1, 0),
         end: new Date(2020, 1, 1, 23),
      }).forEach((date) => {
         const hour = format(date, 'HH');
         hoursReservation['i' + hour] = 0;
      });

      for (const reservation of reservationsData) {
         const dateRequest = new Date(reservation.schedule.start);

         if (reservation.courtesy && !reservation.pay) {
            continue;
         }
         if (dateFilter) {
            const startDayFilter = startOfDay(dateFilter);
            const endDateFilter = addDays(startDayFilter, cantDaysFilter);
            if (
               isEqual(startDayFilter, new Date(reservation.schedule.start)) ||
               isAfter(startDayFilter, new Date(reservation.schedule.start)) ||
               isBefore(endDateFilter, new Date(reservation.schedule.start))
            ) {
               continue;
            }
         }

         if(!reservation.user) continue
         const companyId = reservation.user.user
            ? reservation.user.user.nameCompany
            : reservation.user.nameCompany;

         //Filtro de empresas con mayor gasto
         const formatDate = format(dateRequest, 'dd/MM');

         companyReservation[companyId] = {
            company: companyId,
            creditsUsage:
               reservation.creditsUsage +
               (companyReservation[companyId]?.creditsUsage || 0),
            days: {
               ...(companyReservation[companyId]?.days || {}),
               [formatDate]:
                  reservation.creditsUsage +
                  (companyReservation[companyId]?.days[formatDate] || 0),
            },
         };

         // Filtro por horarios
         const hour = format(dateRequest, 'HH');
         hoursReservation['i' + hour] += 1;

         dayReservation = {
            days: this._getOrderDaysData(dateRequest, dayReservation, 1),
         };

         amenities[reservation.amenity._id] = {
            amenity: reservation.amenity,
            cantReservation:
               1 + (amenities[reservation.amenity._id]?.cantReservation || 0),
            days: {
               ...(amenities[reservation.amenity._id]?.days || {}),
               [formatDate]:
                  1 +
                  (amenities[reservation.amenity._id]?.days[formatDate] || 0),
            },
         };
      }

      const amenitySort = Object.values(amenities).sort(
         (a, b) => b.cantReservation - a.cantReservation,
      );
      const companySort = Object.values(companyReservation).sort(
         (a, b) => b.creditsUsage - a.creditsUsage,
      );

      this.setState({
         isAscReservation: true,
         isAscCompany: true,
         filterReservationByFormated: Object.values(amenitySort),
         filterCompanyReservationFormated: companySort,
         filterDayTotalByFormate: Object.values(dayReservation.days),
         filterHoursTotalByFormate: Object.values(hoursReservation),
      });
   }

   _changeOrderSortReservation() {
      const isAsc = this.state.isAscReservation;

      this.setState({
         isAscReservation: !isAsc,
         filterReservationByFormated:
            this.state.filterReservationByFormated.reverse(),
      });
   }
   _changeOrderSortCompany() {
      const isAsc = this.state.isAscCompany;
      this.setState({
         isAscCompany: !isAsc,
         filterCompanyReservationFormated:
            this.state.filterCompanyReservationFormated.reverse(),
      });
   }

   render() {
      const { isLoad, filterReservationByFormated } = this.state;
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Reporte de ventas de reservaciones</h1>
               </div>
               <div className="header-actions">
                  {/* <Tooltip title="Gráficas" aria-label="Gráficas">
                     <IconButton onClick={this._toggleDrawer.bind(this)}>
                        <i className="material-icons">analytics</i>
                     </IconButton>
                  </Tooltip> */}
               </div>
            </div>
            <div className="Page-content">
               <div className="FilterContainer">
                  <FormControl variant="outlined" className="inputSelectFilter">
                     <InputLabel>Club</InputLabel>
                     <Select
                        name="type"
                        label="Club"
                        value={this.state.towerFilter}
                        onChange={this.onFilterTower.bind(this)}>
                        {this.state.towers.map((tower) => (
                           <MenuItem key={tower._id} value={tower._id}>
                              {tower.name}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
                  {isLoad ? (
                     <span>Cargando información</span>
                  ) : (
                     <>
                        {' '}
                        <DatePicker
                           autoOk
                           value={this.state.dateFilter}
                           inputVariant="outlined"
                           variant="inline"
                           placeholder="Fecha"
                           className="inputSelectFilter"
                           onChange={this._onFilterDate.bind(this)}
                           maxDate={addDays(new Date(), -28)}
                        />
                        <FormControl
                           variant="outlined"
                           className="inputSelectFilter">
                           <InputLabel>Rango de tiempo</InputLabel>
                           <Select
                              name="type"
                              label="Rango de tiempo"
                              value={this.state.cantDaysFilter}
                              onChange={this._onFilterDayRange.bind(this)}>
                              <MenuItem value={1}>1 día</MenuItem>
                              <MenuItem value={7}>7 días</MenuItem>
                              <MenuItem value={15}>15 días</MenuItem>
                              <MenuItem value={28}>28 días</MenuItem>
                           </Select>
                        </FormControl>
                     </>
                  )}
               </div>
               <div className="ReportContent">
                  <Grid container spacing={3}>
                     <Grid item xs={12} md={6} sm={12}>
                        <Card className="card-chart">
                           <CardHeader
                              title="Amenidad más reservada"
                              action={
                                 <Tooltip
                                    title={
                                       this.state.isAscReservation
                                          ? 'Descendente'
                                          : 'Ascendente'
                                    }
                                    aria-label={
                                       this.state.isAscReservation
                                          ? 'Descendente'
                                          : 'Ascendente'
                                    }>
                                    <IconButton
                                       aria-label="sort"
                                       onClick={this._changeOrderSortReservation.bind(
                                          this,
                                       )}>
                                       <i className="material-icons">
                                          sort_by_alpha
                                       </i>
                                    </IconButton>
                                 </Tooltip>
                              }
                           />
                           <CardContent
                              style={{
                                 height: 'calc(100% - 72px)',
                                 overflow: 'auto',
                              }}>
                              <ul
                                 style={{
                                    listStyle: 'none',
                                    width: '100%',
                                 }}>
                                 {filterReservationByFormated
                                    .map((value, index) => {
                                       return (
                                          <li
                                             key={value.amenity._id}
                                             style={{
                                                height: 200,
                                                width: '100%',
                                             }}>
                                             <div
                                                style={{
                                                  display: 'grid',
                                                  gridColumn: 1,
                                                  placeItems: 'center',
                                                  'gap': 4,
                                                  marginTop: index > 0 ? 50 : 0

                                                }}>
                                                <div style={{width: '100%'}}>
                                                   <span>
                                                      {value.amenity.name}
                                                   </span>
                                                </div>
                                                <div
                                                   style={{
                                                      width: '100%',
                                                      height: '100%',
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      alignItems: 'center',

                                                   }}>
                                                   <Line
                                                      data={this._getDataForChartDate(
                                                         value.amenity.name,

                                                         value.days,
                                                      )}
                                                   />
                                                </div>
                                             </div>
                                          </li>
                                       );
                                    })
                                    .slice(0, 9)}
                              </ul>
                           </CardContent>
                        </Card>
                     </Grid>
                     <Grid item xs={12} md={6} sm={12}>
                        <Card className="card-chart">
                           <CardHeader
                              title="Empresas con más créditos gastados"
                              action={
                                 <Tooltip
                                    title={
                                       this.state.isAscCompany
                                          ? 'Descendente'
                                          : 'Ascendente'
                                    }
                                    aria-label={
                                       this.state.isAscCompany
                                          ? 'Descendente'
                                          : 'Ascendente'
                                    }>
                                    <IconButton
                                       aria-label="settings"
                                       onClick={this._changeOrderSortCompany.bind(
                                          this,
                                       )}>
                                       <i className="material-icons">
                                          sort_by_alpha
                                       </i>
                                    </IconButton>
                                 </Tooltip>
                              }
                           />
                           <CardContent
                              style={{
                                 height: 'calc(100% - 72px)',
                                 overflow: 'auto',
                                 paddingBottom: 10,
                              }}>
                              <ul
                                 style={{
                                    listStyle: 'none',
                                 }}>
                                 {this.state.filterCompanyReservationFormated
                                    .map((value, index) => {
                                       // const labelId = `checkbox-list-secondary-label-${value}`;
                                       return (
                                          <li
                                             key={value.company}
                                             style={{ height: 200 }}>
                                             <div
                                                style={{
                                                   display: 'grid',
                                                   gridColumn: 1,
                                                   placeItems: 'center',
                                                   'gap': 4,
                                                   marginTop: index > 0 ? 50 : 0
                                                }}>
                                                <div style={{width: '100%'}}>
                                                   <span>{value.company}</span>
                                                </div>
                                                <div
                                                   style={{
                                                      width: '100%',
                                                      height: '100%',
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      alignItems: 'center',
                                                   }}>
                                                   <Line
                                                      data={this._getDataForChartDate(
                                                         value.company,

                                                         value.days,
                                                      )}
                                                   />
                                                </div>
                                             </div>
                                          </li>
                                       );
                                    })
                                    .slice(0, 9)}
                              </ul>
                           </CardContent>
                        </Card>
                     </Grid>
                     <Grid item xs={12}>
                        <Card className="card-chart">
                           <CardHeader title="Ventas por Día y Hora" />
                           <CardContent
                              style={{
                                 height: 'calc(100% - 120px)',
                                 overflow: 'auto',
                              }}>
                              <div
                                 style={{
                                    display: 'flex',
                                    height: '100%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                 }}>
                                 <div
                                    style={{
                                       width: '90%',
                                       height: '100%',
                                    }}>
                                    <Line
                                       data={this._getDataForChartHourDay(
                                          'Cantidad de reservaciones por día',
                                          this.state.filterDayTotalByFormate,
                                       )}
                                    />
                                 </div>
                                 <div
                                    style={{
                                       width: '90%',
                                       height: '100%',
                                    }}>
                                    <Line
                                       data={this._getDataForChartHourDay(
                                          'Cantidad de reservaciones por horarios',
                                          this.state.filterHoursTotalByFormate,
                                          true,
                                       )}
                                    />
                                 </div>
                              </div>
                           </CardContent>
                        </Card>
                     </Grid>
                  </Grid>
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
   mapStateToProps,
   mapDispatchToProps,
)(ReportSalesFoodPage);
