import { ADD_LIST_COURTESY, ADD_COURTESY, UPDATE_COURTESY } from '../types/courtesy.type';

export function addListCourtesy(data) {
   return {
      type: ADD_LIST_COURTESY,
      payload: data,
   };
}

export function addCourtesy(data) {
   return {
      type: ADD_COURTESY,
      payload: data,
   };
}

export function updateCourtesy(data) {
   return {
      type: UPDATE_COURTESY,
      payload: data,
   };
}
