import { AiOutlineScan } from 'react-icons/ai';

const ServiceCard = ({ index, item, setShowModal, setItemsSelected }) => {
   const getTextStatus = (status) => {
      const statusText = {
         pending: 'Pendiente',
         current: 'En progreso',
      };
      return statusText[status] || '';
   };

   const getBorder = (status) => {
      const colors = {
         pending: 'bg-yellow-1000',
         current: 'bg-green-500',
      };
      return ` ${colors[status]}`;
   };

   return (
      <div
         key={index}
         className={`grid grid-flow-col  p-6 rounded-lg shadow-lg gap-x-4 ${getBorder(
            item.status,
         )}`}>
         <div className="col-span-2 flex flex-col">
            <span className="text-[14px]">{item.reservation.tower}</span>
            <span className="text-[14px]">{item.reservation.floor}</span>
            <span className="text-[14px]">{item.reservation.amenity}</span>
            <span className="text-[14px]">Clave: {item.reservation.code}</span>
            <span className="text-[14px]">{item.date}</span>
            <span className="text-[14px]">{item.time}</span>
            <span className="text-[14px]">{getTextStatus(item.status)}</span>
            <span className="text-[14px]">{item.comment}</span>
         </div>
         <div className="col-span-1 bg-[rgba(255,255,255,.5)] px-2 py-3 rounded-md flex flex-col">
            <span className="text-[14px]">{item.user}</span>
            <span className="text-[12px]">Pedidos </span>
            <div className="flex justify-around w-full">
               <span className="font-600 text-[32px]">{item.items.length}</span>
               <button
                  className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 !border-none"
                  onClick={() => {
                     setShowModal(true);
                     setItemsSelected(item.items);
                  }}>
                  <AiOutlineScan />
               </button>
            </div>
         </div>
      </div>
   );
};

export default ServiceCard;
