export const columnsCredits = [
   {
      headerName: 'Usuario',
      field: 'user.fullName',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Correo',
      field: 'user.email',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Créditos Base',
      field: 'creditsBase',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Créditos Usados',
      field: 'creditsUsage',
      filter: true,
      sortable: true,
      resizable: true,
   },
];
