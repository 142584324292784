const initialState = {
   floors: [],
};

const floorsReducer = (state = initialState, action) => {
   switch (action.type) {
      case 'ADD_LIST_FLOORS':
         return {
            ...state,
            floors: [...action.payload],
         };

      default:
         return state;
   }
};

export { floorsReducer };
