import {
   Drawer,
   FormControl,
   FormControlLabel,
   IconButton,
   InputLabel,
   MenuItem,
   Radio,
   RadioGroup,
   Select,
   Tooltip,
} from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../../../components/AgGrid/AgGrid';
import { http } from '../../../../utils/http';
import { columnsCredits } from './columnsCredits';
import {
   addListUser as addListUserAction,
   addListUserChild as addListUserChildAction,
} from '../../../../redux/actions/user.action';
import { columnsReservation } from './columnsReservation';
import { columnsRequestFood } from './columnsRequestFood';
import { DatePicker } from '@material-ui/pickers';
class ReportCourtesyPage extends Component {
   state = {
      users: [],
      credits: [],
      creditsDataFormate: [],
      reservation: [],
      reservationDataFormate: [],
      requestFood: [],
      requestFoodDataFormate: [],
      isOpenDrawer: false,
      viewCourtesy: 'credit',
      dateFilter: null,
      cantDaysFilter: 1,
   };

   constructor(props) {
      super(props);
      this._getColumnsByView.bind(this);
      this._getDataByView.bind(this);
   }

   async componentDidMount() {
      await this._getUser();
      await this._getUserChildren();

      const users = [...this.props.users, ...this.props.usersChildren];
      await this._getReservations(users);
      await this._getCredits(users);
      await this._getFood(users);

      this.setState({
         users,
      });

      // console.log([...this.props.users, ...this.props.usersChildren]);
   }

   async _getUser() {
      await http('/user', 'GET', {})
         .then((res) => {
            this.props.addListUser(res.data.data);
         })
         .catch((err) => {
            console.error(err);
         });
   }

   async _getUserChildren() {
      await http('/user/child', 'GET', {})
         .then((res) => {
            this.props.addListUserChild(res.data.data);
         })
         .catch((err) => {
            console.error(err);
         });
   }

   //Endpoint by Reports

   async _getReservations(users = this.state.users) {
      await http('/reservation/report/user', 'GET', {})
         .then((res) => {
            const reservationUser = res.data.data;
            let data = [];
            // console.log(res);
            // console.log(users);
            data = reservationUser.map((item) => {
               return {
                  user: users.find((user) => user._id === item.user.user),
                  amenity: item.data[0].amenity,
                  tower: item.data[0].tower,
                  cantReservation: item.reservations,
               };
            });

            // console.log(data);

            data = data.filter((item) => item.cantReservation >= 12);

            this.setState({
               reservation: reservationUser,
               reservationDataFormate: data,
            });
         })
         .catch((err) => console.log(err));
   }

   async _getCredits(users = this.state.users) {
      await http('/credit', 'GET', {})
         .then((res) => {
            // console.log(res);
            const credits = res.data.data;
            let data = [];

            credits.forEach((credit) => {
               // Filtro el historial de creditos solo para lo que se ha gastado en reservaciones
               const histories = credit.history.filter(
                  (history) => history.type === 'CREATE_RESERVATION',
               );

               // recorro los usuarios para mostrar solo los que han tenido actividad reservando y han cosumido segun el historial de sus creditos
               users.forEach((user) => {
                  if (user.credits) {
                     if (user.credits._id === credit._id) {
                        data.push({
                           user,
                           creditsUsage: histories
                              .filter((history) => history.user === user._id)
                              .reduce(
                                 (acc, current) => acc + current.amount,
                                 0,
                              ),
                           creditsBase: user.credits.recharge,
                        });
                     }
                  }
               });
            });

            // Si el uso de creditos ha sido mayor al 110%
            data = data.filter(
               (item) => item.creditsUsage >= item.creditsBase * 1.1,
            );

            // console.log(data);
            this.setState({
               credits,
               creditsDataFormate: data,
            });
         })
         .catch((err) => console.log(err));
   }

   async _getFood(users = this.state.users) {
      await http('/request-food/report/user', 'GET', {})
         .then((res) => {
            console.log(res);

            const requestFoodUser = res.data.data;
            let data = [];
            // console.log(res);
            // console.log(users);
            data = requestFoodUser.map((item) => {
               console.log(item);
               return {
                  user: users.find((user) => user._id === item.user),
                  amountTotal: item.data.reduce((acc, current) => {
                     if (!current.pay?.amount) {
                        return acc + 0;
                     }

                     const amountString = current.pay.amount.toString();
                     const amount = amountString.slice(
                        0,
                        amountString.length - 3,
                     );
                     return acc + +amount;
                  }, 0),
               };
            });

            // console.log(data);

            data = data.filter((item) => item.amountTotal >= 1500.0);

            this.setState({
               requestFood: requestFoodUser,
               requestFoodDataFormate: data,
            });
         })
         .catch((err) => console.log(err));
   }

   _toggleDrawer() {
      this.setState({
         isOpenDrawer: !this.state.isOpenDrawer,
      });
   }

   _changeViewCourtesy(evt) {
      const value = evt.target.value;

      this.setState({
         viewCourtesy: value,
      });
   }

   _getColumnsByView() {
      switch (this.state.viewCourtesy) {
         case 'credit':
            return columnsCredits;

         case 'reservation':
            return columnsReservation;

         case 'food':
            return columnsRequestFood;

         default:
            return [];
      }
   }
   _getDataByView() {
      const {
         creditsDataFormate,
         reservationDataFormate,
         requestFoodDataFormate,
      } = this.state;
      switch (this.state.viewCourtesy) {
         case 'credit':
            return creditsDataFormate;

         case 'reservation':
            return reservationDataFormate;

         case 'food':
            return requestFoodDataFormate;

         default:
            return [];
      }
   }

   _onFilterDate(evt) {
      console.log(evt);
      const value = evt._d;
      this.setState(
         {
            dateFilter: value === null ? null : value,
         },
         () => {
            this._onFilter();
         },
      );
   }
   _onFilterDayRange(evt) {
      const value = evt.target.value;
      this.setState(
         {
            cantDaysFilter: value === null ? null : value,
         },
         () => {
            this._onFilter();
         },
      );
   }

   _onFilter() {
      const { dateFilter, cantDaysFilter } = this.state;
      // // let filterRequestFoodByFormated = this.state.requestFoodByFormated;
      // const requestFood = this.state.requestFood;
      // let food = {};
      // for (const request of requestFood) {
      //    console.log(request);
      //    if (dateFilter !== null) {
      //       const startDayFilter = startOfDay(dateFilter);
      //       const endDateFilter = addDays(startDayFilter, cantDaysFilter);
      //       console.log(dateFilter, endDateFilter);
      //       if (
      //          isAfter(startDayFilter, new Date(request.createdAt)) ||
      //          isBefore(endDateFilter, new Date(request.createdAt))
      //       ) {
      //          continue;
      //       }
      //    }
      //    if (towerFilter !== null) {
      //       if (towerFilter !== request.reservation.tower._id) {
      //          continue;
      //       }
      //    }
      //    request.items.forEach((item) => {
      //       food[item._id] = {
      //          food: item,
      //          cantSold: item.cant + (food[item._id]?.cantSold || 0),
      //       };
      //    });
      // }
      // console.log(food);
      // this.setState({ filterRequestFoodByFormated: Object.values(food) });
   }

   render() {
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Reporte de Cortesías</h1>
               </div>
               <div className="header-actions">
                  {/* <Tooltip title="Gráficas" aria-label="Gráficas">
                     <IconButton onClick={this._toggleDrawer.bind(this)}>
                        <i className="material-icons">analytics</i>
                     </IconButton>
                  </Tooltip> */}
               </div>
            </div>
            <div className="Page-content">
               <div className="FilterContainer">
                  <div>
                     <RadioGroup
                        row
                        placeholder="Tipo de cortesía"
                        aria-label="position"
                        style={{
                           borderRadius: 5,
                           border: 'rgba(0, 0, 0, 0.23) 1px solid',
                           padding: '5.5px 10px',
                        }}
                        name="position"
                        defaultValue="credit"
                        value={this.state.viewCourtesy}
                        onChange={this._changeViewCourtesy.bind(this)}>
                        <Tooltip
                           title="Usuarios que superan un 110% de sus créditos"
                           aria-label="Usuarios que superan un 110% de sus créditos">
                           <FormControlLabel
                              value="credit"
                              control={<Radio color="primary" />}
                              label="Créditos"
                           />
                        </Tooltip>
                        <Tooltip
                           title="Usuarios que han reservado más de 12 veces en una amenidad"
                           aria-label="Usuarios que han reservado más de 12 veces en una amenidad">
                           <FormControlLabel
                              value="reservation"
                              control={<Radio color="primary" />}
                              label="Reservaciones"
                           />
                        </Tooltip>
                        <Tooltip
                           title="Usuarios que han gastado más de 1500$"
                           aria-label="Usuarios que han gastado más de 1500$">
                           <FormControlLabel
                              value="food"
                              control={<Radio color="primary" />}
                              label="Alimentos y bebidas"
                           />
                        </Tooltip>
                     </RadioGroup>
                  </div>
                  <DatePicker
                     autoOk
                     value={this.state.dateFilter}
                     inputVariant="outlined"
                     variant="inline"
                     placeholder="Fecha"
                     className="inputSelectFilter"
                     onChange={this._onFilterDate.bind(this)}
                  />
                  <FormControl variant="outlined" className="inputSelectFilter">
                     <InputLabel>Rango de tiempo</InputLabel>
                     <Select
                        name="type"
                        label="Rango de tiempo"
                        value={this.state.cantDaysFilter}
                        onChange={this._onFilterDayRange.bind(this)}>
                        <MenuItem value={1}>1 día</MenuItem>
                        <MenuItem value={7}>7 días</MenuItem>
                        <MenuItem value={15}>15 días</MenuItem>
                        <MenuItem value={28}>28 días</MenuItem>
                     </Select>
                  </FormControl>
               </div>
               <div className="ReportContent">
                  <AgGrid
                     rowData={this._getDataByView()}
                     columnDefs={this._getColumnsByView()}
                  />
                  <Drawer
                     anchor={'right'}
                     open={this.state.isOpenDrawer}
                     onClose={this._toggleDrawer.bind(this)}>
                     {/* <Pie data={this._getDataForChart()} /> */}
                  </Drawer>
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   users: state.userReducer.users,
   usersChildren: state.userReducer.usersChildren,
});

const mapDispatchToProps = (dispatch) => ({
   addListUserChild: (children) => dispatch(addListUserChildAction(children)),
   addListUser: (children) => dispatch(addListUserAction(children)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportCourtesyPage);
