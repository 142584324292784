import { IconButton, Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../components/AgGrid/AgGrid';
import { http } from '../../utils/http';
import { columns, columnsConcierge } from './columns';
import './ChefPage.scss';
import { addListTower as addListTowerAction } from '../../redux/actions/tower.action';
import { addListChef as addListChefAction } from '../../redux/actions/chef.action';
import EditModalChef from './components/EditModalChef/EditModalChef';
import { Column } from 'ag-grid-community';

class ChefPage extends Component {
   state = {
      chefSelected: null,
      showModal: false,
   };

   async componentDidMount() {
      const {isConcierge} = this.props;
      await this._reload(isConcierge);
   }

   _getChefs(isConcierge) {
      http(isConcierge ? '/user/concierge' : '/user/chef' , 'GET', {})
         .then((res) => {
            this.props.addListChef(res.data.data);
         })
         .catch((err) => {
            console.error(err);
         });
   }
   _getTowers() {
      http('/tower', 'GET', {})
         .then((res) => {
            this.props.addListTower(res.data.data);
         })
         .catch((err) => {
            console.error(err);
         });
   }

   async _reload(isConcierge) {
      await this._getChefs(isConcierge);
      await this._getTowers();
   }

   _showModal() {
      this.setState({ showModal: true });
   }

   _hideModal() {
      this.setState({ showModal: false, chefSelected: null });
   }

   _handleRowClicked(value) {
      const user = value.data;
      this.setState({ showModal: true, chefSelected: user });
   }

   render() {
      const {isConcierge} = this.props;
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>{isConcierge ? 'Concierge' : 'Cocina'}</h1>
               </div>
               <div className="header-actions">
                  <Tooltip title="Refrescar" aria-label="Refrescar">
                     <IconButton onClick={()=>{this._reload(isConcierge)}}>
                        <i className="material-icons">refresh</i>
                     </IconButton>
                  </Tooltip>
                  <Tooltip title="Crear" aria-label="Crear">
                     <IconButton onClick={this._showModal.bind(this)}>
                        <i className="material-icons">add</i>
                     </IconButton>
                  </Tooltip>
               </div>
            </div>
            <div className="Page-content">
               <AgGrid
                  rowData={this.props.chefs}
                  columnDefs={isConcierge ? columnsConcierge : columns}
                  onRowDoubleClicked={this._handleRowClicked.bind(this)}
               />
            </div>
            <EditModalChef
               isOpen={this.state.showModal}
               onClose={this._hideModal.bind(this)}
               chef={this.state.chefSelected}
               isConcierge={isConcierge}
            />
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   chefs: state.chefReducer.chefs,
});

const mapDispatchToProps = (dispatch) => ({
   addListTower: (towers) => dispatch(addListTowerAction(towers)),
   addListChef: (chef) => dispatch(addListChefAction(chef)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChefPage);
