import { IconButton, Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../components/AgGrid/AgGrid';
import { http } from '../../utils/http';
import { columns } from './columns';
import './ValetParkingPage.scss';
import { addListTower as addListTowerAction } from '../../redux/actions/tower.action';
import { addListValetParking as addListValetParkingAction } from '../../redux/actions/valetParking.action';
import EditModalValet from './components/EditModalValet/EditModalValet';
class ValetParkingPage extends Component {
   state = {
      valetSelected: null,
      showModal: false,
   };

   async componentDidMount() {
      await this._reload();
   }

   _getValets() {
      http('/user/valet-parking', 'GET', {})
         .then((res) => {
            this.props.addListValetParking(res.data.data);
         })
         .catch((err) => {
            console.error(err);
         });
   }

   _getTowers() {
      http('/tower', 'GET', {})
         .then((res) => {
            this.props.addListTower(res.data.data);
         })
         .catch((err) => {
            console.error(err);
         });
   }

   async _reload() {
      await this._getValets();
      await this._getTowers();
   }

   _showModal() {
      this.setState({ showModal: true });
   }

   _hideModal() {
      this.setState({ showModal: false, valetSelected: null });
   }

   _handleRowClicked(value) {
      const user = value.data;
      this.setState({ showModal: true, valetSelected: user });
   }

   render() {
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Valet Parking</h1>
               </div>
               <div className="header-actions">
                  <Tooltip title="Refrescar" aria-label="Refrescar">
                     <IconButton onClick={this._reload.bind(this)}>
                        <i className="material-icons">refresh</i>
                     </IconButton>
                  </Tooltip>
                  <Tooltip title="Crear" aria-label="Crear">
                     <IconButton onClick={this._showModal.bind(this)}>
                        <i className="material-icons">add</i>
                     </IconButton>
                  </Tooltip>
               </div>
            </div>
            <div className="Page-content">
               <AgGrid
                  rowData={this.props.valets}
                  columnDefs={columns}
                  onRowDoubleClicked={this._handleRowClicked.bind(this)}
               />
            </div>
            <EditModalValet
               isOpen={this.state.showModal}
               onClose={this._hideModal.bind(this)}
               valet={this.state.valetSelected}
            />
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   valets: state.valetParkingReducer.valets,
});

const mapDispatchToProps = (dispatch) => ({
   addListTower: (towers) => dispatch(addListTowerAction(towers)),
   addListValetParking: (valets) => dispatch(addListValetParkingAction(valets)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ValetParkingPage);
