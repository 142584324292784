export const columnsReportUser = [
   {
      headerName: 'Usuario',
      field: 'user.fullName',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Monto pagado',
      field: 'amount',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Tipo',
      field: 'type',
      filter: true,
      sortable: true,
      resizable: true,
   },

   {
      headerName: 'Fecha',
      field: 'createdAt',
      cellRenderer: 'agFullDateCellRenderer',
      filter: true,
      sortable: true,
      resizable: true,
   },
];
