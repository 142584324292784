import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
   TextField,
   Select,
   MenuItem,
   FormControl,
   InputLabel,
   FormControlLabel,
   Switch,
} from '@material-ui/core';
import Button from '../../../../components/Buttons/Button';
import Modal from '../../../../components/Modal/Modal';
import { http } from '../../../../utils/http';
import { connect } from 'react-redux';
import { TimePicker } from '@material-ui/pickers';
import {
   addAmenity as addAmenityAction,
   updateAmenity as updateAmenityAction,
} from '../../../../redux/actions/amenity.action';
import ChangeImage from '../../../../components/ChangeImage/ChangeImage';
import { convertArrayToObjectForError } from '../../../../utils/convertArrayToObject';
import ms from 'ms';
const initialState = {
   name: '',
   description: '',
   cost: '',
   schedule: {
      start: new Date(),
      end: new Date(),
   },
   timeMinReservation: '',
   amenities: '',
   staff: '',
   onlyUniqueReservation: true,
   cantPerson: '',
   image: '',
   days: [],
};
const initialStateError = {
   name: false,
   description: false,
   cost: false,
   // schedule: {
   //    start: new Date(),
   //    end: new Date(),
   // },
   timeMinReservation: false,
   amenities: false,
   staff: false,
   onlyUniqueReservation: false,
   cantPerson: false,
   // image: false,
};

const EditModalAmenity = ({
   isOpen,
   onClose,
   amenity,
   addAmenity,
   updateAmenity,
}) => {
   const [formModalAmenity, setFormModalAmenity] = useState(initialState);
   const [loading, setLoading] = useState(false);
   const [errors, setErrors] = useState(initialStateError);
   const [unidadTime, setUnidadTime] = useState('h');
   const [imageUpload, setImageUpload] = useState(null);

   useEffect(() => {
      if (amenity) {
         const timeReservation = ms(amenity.timeMinReservation, {
            long: true,
         }).split(' ');
         setFormModalAmenity({
            name: amenity.name,
            description: amenity.description,
            cost: amenity.cost,
            schedule: {
               start: new Date(amenity.schedule.start),
               end: new Date(amenity.schedule.end),
            },
            timeMinReservation: timeReservation[0],
            staff: amenity.staff.join(', '),
            amenities: amenity.amenities.join(', '),
            image: amenity.image,
            cantPerson: amenity.cantPerson,
            onlyUniqueReservation: amenity.onlyUniqueReservation,
            days: amenity.days,
         });

         setUnidadTime(timeReservation[1][0].toLowerCase());
      }

      return () => {
         setFormModalAmenity(initialState);
         setImageUpload(null);
         setErrors(initialStateError);
      };
   }, [amenity, isOpen]);

   const _onCreateUser = () => {
      // evt.preventDefault();
      setLoading(true);
      http('/amenity', 'POST', {
         ...formModalAmenity,
         cost: +formModalAmenity.cost,
         cantPerson: +formModalAmenity.cantPerson,
         timeMinReservation: ms(
            formModalAmenity.timeMinReservation + unidadTime,
         ),
         amenities:
            formModalAmenity.amenities === ''
               ? []
               : formModalAmenity.amenities
                    .split(',')
                    .filter(Boolean)
                    .map((r) => r.trim()),
         staff:
            formModalAmenity.staff === ''
               ? []
               : formModalAmenity.staff
                    .split(',')
                    .filter(Boolean)
                    .map((r) => r.trim()),
      })
         .then(async (res) => {
            addAmenity(res.data.data);
            if (imageUpload !== null) {
               await onSubmitImage(res.data.data._id);
            }
            onClose();
         })
         .catch((err) => {
            console.error(err);
            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const _onEditUser = () => {
      // evt.preventDefault();
      setLoading(true);
      http(`/amenity/${amenity._id}`, 'PUT', {
         ...formModalAmenity,
         cost: +formModalAmenity.cost,
         cantPerson: +formModalAmenity.cantPerson,
         timeMinReservation: ms(
            formModalAmenity.timeMinReservation + unidadTime,
         ),
         amenities:
            formModalAmenity.amenities === ''
               ? []
               : formModalAmenity.amenities
                    .split(',')
                    .filter(Boolean)
                    .map((r) => r.trim()),
         staff:
            formModalAmenity.staff === ''
               ? []
               : formModalAmenity.staff
                    .split(',')
                    .filter(Boolean)
                    .map((r) => r.trim()),
      })
         .then(async (res) => {
            console.log(res);
            updateAmenity(res.data.data);
            if (imageUpload !== null) {
               await onSubmitImage(res.data.data._id);
            }
            onClose();
         })
         .catch((err) => {
            console.error(err);
            if (err?.error?.fields) {
               const errors = Object.values(err?.error?.fields);
               const value = convertArrayToObjectForError(errors, 'property');
               setErrors({ ...initialStateError, ...value });
               console.log(value);
            }
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const _isDisableButtonModal = () => {
      const {
         name,
         description,
         cost,
         timeMinReservation,
         cantPerson,
         // allergens,
      } = formModalAmenity;

      return (
         name === '' ||
         description === '' ||
         cost === '' ||
         timeMinReservation === '' ||
         cantPerson === ''
      );
   };

   const onSubmitImage = async (id) => {
      let formData = new FormData();
      formData.append('amenityImage', imageUpload);

      return await http(`/amenity/${id}/upload`, 'POST', formData)
         .then((res) => {
            updateAmenity(res.data.data);
            console.log(res);
         })
         .catch((err) => console.log(err));
   };

   const _changeHandleInput = (evt, key) => {
      setFormModalAmenity({
         ...formModalAmenity,
         [key]: evt.target.value,
      });
   };

   const _changeHandleHours = (value, key) => {
      setFormModalAmenity({
         ...formModalAmenity,
         schedule: {
            ...formModalAmenity.schedule,
            [key]: value,
         },
      });
   };

   const handleChangeOnlyUniqueReservation = () => {
      setFormModalAmenity({
         ...formModalAmenity,
         onlyUniqueReservation: !formModalAmenity.onlyUniqueReservation,
      });
   };

   return (
      <Modal
         actions={
            <div className="createUser-actions-modal">
               <Button className="actions-item" onClick={onClose}>
                  Cancelar
               </Button>
               <Button
                  loading={loading}
                  className="actions-item"
                  onClick={amenity ? _onEditUser : _onCreateUser}
                  disabled={_isDisableButtonModal()}>
                  Aceptar
               </Button>
            </div>
         }
         isOpen={isOpen}
         title={amenity !== null ? 'Editar Amenidad' : 'Agregar Amenidad'}
         description={
            amenity !== null
               ? 'Actualice la información necesaria para editar una amenidad'
               : 'Agrege la información necesaria para crear una nueva amenidad'
         }
         onClose={onClose}>
         <div className="createUser-content-modal">
            <TextField
               name="name"
               className="createUser-inputs"
               label="Nombre"
               variant="outlined"
               value={formModalAmenity.name}
               error={errors.name !== false}
               helperText={errors.name}
               onChange={(evt) => _changeHandleInput(evt, 'name')}
            />
            <TextField
               name="description"
               className="createUser-inputs"
               label="Descripción"
               variant="outlined"
               value={formModalAmenity.description}
               error={errors.description !== false}
               helperText={errors.description}
               onChange={(evt) => _changeHandleInput(evt, 'description')}
            />
            <TextField
               name="cost"
               className="createUser-inputs"
               label="Costo"
               variant="outlined"
               value={formModalAmenity.cost}
               error={errors.cost !== false}
               helperText={errors.cost}
               onChange={(evt) => _changeHandleInput(evt, 'cost')}
            />
            <TextField
               name="cantPerson"
               className="createUser-inputs"
               label="Cantidad de personas"
               variant="outlined"
               value={formModalAmenity.cantPerson}
               error={errors.cantPerson !== false}
               helperText={errors.cantPerson}
               onChange={(evt) => _changeHandleInput(evt, 'cantPerson')}
            />
            <TimePicker
               autoOk={true}
               className="createUser-inputs"
               label="Hora de inicio"
               ampm={true}
               inputVariant="outlined"
               variant="inline"
               openTo="hours"
               clearable={true}
               value={formModalAmenity.schedule.start}
               onChange={
                  (date) => {
                     _changeHandleHours(date._d, 'start');
                  }
                  // onChangeHour({
                  //    selectedDate: date._d,
                  //    beforeDate: date._i,
                  // })
               }
            />
            <TimePicker
               autoOk={true}
               className="createUser-inputs"
               label="Hora de fin"
               ampm={true}
               inputVariant="outlined"
               variant="inline"
               openTo="hours"
               clearable={true}
               value={formModalAmenity.schedule.end}
               onChange={
                  (date) => {
                     _changeHandleHours(date._d, 'end');
                  }
                  // onChangeHour({
                  //    selectedDate: date._d,
                  //    beforeDate: date._i,
                  // })
               }
            />
            <div
               style={{
                  display: 'flex',
                  width: '100%',
                  margin: 5,
                  marginBottom: 10,
               }}>
               {[
                  { days: 'Lunes', number: 1 },
                  { days: 'Martes', number: 2 },
                  { days: 'Miercoles', number: 3 },
                  { days: 'Jueves', number: 4 },
                  { days: 'Viernes', number: 5 },
                  { days: 'Sabado', number: 6 },
                  { days: 'Domingo', number: 0 },
               ].map((item) => {
                  return (
                     <div style={{ padding: 5 }}>
                        <label>{item.days}</label>
                        <input
                           type="checkbox"
                           checked={
                              formModalAmenity.days.find(
                                 (_el) => _el.number === item.number,
                              )
                                 ? true
                                 : false
                           }
                           onChange={(e) => {
                              const { days } = formModalAmenity;
                              let _indexFind = days.findIndex(
                                 (find) => find.number === item.number,
                              );
                              if (_indexFind > -1) {
                                 days.splice(_indexFind, 1);
                                 setFormModalAmenity({
                                    ...formModalAmenity,
                                    days: [...days],
                                 });
                                 return true;
                              }
                              setFormModalAmenity({
                                 ...formModalAmenity,
                                 days: [...days, { ...item }],
                              });
                           }}
                        />
                     </div>
                  );
               })}
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
               <TextField
                  style={{ width: '100%' }}
                  name="timeMinReservation"
                  className="createUser-inputs"
                  label="Tiempo minimo de reserva"
                  type="tel"
                  variant="outlined"
                  value={formModalAmenity.timeMinReservation}
                  error={errors.timeMinReservation !== false}
                  helperText={errors.timeMinReservation}
                  onChange={(evt) =>
                     _changeHandleInput(evt, 'timeMinReservation')
                  }
               />
               <FormControl
                  variant="outlined"
                  className="createUser-inputs"
                  style={{ width: 130, marginLeft: 10 }}>
                  <InputLabel>Unidad</InputLabel>
                  <Select
                     name="type"
                     label="Unidad"
                     value={unidadTime}
                     onChange={(evt) => setUnidadTime(evt.target.value)}>
                     {/* <MenuItem value={''}>Selecione una unida</MenuItem> */}
                     <MenuItem value={'h'}>Hora</MenuItem>
                     <MenuItem value={'m'}>Minuto</MenuItem>
                  </Select>
               </FormControl>
            </div>
            {/* 
            <TextField
               name="staff"
               className="createUser-inputs"
               label="Staff"
               variant="outlined"
               value={formModalAmenity.staff}
               error={errors.staff !== false}
               helperText={errors.staff}
               onChange={(evt) => _changeHandleInput(evt, 'staff')}
            />
            <TextField
               name="amenities"
               className="createUser-inputs"
               label="Amenities"
               variant="outlined"
               value={formModalAmenity.amenities}
               error={errors.amenities !== false}
               helperText={errors.amenities}
               onChange={(evt) => _changeHandleInput(evt, 'amenities')}
            /> */}
            <FormControlLabel
               control={
                  <Switch
                     checked={formModalAmenity.onlyUniqueReservation}
                     onChange={handleChangeOnlyUniqueReservation}
                     name="onlyUniqueReservation"
                  />
               }
               label="Reservación única"
            />

            <ChangeImage
               previewUrl={formModalAmenity.image}
               onChangeImage={(file) => setImageUpload(file)}
            />
         </div>
      </Modal>
   );
};

EditModalAmenity.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
   addAmenity: (amenity) => dispatch(addAmenityAction(amenity)),
   updateAmenity: (amenity) => dispatch(updateAmenityAction(amenity)),
});

export default connect(null, mapDispatchToProps)(EditModalAmenity);
