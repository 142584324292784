import {
   ADD_LIST_NEWS,
   ADD_NEWS,
   DELETE_NEWS,
   UPDATE_NEWS,
} from '../types/news.type';
const initialState = {
   news: [],
};

const newsReducer = (state = initialState, action) => {
   switch (action.type) {
      case ADD_LIST_NEWS:
         return {
            ...state,
            news: [...action.payload],
         };

      case ADD_NEWS:
         return {
            ...state,
            news: [...state.news, action.payload],
         };

      case UPDATE_NEWS:
         return {
            ...state,
            news: state.news.map((newItem, index) => {
               if (newItem._id !== action.payload._id) {
                  // This isn't the item we care about - keep it as-is
                  return newItem;
               }

               // Otherwise, this is the one we want - return an updated value
               return {
                  ...newItem,
                  ...action.payload,
               };
            }),
         };

      case DELETE_NEWS:
         return {
            ...state,
            news: state.news.filter(
               (newItem, index) => newItem._id !== action.payload._id,
            ),
         };
      default:
         return state;
   }
};

export { newsReducer };
