import { ADD_LIST_NEWS, ADD_NEWS, DELETE_NEWS, UPDATE_NEWS } from '../types/news.type';

export function addListNews(data) {
   return {
      type: ADD_LIST_NEWS,
      payload: data,
   };
}

export function addNews(data) {
   return {
      type: ADD_NEWS,
      payload: data,
   };
}

export function updateNews(data) {
   return {
      type: UPDATE_NEWS,
      payload: data,
   };
}

export function deleteNews(data) {
   return {
      type: DELETE_NEWS,
      payload: data,
   };
}
