const ItemPrint = ({ item }) => {
   return (
      <div className="grid grid-cols-2 gap-x-2">
         <div className="flex flex-col gap-y-1 ">
            <span> {item.name}</span>
            <span>
               Precio <strong>{item.price}</strong>{' '}
            </span>
            <span>
               Tamaño <strong>{item.weight}</strong>
            </span>
            <span>
               Cantidad <strong>{item?.cant}</strong>
            </span>
         </div>
         <div>
            <img
               src={item.image}
               alt=""
               srcset=""
               className="object-cover w-[80px] h-[80px]"
            />
         </div>
      </div>
   );
};

export default ItemPrint;
