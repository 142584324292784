import React, { Component } from 'react';
import { connect } from 'react-redux';
import DateInputFilter from '../../components/DateInputFilter/DateInputFilter';
import './Dashboard.scss';
import LogoYama from '../../assets/images/yama-logo.png';

class DashboardPage extends Component {
   render() {
      return (
         <div className="Dashboard">
            <img
               src={LogoYama}
               alt="Yama"
               style={{ width: 100, height:110, marginBottom: 10 }}
            />
            <h1>Bienvenido</h1>
            <h2>{this.props.user.fullName}</h2>
         </div>
      );
   }
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
