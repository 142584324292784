const KEY_STORAGE = 'Yama';

export function getStorage(key) {
   try {
      const data = localStorage.getItem(getKey(key));
      return JSON.parse(data);
   } catch (err) {
      console.error(err);
   }
}

export function setStorage(key, data) {
   try {
      localStorage.setItem(getKey(key), JSON.stringify(data));
   } catch (err) {
      console.error(err);
   }
}

export function removeStorage(key) {
   try {
      localStorage.removeItem(getKey(key));
   } catch (err) {}
}

function getKey(key) {
   return `${KEY_STORAGE}_${key}`;
}
