import { IconButton, Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../../../components/AgGrid/AgGrid';
import { http } from '../../../../utils/http';
import { columns } from './columns';

class ReportRequestFoodPage extends Component {
   state = {
      request: [],
   };

   componentDidMount() {
      this._getRequestFood();
   }

   _getRequestFood() {
      http('/request-food', 'GET', {})
         .then((res) => {
            console.log(res);

            this.setState({
               request: res.data.data,
            });
         })
         .catch((err) => console.log(err));
   }

   render() {
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Reporte de solicitudes de alimentos</h1>
               </div>
               {/* <div className="header-actions">
                  <Tooltip title="Refrescar" aria-label="Refrescar">
                     <IconButton onClick={this._getRequestFood.bind(this)}>
                        <i className="material-icons">refresh</i>
                     </IconButton>
                  </Tooltip>
               </div> */}
            </div>
            <div className="Page-content">
               <AgGrid rowData={this.state.request} columnDefs={columns} />
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
   mapStateToProps,
   mapDispatchToProps,
)(ReportRequestFoodPage);
