import { IconButton, Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { http } from '../../utils/http';
import './ClubPage.scss';
import EditModalClub from './components/EditModalClub/EditModalClub';
import {
   addListTower as addListTowerAction,
   addListAmenity as addListAmenityAction,
} from '../../redux/actions/tower.action';
import { format, parseISO } from 'date-fns';
import ModalFloors from './components/ModalFloors/ModalFloors';

class TowersPage extends Component {
   state = {
      towerSelected: null,
      showModal: false,
      showModalFloors: false,
   };

   async componentDidMount() {
      await this._getTowers();
      await this._getAmenitys();
   }

   async _getTowers() {
      http('/tower', 'GET', {})
         .then((res) => {
            this.props.addListTower(res.data.data);
         })
         .catch((err) => console.error(err));
   }

   async _getAmenitys() {
      http('/amenity', 'GET', {})
         .then((res) => {
            this.props.addListAmenity(res.data.data);
         })
         .catch((err) => console.error(err));
   }

   _showModal() {
      this.setState({ showModal: true });
   }

   _hideModal() {
      this.setState({ showModal: false, towerSelected: null });
   }

   _handleRowClicked(value) {
      this.setState({ showModal: true, towerSelected: value });
   }

   render() {
      const { towers } = this.props;
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Administración de torres</h1>
               </div>
               <div className="header-actions">
                  <Tooltip title="Refrescar" aria-label="Refrescar">
                     <IconButton onClick={this._getTowers.bind(this)}>
                        <i className="material-icons">refresh</i>
                     </IconButton>
                  </Tooltip>
                  <Tooltip title="Crear" aria-label="Crear">
                     <IconButton onClick={this._showModal.bind(this)}>
                        <i className="material-icons">add</i>
                     </IconButton>
                  </Tooltip>
               </div>
            </div>
            <div className="Page-content items-towers">
               {towers &&
                  towers.map((item, index) => {
                     const {
                        weekSchedule: { end, start },
                     } = item;
                     return (
                        <div className="items" key={index}>
                           <img src={item.image} alt="" />
                           <h3>{item.name}</h3>
                           <div>
                              <span>
                                 Hora {format(parseISO(start), 'HH:mm')} -{' '}
                                 {format(parseISO(end), 'HH:mm')}
                              </span>
                           </div>
                           <div
                              className={
                                 'footer-card w-full flex flex-row !justify-end'
                              }>
                              <Tooltip title="Editar" aria-label="Crear">
                                 <IconButton
                                    onClick={() => {
                                       this._handleRowClicked(item);
                                    }}>
                                    <i className="material-icons">edit</i>
                                 </IconButton>
                              </Tooltip>

                              <button
                                 onClick={() => {
                                    this.setState({
                                       showModalFloors: true,
                                       towerSelected: item,
                                    });
                                 }}>
                                 Pisos y Amenidades
                              </button>
                           </div>
                        </div>
                     );
                  })}
            </div>
            <EditModalClub
               isOpen={this.state.showModal}
               onClose={this._hideModal.bind(this)}
               tower={this.state.towerSelected}
            />
            <ModalFloors
               isOpen={this.state.showModalFloors}
               onClose={() => {
                  this.setState({
                     showModalFloors: false,
                     towerSelected: null,
                  });
               }}
               tower={this.state.towerSelected}
               x={1000}
            />
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   towers: state.towerReducer.towers,
});

const mapDispatchToProps = (dispatch) => ({
   addListTower: (towers) => dispatch(addListTowerAction(towers)),
   addListAmenity: (amenitys) => dispatch(addListAmenityAction(amenitys)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TowersPage);
