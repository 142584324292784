import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './ReportPage.scss';

//import LogoYama from '../../assets/images/yama-logo-blue.svg';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import './ReportPage.scss';
import { Link } from 'react-router-dom';

const reportes = [
   {
      text: 'Reporte de Alimento y Bebidas',
      description:
         'Este reporte muestra las métricas de ventas de alimentos y bebidas',
      to: '/report/food',
      icon: 'fastfood',
   },
   {
      text: 'Reporte de Rerservaciones',
      description:
         'Este reporte muestra las métricas de ventas de reservaciones',
      to: '/report/reservation',
      icon: 'event',
   },
   {
      text: 'Reporte de Créditos',
      description:
         'Este reporte muestra las métricas de uso de créditos de los usuarios',
      to: '/report/credit',
      icon: 'credit_score',
   },
   {
      text: 'Reporte de Valet Parking',
      description:
         'Este reporte muestra las métricas de las solicitudes de valet parking de los usuarios',
      to: '/report/valet-parking',
      icon: 'local_parking',
   },
   {
      text: 'Reporte de Cortesía',
      description:
         'Este reporte muestra las métricas de los usuarios para otorgar una cortesía',
      to: '/report/courtesy',
      icon: 'redeem',
   },
   {
      text: 'Reporte de Créditos Extras',
      description:
         'Este reporte muestra las métricas de la solicitudes de créditos extra',
      to: '/report/credit-extra',
      icon: 'credit_score',
   },
   {
      text: 'Reporte de Ventas de Alimentos',
      description:
         'Este reporte muestra las métricas de las ventas de los alimentos',
      to: '/report/sales/food',
      icon: 'trending_up',
   },
   {
      text: 'Reporte de Ventas de Reservaciones',
      description:
         'Este reporte muestra las métricas de las ventas de las reservaciones',
      to: '/report/sales/reservation',
      icon: 'trending_up',
   },
   {
      text: 'Reporte de Pagos de creditos',
      description:
         'En este resporte es para ver los ingresos de pagos por creditos',
      to: '/report/credit-pay',
      icon: 'paid',
   },
   {
      text: 'Reporte de propinas',
      description:
         'En este resporte es para ver los ingresos de pagos por propinas',
      to: '/report/tips-pay',
      icon: 'paid',
   },
];

class ReportPage extends Component {
   static propTypes = {
      prop: PropTypes,
   };

   render() {
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Reportes</h1>
               </div>
            </div>
            <div className="Page-content">
               <div className="containerCard">
                  {reportes.map((item) => {
                     return (
                        <Link className="card" to={item.to}>
                           <Card>
                              <CardContent
                                 style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                 }}>
                                 <p className="tituloCard">{item.text}</p>
                                 <i className="material-icons icono">
                                    {item.icon}
                                 </i>
                                 <p className="descriptionCard">
                                    {item.description}
                                 </p>
                              </CardContent>
                           </Card>
                        </Link>
                     );
                  })}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
