export const columnsReportReservation = [
   {
      headerName: 'ID',
      field: '_id',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Club',
      field: 'tower.name',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Amenidad',
      field: 'amenity.name',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Usuario',
      field: 'user.fullName',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'ID usuario',
      field: 'user._id',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Fecha de reservacion',
      field: 'schedule.start',
      cellRenderer: 'agFullDateCellRenderer',
      filter: true,
      sortable: true,
      resizable: true,
   },

   {
      headerName: 'Fecha de creación',
      field: 'createdAt',
      cellRenderer: 'agFullDateCellRenderer',
      filter: true,
      sortable: true,
      resizable: true,
   },
];
