import {
   ADD_LIST_AMENITY,
   ADD_AMENITY,
   UPDATE_AMENITY,
   DELETE_AMENITY,
} from '../types/amenity.type';

const initialState = {
   amenities: [],
};

const amenityReducer = (state = initialState, action) => {
   switch (action.type) {
      case ADD_LIST_AMENITY:
         return {
            ...state,
            amenities: [...action.payload],
         };

      case ADD_AMENITY:
         return {
            ...state,
            amenities: [...state.amenities, action.payload],
         };

      case UPDATE_AMENITY:
         return {
            ...state,
            amenities: state.amenities.map((amenity, index) => {
               if (amenity._id !== action.payload._id) {
                  // This isn't the item we care about - keep it as-is
                  return amenity;
               }

               // Otherwise, this is the one we want - return an updated value
               return {
                  ...amenity,
                  ...action.payload,
               };
            }),
         };
      case DELETE_AMENITY:
         return {
            ...state,
            amenities: state.amenities.filter(
               (amenity, index) => amenity._id !== action.payload._id,
            ),
         };
      default:
         return state;
   }
};

export { amenityReducer };
