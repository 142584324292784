import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import AuthRouter from '../components/Guards/AuthRouter/AuthRouter';
import PrivateRouter from '../components/Guards/PrivateRouter/PrivateRouter';
import LoginPage from '../pages/Login/LoginPage';
import AppRouting from './app.routing';

export default function RootRouting() {
   return (
      <Router>
         <Switch>
            <AuthRouter path="/login" component={LoginPage} />
            <PrivateRouter path="/" component={AppRouting} />
         </Switch>
      </Router>
   );
}
