import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   IconButton,
   Slide,
   TextField,
   Tooltip,
} from '@material-ui/core';

import React, { forwardRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { http } from '../../../utils/http';

const Transition = forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const AgActionButtonCellRenderer = ({ data, del, active, sendEmail }) => {
   const dispatch = useDispatch();
   const [openDeleteModal, setOpenDeleteModal] = useState(false);
   const [openActiveModal, setOpenActiveModal] = useState(false);
   const [openSendEmailModal, setOpenSendEmailModal] = useState(false);
   const [emailFormSendEmail, setEmailFormSendEmail] = useState('');

   const handleClickOpenDeleteModal = () => {
      setOpenDeleteModal(true);
   };

   const handleCloseDeleteModal = (isDelete) => {
      if (isDelete) {
         deleteItem();
      }
      setOpenDeleteModal(false);
   };

   const handleClickOpenActiveModal = () => {
      setOpenActiveModal(true);
   };

   const handleClickOpenSendEmailModal = () => {
      setOpenSendEmailModal(true);
   };
   const handleCloseSendEmailModal = (isSendEmail) => {
      if (isSendEmail) {
         sendEmailRecoverPassword();
      }
      setOpenSendEmailModal(false);
   };

   const handleCloseActiveModal = (isActive) => {
      if (isActive) {
         activeItem();
      }
      setOpenActiveModal(false);
   };

   const deleteItem = () => {
      http(`${del.url}/${data._id}`, 'DELETE', {})
         .then((res) => {
            //console.log(res);
            dispatch(del.action(res.data.data));
         })
         .catch((err) => console.log(err));
   };

   const activeItem = () => {
      const isActive = data.active;
      http(
         `${active.url}/${data._id}/${isActive ? 'inactive' : 'active'}`,
         'PATCH',
         {},
      )
         .then((res) => {
            console.log(res);
            dispatch(active.action(res.data.data));
         })
         .catch((err) => console.log(err));
   };

   const sendEmailRecoverPassword = () => {
      http(`/auth/recovery-password-alt`, 'POST', {
         email: data.email,
         emailReceive: emailFormSendEmail,
      })
         .then((res) => {
            console.log(res);
         })
         .catch((err) => console.log(err));
   };

   return (
      <span>
         {del && (
            <Tooltip title="Borrar" aria-label="Borrar">
               <IconButton onClick={handleClickOpenDeleteModal}>
                  <i className="material-icons">delete</i>
               </IconButton>
            </Tooltip>
         )}
         {active && (
            <Tooltip
               title={data.active ? 'Inactivar' : 'Activar'}
               aria-label={data.active ? 'Inactivar' : 'Activar'}>
               <IconButton
                  onClick={handleClickOpenActiveModal}
                  style={{
                     color: data.active
                        ? 'rgba(25, 231, 147, 0.8)'
                        : 'rgba(231, 25, 66, 0.8)',
                  }}>
                  <i className="material-icons">
                     {data.active ? 'block' : 'done'}
                  </i>
               </IconButton>
            </Tooltip>
         )}
         {sendEmail && (
            <Tooltip
               title="Recuperar contraseña"
               aria-label="Recuperar contraseña">
               <IconButton
                  onClick={handleClickOpenSendEmailModal}
                  style={{
                     color: 'rgba(231, 173, 25, 0.8)',
                  }}>
                  <i className="material-icons">email</i>
               </IconButton>
            </Tooltip>
         )}
         {/* Dialog */}
         <Dialog
            open={openDeleteModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseDeleteModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle id="alert-dialog-slide-title">
               ¿Está seguro?
            </DialogTitle>
            <DialogContent>
               <DialogContentText id="alert-dialog-slide-description">
                  Este registro será eliminado permanentemente. Esta acción no
                  puede ser desecha.
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button
                  onClick={() => handleCloseDeleteModal(true)}
                  color="primary">
                  Eliminar
               </Button>
               <Button
                  onClick={() => handleCloseDeleteModal(false)}
                  color="primary">
                  Cancelar
               </Button>
            </DialogActions>
         </Dialog>

         <Dialog
            open={openActiveModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseActiveModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle id="alert-dialog-slide-title">
               ¿Está seguro?
            </DialogTitle>
            <DialogContent>
               <DialogContentText id="alert-dialog-slide-description">
                  Este registro pasará a {data.active ? 'inactivo' : 'activo'}.
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button
                  onClick={() => handleCloseActiveModal(true)}
                  color="primary">
                  Aceptar
               </Button>
               <Button
                  onClick={() => handleCloseActiveModal(false)}
                  color="primary">
                  Cancelar
               </Button>
            </DialogActions>
         </Dialog>

         <Dialog
            open={openSendEmailModal}
            onClose={handleCloseSendEmailModal}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
               Enviar correo de recuperación de contraseña
            </DialogTitle>
            <DialogContent>
               <DialogContentText>
                  En caso de no recibir el email de recuperación de contraseña
                  al correo del usuario, puede enviar ese correo a otra
                  dirección de correo para poder acceder a la plataforma
               </DialogContentText>
               <TextField
                  autoFocus
                  value={emailFormSendEmail}
                  onChange={(evt) => setEmailFormSendEmail(evt.target.value)}
                  variant="outlined"
                  margin="dense"
                  id="name"
                  label="Dirección de correo"
                  type="email"
                  fullWidth
               />
            </DialogContent>
            <DialogActions>
               <Button
                  onClick={() => handleCloseSendEmailModal(false)}
                  color="primary">
                  Cancelar
               </Button>
               <Button
                  disabled={emailFormSendEmail.trim() === ''}
                  onClick={() => handleCloseSendEmailModal(true)}
                  color="primary">
                  Enviar correo
               </Button>
            </DialogActions>
         </Dialog>
      </span>
   );
};

export default AgActionButtonCellRenderer;
