export const columnsReportFood = [
   {
      headerName: 'Platillo',
      field: 'name',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Cantidad',
      field: 'cant',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Precio c/u',
      field: 'price',
      filter: true,
      sortable: true,
      resizable: true,
   },

   {
      headerName: 'Club',
      field: 'reservation.tower.name',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Amenidad',
      field: 'reservation.amenity.name',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Usuario',
      field: 'user.fullName',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Fecha',
      field: 'createdAt',
      cellRenderer: 'agFullDateCellRenderer',
      filter: true,
      sortable: true,
      resizable: true,
   },
];
