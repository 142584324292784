import { IconButton, Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../components/AgGrid/AgGrid';
import { http } from '../../utils/http';
import { columns } from './columns';
import './MenuPage.scss';
import { addListFood as addListFoodAction } from '../../redux/actions/food.action';
import { addListMenu as addListMenuAction } from '../../redux/actions/menu.action';
import { addListTower as addListTowerAction } from '../../redux/actions/tower.action';
import EditModalMenu from './components/EditModalMenu/EditModalMenu';
class MenuPage extends Component {
   state = {
      menuSelected: null,
      showModal: false,
   };

   async componentDidMount() {
      await this._reload();
   }

   async _reload() {
      await Promise.all([
         this._getMenus(),
         this._getFoods(),
         this._getTowers(),
      ]);
   }

   _getMenus() {
      http('/menu', 'GET', {})
         .then((res) => {
            console.log(res.data.data);
            this.props.addListMenu(res.data.data);
         })
         .catch((err) => {
            console.error(err);
         });
   }

   _getTowers() {
      http('/tower', 'GET', {})
         .then((res) => {
            this.props.addListTower(res.data.data);
         })
         .catch((err) => {
            console.error(err);
         });
   }

   _getFoods() {
      http('/food', 'GET', {})
         .then((res) => {
            this.props.addListFood(res.data.data);
         })
         .catch((err) => {
            console.error(err);
         });
   }

   _showModal() {
      this.setState({ showModal: true });
   }

   _hideModal() {
      this.setState({ showModal: false, menuSelected: null });
   }

   _handleRowClicked(value) {
      const user = value.data;
      this.setState({ showModal: true, menuSelected: user });
   }

   render() {
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Menús</h1>
               </div>
               <div className="header-actions">
                  <Tooltip title="Refrescar" aria-label="Refrescar">
                     <IconButton onClick={this._reload.bind(this)}>
                        <i className="material-icons">refresh</i>
                     </IconButton>
                  </Tooltip>
                  <Tooltip title="Crear" aria-label="Crear">
                     <IconButton onClick={this._showModal.bind(this)}>
                        <i className="material-icons">add</i>
                     </IconButton>
                  </Tooltip>
               </div>
            </div>
            <div className="Page-content">
               <AgGrid
                  rowData={this.props.menus}
                  columnDefs={columns}
                  onRowDoubleClicked={this._handleRowClicked.bind(this)}
               />
            </div>
            {this.state.showModal ? (
               <EditModalMenu
                  isOpen={this.state.showModal}
                  onClose={this._hideModal.bind(this)}
                  menu={this.state.menuSelected}
               />
            ) : null}
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   menus: state.menuReducer.menus,
});
const mapDispatchToProps = (dispatch) => ({
   addListTower: (towers) => dispatch(addListTowerAction(towers)),
   addListMenu: (menus) => dispatch(addListMenuAction(menus)),
   addListFood: (foods) => dispatch(addListFoodAction(foods)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuPage);
