import {
   ADD_LIST_RESERVATION,
   ADD_RESERVATION,
   UPDATE_RESERVATION,
   ADD_LIST_CLUB,
} from '../types/reservation.type';

export function addListReservation(data) {
   return {
      type: ADD_LIST_RESERVATION,
      payload: data,
   };
}

export function addListClub(data) {
   return {
      type: ADD_LIST_CLUB,
      payload: data,
   };
}

export function addtReservation(data) {
   return {
      type: ADD_RESERVATION,
      payload: data,
   };
}

export function updateReservation(data) {
   return {
      type: UPDATE_RESERVATION,
      payload: data,
   };
}
