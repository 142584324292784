import { deleteChef as deleteChefAction } from "../../redux/actions/chef.action";

export const columns = [
   { headerName: 'Nombre', field: 'name', filter: true, sortable: true },
   { headerName: 'Apellido', field: 'lastName', filter: true, sortable: true },
   { headerName: 'Correo', field: 'email', filter: true, sortable: true },
   {
      headerName: 'Tipo',
      field: 'typeChef',
      filter: true,
      sortable: true,
      valueGetter: ({ data }) => {
         return data.typeChef === 'CHEF' ? 'COCINA' : 'BAR';
      },
   },
   { headerName: 'Club', field: 'tower.name', filter: true, sortable: true },
   {
      headerName: 'Acciones',
      cellRenderer: 'agActionButtonCellRenderer',
      width: 110,
      cellRendererParams: {
         del: {
            url: '/user/chef',
            action: deleteChefAction
         }
      },
   },
];

export const columnsConcierge = [
   { headerName: 'Nombre', field: 'name', filter: true, sortable: true },
   { headerName: 'Apellido', field: 'lastName', filter: true, sortable: true },
   { headerName: 'Correo', field: 'email', filter: true, sortable: true },
   { headerName: 'Club', field: 'tower.name', filter: true, sortable: true },
   {
      headerName: 'Acciones',
      cellRenderer: 'agActionButtonCellRenderer',
      width: 110,
      cellRendererParams: {
         del: {
            url: '/user/concierge',
            action: deleteChefAction
         }
      },
   },
];