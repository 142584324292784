import { deleteTower } from '../../redux/actions/tower.action';

export const columns = [
   {
      headerName: 'Nombre',
      field: 'name',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Dirección',
      field: 'address',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Hora de inicio',
      field: 'weekSchedule.start',
      filter: true,
      sortable: true,
      cellRenderer: 'agTimeCellRenderer',
      resizable: true,
   },
   {
      headerName: 'Hora de fin',
      field: 'weekSchedule.end',
      filter: true,
      sortable: true,
      cellRenderer: 'agTimeCellRenderer',
      resizable: true,
   },
   {
      headerName: 'Hora de inicio (Fin de semana)',
      field: 'weekendSchedule.start',
      filter: true,
      sortable: true,
      cellRenderer: 'agTimeCellRenderer',

      resizable: true,
   },
   {
      headerName: 'Hora de fin (Fin de semana)',
      field: 'weekendSchedule.end',
      filter: true,
      sortable: true,
      cellRenderer: 'agTimeCellRenderer',
      resizable: true,
   },
   {
      headerName: 'Pisos',
      field: 'floors.length',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Acciones',
      cellRenderer: 'agActionButtonCellRenderer',
      width: 110,
      cellRendererParams: {
         del: {
            url: '/tower',
            action: deleteTower,
         },
      },
   },
];
