export const columnsRequestFood = [
   {
      headerName: 'Usuario',
      field: 'user.fullName',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Correo',
      field: 'user.email',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Monto gastado',
      field: 'amountTotal',
      filter: true,
      sortable: true,
      resizable: true,
   },
];
