import React, { useState, useEffect, Fragment, useRef } from 'react';
import Modal from '../../../../components/Modal/Modal';
import './AddModalCortesy.scss';
import UserAutocomplete from '../../../../components/autocomplete/autocomplete';
import {
   FormControl,
   IconButton,
   InputLabel,
   MenuItem,
   Select,
   TextField,
} from '@material-ui/core';
import { http } from '../../../../utils/http';
import Button from '../../../../components/Buttons/Button';
import { connect } from 'react-redux';
import { addListTower as addListTowerAction } from '../../../../redux/actions/tower.action';
import { addListFood as addListFoodAction } from '../../../../redux/actions/food.action';
import { addListAmenity as addListAmenityAction } from '../../../../redux/actions/amenity.action';
import { convertArrayToObject } from '../../../../utils/convertArrayToObject';
import {
   addCourtesy as addCourtesyAction,
   updateCourtesy as updateCourtesyAction,
} from '../../../../redux/actions/courtesy.action';
import ms from 'ms';

const initialState = [];
const initialStateForm = {
   user: null,
   credits: '',
   tower: null,
   amenity: null,
   timeAmenity: '',
   menu: null,
   foods: [],
};

const AddModalCortesy = ({ isOpen, onClose, courtesy, ...props }) => {
   const [buttonSelected, setButtonSelected] = useState('CREDITS');
   const [unidadTime, setUnidadTime] = useState('h');

   // const [tower, setTowers] = useState(initialState);
   const [amenitiesChooseByTower, setAmenitiesChooseByTower] = useState(
      initialState,
   );
   // const [menu, setMenus] = useState(initialState);
   const [foodsChooseByMenu, setFoodsChooseByMenu] = useState(initialState);
   const [formCourtesy, setFormCourtesy] = useState(initialStateForm);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      _getAll();
      return () => {
         setFoodsChooseByMenu(initialState);
         setFormCourtesy(initialStateForm);
      };
   }, [isOpen]);

   const _onCreateCourtesy = () => {
      setLoading(true);
      http(`/courtesy`, 'POST', {
         type: buttonSelected,
         user: formCourtesy.user._id,
         typeUser: formCourtesy.user.user ? 'UserChild' : 'User',
         ..._getDataCreateBody(),
      })
         .then((res) => {
            console.log(res);
            props.addCourtesy(res.data.data);
            onClose();
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            setLoading(false);
         });
   };

   // const _onEditCourtesy = () => {
   //    onClose();
   //    setLoading(true);
   //    http(`/courtesy/id`, 'PUT', {})
   //       .then((res) => {
   //          console.log(res);
   //       })
   //       .catch((err) => {
   //          console.log(err);
   //       });
   // };

   const _isDisableButtonModal = () => {
      return false;
   };

   const _getAll = async () => {
      await Promise.all([_getTowers()]);
   };

   const _getTowers = () => {
      http('/tower', 'GET', {})
         .then((res) => {
            console.log(res);
            props.addListTower(res.data.data);
         })
         .catch((err) => {
            console.error(err);
         });
   };

   // const _getAmenityByTower = (towerId) => {
   //    http('/amenity', 'GET', {})
   //       .then((res) => {
   //          console.log(res);
   //          props.addListAmenity(res.data.data);
   //       })
   //       .catch((err) => console.error(err));
   // };

   const _getFoodsByTower = (towerId) => {
      http(`/menu/tower/${towerId}`, 'GET', {})
         .then((res) => {
            setFormCourtesy({
               ...formCourtesy,
               tower: towerId,
               menu: res.data.data._id,
               foods: [],
            });

            const food = res.data.data.sections.reduce(
               (acc, section) => [...acc, ...section.items],
               [],
            );
            console.log(food);
            setFoodsChooseByMenu(food);
            console.log(res);
            // props.addListFood(res.data.data);
         })
         .catch((err) => {
            console.error(err);
            setFoodsChooseByMenu([]);
         });
   };

   //Form
   const _changeHandleInput = (evt, key, searchMenuByTower = false) => {
      setFormCourtesy({
         ...formCourtesy,
         [key]: evt.target.value,
      });

      if (searchMenuByTower) {
         _getFoodsByTower(evt.target.value);
         const floors =
            props.towers.find((tower) => tower._id === evt.target.value)
               ?.floors || [];

         if (floors.length > 0) {
            const amenities = floors.reduce(
               (acc, floor) => [...acc, ...floor.amenities],
               [],
            );
            setAmenitiesChooseByTower(amenities);
         } else {
            setAmenitiesChooseByTower([]);
         }
      }
   };

   const _changeHandleUser = (user) => {
      setFormCourtesy({
         ...formCourtesy,
         user: user,
      });
   };

   const addFoodReservation = (evt) => {
      const value = evt.target.value;
      const foodObject = convertArrayToObject(formCourtesy.foods, '_id');

      if (foodObject[value._id]) {
         return;
      }

      setFormCourtesy({
         ...formCourtesy,
         foods: [...formCourtesy.foods, value],
      });
   };

   const _getDataCreateBody = () => {
      switch (buttonSelected) {
         case 'CREDITS':
            return {
               credits: +formCourtesy.credits,
            };
         case 'RESERVATION':
            return {
               tower: formCourtesy.tower,
               amenity: formCourtesy.amenity,
               timeAmenity: ms(formCourtesy.timeAmenity + unidadTime),
            };
         case 'FOOD':
            return {
               tower: formCourtesy.tower,
               menu: formCourtesy.menu,
               foods: formCourtesy.foods.map((food) => ({
                  food: food._id,
                  stock: +food.stock,
               })),
            };
         default:
            return {};
      }
   };

   const _handleChangeCantFood = (evt, id) => {
      const value = evt.target.value;
      const foodsUpdate = formCourtesy.foods.map((item) => {
         if (item._id === id) {
            return {
               ...item,
               stock: value,
            };
         }

         return item;
      });
      setFormCourtesy({
         ...formCourtesy,
         foods: foodsUpdate,
      });
   };
   const _deleteItem = (id) => {
      const foodsUpdate = formCourtesy.foods.filter((item) => item._id !== id);
      setFormCourtesy({
         ...formCourtesy,
         foods: foodsUpdate,
      });
   };

   // Rendering

   const onChangeTypeCourtesy = (typeCortesy) => {
      setFormCourtesy({ ...initialStateForm, user: formCourtesy.user });
      setButtonSelected(typeCortesy);
   };

   const getRenderCredits = () => {
      // input numerico para gregar la cantidad de creditos
      return (
         <Fragment>
            <TextField
               className="createUser-inputs"
               id="credits"
               label="Numero de créditos"
               variant="outlined"
               type="number"
               onChange={(evt) => _changeHandleInput(evt, 'credits')}
            />
         </Fragment>
      );
   };

   const getRenderReservations = () => {
      // SELECT DE TORRE, SELECT DE AMENIDADES, INPUT NUMERICO para la cantidad de horas
      return (
         <Fragment>
            <FormControl className="createUser-inputs" variant="outlined">
               <InputLabel>Club</InputLabel>
               <Select
                  name="club"
                  label="Club"
                  value={formCourtesy.tower}
                  onChange={(evt) => _changeHandleInput(evt, 'tower', true)}>
                  {/* <MenuItem value={''} selected>
                     Seleccione un Club
                  </MenuItem> */}
                  {props.towers.map((tower) => (
                     <MenuItem key={tower._id} value={tower._id}>
                        {tower.name}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>

            <FormControl
               className="createUser-inputs"
               variant="outlined"
               disabled={formCourtesy.tower === null}>
               <InputLabel>Amenidad</InputLabel>
               <Select
                  name="amenity"
                  label="Amenidad"
                  value={formCourtesy.amenity}
                  onChange={(evt) => _changeHandleInput(evt, 'amenity')}>
                  {/* <MenuItem value={''}>Seleccione una amenidad</MenuItem> */}
                  {amenitiesChooseByTower.map((a) => (
                     <MenuItem key={a._id} value={a._id}>
                        {a.name}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>

            <div style={{ display: 'flex', width: '100%' }}>
               <TextField
                  style={{ width: '100%' }}
                  name="timeAmenity"
                  className="createUser-inputs"
                  label="Horas de reserva"
                  type="tel"
                  variant="outlined"
                  value={formCourtesy.timeAmenity}
                  onChange={(evt) => _changeHandleInput(evt, 'timeAmenity')}
               />
               <FormControl
                  variant="outlined"
                  className="createUser-inputs"
                  style={{ width: 130, marginLeft: 10 }}>
                  <InputLabel>Unidad</InputLabel>
                  <Select
                     name="type"
                     label="Unidad"
                     value={unidadTime}
                     onChange={(evt) => setUnidadTime(evt.target.value)}>
                     {/* <MenuItem value={''}>Selecione una unida</MenuItem> */}
                     <MenuItem value={'h'}>Hora</MenuItem>
                     <MenuItem value={'m'}>Minuto</MenuItem>
                  </Select>
               </FormControl>
            </div>
            {/* <TextField
               id="amountTime"
               className="createUser-inputs"
               label="Cantidad de tiempo valido"
               variant="outlined"
               type="number"
               onChange={(evt) => _changeHandleInput(evt, 'timeAmenity')}
            /> */}
         </Fragment>
      );
   };

   const getRenderFood = () => {
      //SELECT de ALIMENTOS, SELECT DE TORRE, LIST (COMPONENTE REUTILIZABLE
      return (
         <Fragment>
            <FormControl className="createUser-inputs" variant="outlined">
               <InputLabel>Clubs</InputLabel>
               <Select
                  name="club"
                  label="Club"
                  value={formCourtesy.tower}
                  onChange={(evt) => _changeHandleInput(evt, 'tower', true)}>
                  {/* <MenuItem value={''}>Seleccione un Club</MenuItem> */}
                  {props.towers.map((tower) => (
                     <MenuItem key={tower._id} value={tower._id}>
                        {tower.name}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>

            <FormControl
               className="createUser-inputs"
               variant="outlined"
               disabled={foodsChooseByMenu.length === 0}>
               <InputLabel>Alimentos y Bebidas</InputLabel>
               <Select
                  name="foods"
                  label="Alimentos y Bebidas"
                  value={''}
                  onChange={(evt) => addFoodReservation(evt)}>
                  <MenuItem value={''}>Seleccione un alimento</MenuItem>
                  {foodsChooseByMenu.map((m) => (
                     <MenuItem key={m.food._id} value={m.food}>
                        {m.food.name}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
            <div className="ListItemMenu">
               <ul className="ListItemMenu-list">
                  {formCourtesy.foods.map((food) => _getItem(food))}
               </ul>
            </div>
         </Fragment>
      );
   };

   const _getItem = (item) => {
      return (
         <li key={item._id} className="ListItemMenu-item">
            <div className="ListItemMenu-itemContainer">
               <div className="ListItemMenu-item_left">
                  <div className="ListItemMenu-item_image">
                     <img src={item.image ? item.image : ''} alt={item.name} />
                  </div>
                  <div className="ListItemMenu-item_info">
                     <span className="itemTitle">{item.name}</span>
                     <span className="itemDescription">
                        {item.descriptionShort}
                     </span>
                  </div>
               </div>
               <div className="ListItemMenu-item_right">
                  <TextField
                     name="cant"
                     label="Cantidad"
                     variant="outlined"
                     value={item.stock}
                     onChange={(val) => _handleChangeCantFood(val, item._id)}
                     type="number"
                  />
                  <IconButton
                     edge="end"
                     aria-label="delete"
                     onClick={() => _deleteItem(item._id)}>
                     <i className="material-icons">delete</i>
                  </IconButton>
               </div>
            </div>
         </li>
      );
   };

   const getContent = () => {
      switch (buttonSelected) {
         case 'CREDITS':
            return getRenderCredits();
         case 'RESERVATION':
            return getRenderReservations();
         case 'FOOD':
            return getRenderFood();
         default:
            return <div></div>;
      }
   };

   return (
      <Modal
         isOpen={isOpen}
         actions={
            <div className="createUser-actions-modal">
               <Button className="actions-item" onClick={onClose}>
                  Cancelar
               </Button>
               <Button
                  loading={loading}
                  className="actions-item"
                  onClick={courtesy ? () => {} : _onCreateCourtesy}
                  disabled={_isDisableButtonModal()}>
                  Aceptar
               </Button>
            </div>
         }
         title={'Agregar Cortesía'}
         description={
            courtesy !== null
               ? 'Actualice la información necesaria para editar una cortesía'
               : 'Agrege la información necesaria para crear una nueva cortesía'
         }
         onClose={onClose}>
         <div className="createUser-content-modal">
            <div className="contentButton">
               <button
                  className={buttonSelected === 'CREDITS' ? 'selected' : ''}
                  onClick={() => onChangeTypeCourtesy('CREDITS')}>
                  Créditos
               </button>
               <button
                  className={buttonSelected === 'RESERVATION' ? 'selected' : ''}
                  onClick={() => onChangeTypeCourtesy('RESERVATION')}>
                  Reservación
               </button>
               <button
                  className={buttonSelected === 'FOOD' ? 'selected' : ''}
                  onClick={() => onChangeTypeCourtesy('FOOD')}>
                  Alimentos
               </button>
            </div>

            <div className="createUser-inputs">
               <UserAutocomplete onChange={(user) => _changeHandleUser(user)} />
            </div>
            {getContent()}
         </div>
      </Modal>
   );
};

const mapStateToProps = (state) => ({
   towers: state.towerReducer.towers,
   foods: state.foodReducer.foods,
   amenities: state.amenityReducer.amenities,
});

const mapDispatchToProps = (dispatch) => ({
   addListTower: (towers) => dispatch(addListTowerAction(towers)),
   addListFood: (foods) => dispatch(addListFoodAction(foods)),
   addListAmenity: (amenities) => dispatch(addListAmenityAction(amenities)),
   addCourtesy: (courtesy) => dispatch(addCourtesyAction(courtesy)),
   updateCourtesy: (courtesy) => dispatch(updateCourtesyAction(courtesy)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddModalCortesy);
