import ms from 'ms';

export const columns = [
   { headerName: 'ID', field: '_id', filter: true, sortable: true },
   {
      headerName: 'Usuario',
      field: 'user.fullName',
      filter: true,
      sortable: true,
   },
   { headerName: 'Tipo', field: 'type', filter: true, sortable: true },
   { headerName: 'Club', field: 'tower.name', filter: true, sortable: true },
   {
      headerName: 'Creditos',
      field: 'credits',
      filter: true,
      sortable: true,
   },
   {
      headerName: 'Amenidad',
      field: 'amenity.name',
      filter: true,
      sortable: true,
   },
   {
      headerName: 'Cantidad de horas de reservación',
      field: 'timeAmenity',
      valueGetter: ({ data }) => {
         if (data?.timeAmenity) {
            return ms(data?.timeAmenity);
         } else {
            return '';
         }
      },
      filter: true,
      sortable: true,
   },
   {
      headerName: 'Estado',
      field: 'state',
      filter: true,
      sortable: true,
   },
];
